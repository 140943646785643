import notification from '../helpers/notifications';
import {
    POST_WATCH_LIST,
    WATCHLIST_ADDRESS_ALREADY_EXIST,
    WATCHLIST_ADDRESS_NOT_FOUND,
    ERROR_WRONG_ADDRESS,
    SEND_LIMIT_EXCEEDED,
    watchListPath,
} from '../constants';

const postWatchListRequested = () => ({
    type: POST_WATCH_LIST.POST_WATCH_LIST_REQUEST,
});

const postWatchListLoaded = data => ({
    type: POST_WATCH_LIST.POST_WATCH_LIST_SUCCESS,
    payload: data,
});

const postWatchListError = error => ({
    type: POST_WATCH_LIST.POST_WATCH_LIST_FAILURE,
    payload: error,
});

const postWatchListAction = postService => (values, t, history) => dispatch => {
    dispatch(postWatchListRequested());
    postService
        .addWatchList(values)
        .then(data => {
            dispatch(postWatchListLoaded(data));
            notification('', t('addressAddedToWatchList'), 'success');
            history.push(watchListPath);
        })
        .catch(error => {
            dispatch(postWatchListError(error.response.data.message));
            const {
                response: { data: { message = 'Something went wrong' } = {} } = {},
            } = error;
            let errorMessage = message;

            if (message === WATCHLIST_ADDRESS_ALREADY_EXIST) {
                errorMessage = t('error.addressAlreadyAddedToWatchList');
            }

            if (message === WATCHLIST_ADDRESS_NOT_FOUND) {
                errorMessage = t('error.addressNotFound');
            }

            if (message === ERROR_WRONG_ADDRESS) {
                errorMessage = t('error.wrongAddress');
            }

            if (message === SEND_LIMIT_EXCEEDED) {
                errorMessage = t('error.limitIsExceeded');
            }

            notification('', errorMessage, 'danger');
        });
};

export default postWatchListAction;

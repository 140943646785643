import { GET_PENDING_TRANSACTIONS } from '../constants';

const getPendingTransctionsRequested = () => ({
    type: GET_PENDING_TRANSACTIONS.GET_PENDING_TRANSACTIONS_REQUEST,
});

const getPendingTransctionsLoaded = data => ({
    type: GET_PENDING_TRANSACTIONS.GET_PENDING_TRANSACTIONS_SUCCESS,
    payload: data,
});

const getPendingTransctionsError = error => ({
    type: GET_PENDING_TRANSACTIONS.GET_PENDING_TRANSACTIONS_FAILURE,
    payload: error,
});

const getPendingTransctionsAction = getService => (itemsPerPage, pageNumber) => dispatch => {
    dispatch(getPendingTransctionsRequested());
    getService
        .getPendingTransctions(itemsPerPage, pageNumber)
        .then(data => {
            dispatch(getPendingTransctionsLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getPendingTransctionsError(err));
        });
};

export default getPendingTransctionsAction;

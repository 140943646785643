import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import mobileWidth from '@helpers/mobile-width';
import truncate from '@helpers/truncate-string';
import { addressPath } from '@constants';
import formatNumber from '@helpers/format-number';
import style from './top-statistics.module.scss';

const OverView = ({
    topStatistics: { topMiner, topSender, topReceiver, topTxCount, topTxCountReceived, topTxCountSent },
    topStatistics,
}) => {
    const { t } = useTranslation();
    const data = [
        {
            leftLabel: `${t('top')} BTCU ${t('sender')}`,
            leftValue: topSender?.address,
            rightLabel: `${t('total')} BTCU`,
            rightValue: topSender?.value,
        },
        {
            leftLabel: `${t('top')} ${t('count')}`,
            leftValue: topTxCount?.address,
            rightLabel: t('totalTxn'),
            rightValue: topTxCount?.value,
        },
        {
            leftLabel: `${t('top')} BTCU ${t('receiver')}`,
            leftValue: topReceiver?.address,
            rightLabel: `${t('total')} BTCU`,
            rightValue: topReceiver?.value,
        },
        {
            leftLabel: `${t('top')} ${t('txnCountSent')}`,
            leftValue: topTxCountSent?.address,
            rightLabel: t('totalTxn'),
            rightValue: topTxCountSent?.value,
        },
        {
            leftLabel: t('topBlocksMiner'),
            leftValue: topMiner?.address,
            rightLabel: t('blocksMined'),
            rightValue: topMiner?.value,
        },
        {
            leftLabel: t('txnCountReceived'),
            leftValue: topTxCountReceived?.address,
            rightLabel: t('totalTxn'),
            rightValue: topTxCountReceived?.value,
        },
    ];

    if (!Object.keys(topStatistics).length) {
        return <p className={style.noData}>{t('noDataYet')}</p>;
    }

    return (
        <div className={style.overView}>
            {data.map(item => {
                const { leftLabel, leftValue, rightLabel, rightValue } = item;

                return (
                    <div key={leftLabel} className={style.overView__item}>
                        <div className={style.overView__item_top}>
                            <p className={style.overView__item_label}>{leftLabel}</p>
                            <p className={style.overView__item_label}>{rightLabel}</p>
                        </div>
                        <div className={style.overView__item_bottom}>
                            <Link to={`${addressPath}/${leftValue}`} className={style.overView__item_address}>
                                {mobileWidth() ? truncate(leftValue, 18) : leftValue}
                            </Link>
                            <p className={style.overView__item_value}>{formatNumber(rightValue)}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

OverView.defaultProps = {
    topStatistics: {},
};

OverView.propTypes = {
    topStatistics: PropTypes.object,
};

export default OverView;

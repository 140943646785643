import { GET_DAILY_TRANSACTIONS_CHART } from '../constants';

const getDailyTransactionsChartRequested = () => ({
    type: GET_DAILY_TRANSACTIONS_CHART.GET_DAILY_TRANSACTIONS_CHART_REQUEST,
});

const getDailyTransactionsChartLoaded = data => ({
    type: GET_DAILY_TRANSACTIONS_CHART.GET_DAILY_TRANSACTIONS_CHART_SUCCESS,
    payload: data,
});

const getDailyTransactionsChartError = error => ({
    type: GET_DAILY_TRANSACTIONS_CHART.GET_DAILY_TRANSACTIONS_CHART_FAILURE,
    payload: error,
});

const getDailyTransactionsChartAction = getService => timeframe => dispatch => {
    dispatch(getDailyTransactionsChartRequested());
    getService
        .getDailyTransactionsChart(timeframe)
        .then(data => {
            dispatch(getDailyTransactionsChartLoaded(data));
        })
        .catch(err => {
            dispatch(getDailyTransactionsChartError(err));
        });
};

export default getDailyTransactionsChartAction;

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Radio, Checkbox } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import networkTitle from '@helpers/network';
import NOTIFICATION from '@constants/notification.constants';
import getWatchListAction from '@actions/get-watch-list.actions';
import deleteWatchListAction from '@actions/delete-watch-list.actions';
import putWatchListAction from '@actions/put-watch-list.actions';
import withTitle from '@hoc/with-title';
import Spinner from '@spinner';
import Field from '@UI/field/field';
import removeIcon from '@assets/images/icons/remove-icon.svg';
import withPostService from '@hoc/with-post-service';
import withGetService from '@hoc/with-get-service';
import { compose } from '@utils';
import Button from '@UI/button/button';
import { watchListPath } from '@constants';
import style from './watch-list.module.scss';

export class WatchListEdit extends Component {
    static defaultProps = {
        t: () => {},
        getWatchList: () => {},
        putWatchList: () => {},
        deleteWatchList: () => {},
        history: {},
        match: {},
        watchList: {},
        location: {},
        successWatchList: false,
    };

    static propTypes = {
        t: PropTypes.func,
        getWatchList: PropTypes.func,
        putWatchList: PropTypes.func,
        deleteWatchList: PropTypes.func,
        history: PropTypes.object,
        match: PropTypes.object,
        watchList: PropTypes.object,
        location: PropTypes.object,
        successWatchList: PropTypes.bool,
    };

    state = {
        address: '',
        description: '',
        tag: '',
        disabledSaveBtn: true,
        notificationStatus: NOTIFICATION.OFF,
        loading: true,
        internalCheckbox: false,
        emailCheckbox: false,
    };

    componentDidMount() {
        const { getWatchList } = this.props;
        getWatchList(100, 1);
    }

    componentDidUpdate(prevProps) {
        const {
            successWatchList,
            watchList,
            match: {
                params: { id },
            },
            history,
        } = this.props;

        if (successWatchList && successWatchList !== prevProps.successWatchList) {
            try {
                const findList = watchList.items.find(item => item.address === id);
                const { address, description, notificationStatus, tag, notificationDestination } = findList;

                let internalCheckbox = false;
                let emailCheckbox = false;

                if (notificationDestination === 'ALL') {
                    internalCheckbox = true;
                    emailCheckbox = true;
                }

                if (notificationDestination === 'INTERNAL') {
                    internalCheckbox = true;
                    emailCheckbox = false;
                }

                if (notificationDestination === 'EMAIL') {
                    internalCheckbox = false;
                    emailCheckbox = true;
                }

                this.setState({
                    address,
                    description,
                    notificationStatus,
                    disabledSaveBtn: false,
                    loading: false,
                    tag,
                    internalCheckbox,
                    emailCheckbox,
                });
            } catch (e) {
                history.push(watchListPath);
            }
        }
    }

    inputOnChange = event => {
        const { name, value } = event.target;

        if (name === 'description' && value.length <= 75) {
            this.setState({
                [name]: value,
            });
        }

        if (name === 'tag' && value.length <= 20) {
            this.setState({
                [name]: value.trim(),
            });
        }
    };

    radioOnChange = e => {
        const { value } = e.target;
        this.setState({
            notificationStatus: value,
        });
    };

    checkboxOnChange = e => {
        const { checked, name } = e.target;

        if (name === 'email') {
            this.setState({
                emailCheckbox: checked,
            });
        }

        if (name === 'internal') {
            this.setState({
                internalCheckbox: checked,
            });
        }
    };

    back = () => {
        const { history } = this.props;
        history.goBack();
    };

    save = () => {
        const {
            t,
            putWatchList,
            history,
            location: { search },
        } = this.props;
        const { address, notificationStatus, description, tag, internalCheckbox, emailCheckbox } = this.state;
        const parse = search.split('=');

        let notificationDestination = 'OFF';

        if (internalCheckbox && emailCheckbox) {
            notificationDestination = 'ALL';
        }

        if (internalCheckbox && !emailCheckbox) {
            notificationDestination = 'INTERNAL';
        }

        if (!internalCheckbox && emailCheckbox) {
            notificationDestination = 'EMAIL';
        }

        const data = {
            address,
            notificationStatus,
            description,
            tag,
            notificationDestination,
            currency: networkTitle(),
        };
        putWatchList(data, t, history, parse[1]);
    };

    remove = () => {
        const { t, deleteWatchList, history } = this.props;
        const { address } = this.state;

        const data = {
            address,
            currency: networkTitle(),
        };

        deleteWatchList(data, t, history);
    };

    render() {
        const { t } = this.props;
        const { address, loading, emailCheckbox, internalCheckbox } = this.state;
        const { tag, description, disabledSaveBtn, notificationStatus } = this.state;

        const isActiveCheckbox = notificationStatus !== NOTIFICATION.OFF && !emailCheckbox && !internalCheckbox;

        const isOnlyDescSpace = description.length && !/\S/.test(description);
        const disabledButton = disabledSaveBtn || isActiveCheckbox || !!isOnlyDescSpace;

        if (loading) {
            return (
                <div className={style.container}>
                    <Spinner />
                </div>
            );
        }

        return (
            <div className={style.container} id="watchListAdd">
                <div className={style.header}>
                    <p className={style.header__title}>{t('editWatchList')}</p>
                </div>
                <div className={style.edit}>
                    <div className={style.edit__row}>
                        <p className={style.edit__row_label}>{t('address')}</p>
                        <p className={style.edit__row_value}>{address}</p>
                    </div>
                    <div className={style.edit__row}>
                        <p className={style.edit__row_label}>{t('nameTag')}</p>
                        <div className={style.edit__inputContainer}>
                            <Field
                                id="tag"
                                type="text"
                                name="tag"
                                placeholder={t('optional')}
                                value={tag}
                                onChange={this.inputOnChange}
                                inputStyle={style.edit__input}
                                labelStyle={style.edit__label}
                                labelStyleActive={style.edit__labelActive}
                            />
                        </div>
                    </div>
                    <div className={style.edit__row}>
                        <p className={style.edit__row_label}>{t('description')}</p>
                        <div className={style.edit__inputContainer}>
                            <Field
                                id="description"
                                type="text"
                                name="description"
                                placeholder={t('optional')}
                                value={description}
                                onChange={this.inputOnChange}
                                inputStyle={style.edit__input}
                                labelStyle={style.edit__label}
                                labelStyleActive={style.edit__labelActive}
                            />
                        </div>
                    </div>
                    <p className={style.edit__text}>{t('monitorIncomingTransaction')}</p>
                    <br />
                    <br />
                    <p className={style.edit__text}>{t('selectNotification')}</p>
                    <div className={style.radioGroup}>
                        <Radio.Group onChange={this.radioOnChange} value={notificationStatus}>
                            <Radio value={NOTIFICATION.OFF}>{t('noNotification')}</Radio>
                            <Radio value={NOTIFICATION.ALL}>{t('notifyIncomingOutgoingTxns')}</Radio>
                            <Radio value={NOTIFICATION.ONLY_INCOMING}>{t('notifyOnlyIncoming')}</Radio>
                            <Radio value={NOTIFICATION.ONLY_OUTGOING}>{t('notifyOnlyOutgoing')}</Radio>
                        </Radio.Group>
                    </div>
                    {notificationStatus !== NOTIFICATION.OFF ? (
                        <div className={style.checkbox}>
                            <p className={style.checkbox__title}>{t('selectReceiveNotify')}</p>
                            <div className={style.checkbox__wrapper}>
                                <Checkbox
                                    checked={emailCheckbox}
                                    name="email"
                                    onChange={this.checkboxOnChange}
                                >
                                    <p className={style.form__checkboxContainer_text}>Email</p>
                                </Checkbox>
                                <Checkbox
                                    checked={internalCheckbox}
                                    name="internal"
                                    onChange={this.checkboxOnChange}
                                >
                                    <p className={style.form__checkboxContainer_text}>
                                        {t('internalNotifications')}
                                    </p>
                                </Checkbox>
                            </div>
                        </div>
                    ) : null}
                    <div className={style.edit__buttonWrapper}>
                        <div className={style.edit__buttonWrapper_leftSide}>
                            <Button
                                type="button"
                                className={style.edit__buttonWrapper_back}
                                onClick={this.back}
                            >
                                {t('back')}
                            </Button>
                            <Button
                                type="submit"
                                className={style.edit__buttonWrapper_save}
                                onClick={this.save}
                                disabled={disabledButton}
                            >
                                {t('save')}
                            </Button>
                        </div>
                        <Button
                            type="submit"
                            className={style.edit__buttonWrapper_remove}
                            onClick={this.remove}
                        >
                            <img src={removeIcon} alt="removeIcon" />
                            {t('remove')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        updateWatchList: { data: updateWatchList, success, loading },
        deleteWatchList: { deleteWatchList },
        getWatchList: {
            data: { watchListArr: watchList },
            success: successWatchList,
        },
    } = state;

    return {
        updateWatchList,
        success,
        loading,
        deleteWatchList,
        watchList,
        successWatchList,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        getWatchList: getWatchListAction(getService),
        putWatchList: putWatchListAction(postService),
        deleteWatchList: deleteWatchListAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    withGetService(),
    withTitle({ title: '| Edit Watch List' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(WatchListEdit);

import { GET_DIFFICULTY_CHART } from '../constants';

const getDifficultyChartRequested = () => ({
    type: GET_DIFFICULTY_CHART.GET_DIFFICULTY_CHART_REQUEST,
});

const getDifficultyChartLoaded = data => ({
    type: GET_DIFFICULTY_CHART.GET_DIFFICULTY_CHART_SUCCESS,
    payload: data,
});

const getDifficultyChartError = error => ({
    type: GET_DIFFICULTY_CHART.GET_DIFFICULTY_CHART_FAILURE,
    payload: error,
});

const getDifficultyChartAction = getService => timeframe => dispatch => {
    dispatch(getDifficultyChartRequested());
    getService
        .getDifficultyChart(timeframe)
        .then(data => {
            dispatch(getDifficultyChartLoaded(data));
        })
        .catch(err => {
            dispatch(getDifficultyChartError(err));
        });
};

export default getDifficultyChartAction;

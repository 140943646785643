import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import getWatchListAction from '@actions/get-watch-list.actions';
import withTitle from '@hoc/with-title';
import withGetService from '@hoc/with-get-service';
import WatchListView from './watch-list-view';
import { compose } from '@utils';
import Spinner from '@spinner';
import { editWatchListPath, watchListPath } from '@constants';
import style from './watch-list.module.scss';

export class WatchList extends Component {
    static defaultProps = {
        getWatchList: () => {},
        history: {},
        loading: false,
        success: false,
        watchList: {},
        location: {},
    };

    static propTypes = {
        getWatchList: PropTypes.func,
        history: PropTypes.object,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        watchList: PropTypes.object,
        location: PropTypes.object,
    };

    state = {
        currentPageNumber: '',
        numItemsPerPage: 4,
        items: [],
        totalCount: '',
        grandTotal: '',
        grandTotalUSD: '',
    };

    componentDidMount() {
        const {
            location: { search },
        } = this.props;
        const { numItemsPerPage } = this.state;
        const parse = search.split('=');
        this.loadData(numItemsPerPage, parse[1] || 1);
    }

    componentDidUpdate(prevProps) {
        const {
            success,
            watchList: { currentPageNumber, items, totalCount, grandTotal, grandTotalUSD },
        } = this.props;

        if (success && success !== prevProps.success) {
            this.setState({
                currentPageNumber,
                items,
                totalCount,
                grandTotal,
                grandTotalUSD,
            });
        }
    }

    loadData = (itemsPerPage, numberPage) => {
        const { getWatchList } = this.props;
        getWatchList(itemsPerPage, numberPage);
    };

    openEdit = id => {
        const { history } = this.props;
        const { currentPageNumber } = this.state;

        history.push({
            pathname: `${editWatchListPath}/${id}`,
            search: `?page=${currentPageNumber}`,
        });
    };

    changePagination = number => {
        const { history } = this.props;
        const { numItemsPerPage } = this.state;

        history.push({
            pathname: watchListPath,
            search: `?page=${number}`,
        });
        this.loadData(numItemsPerPage, number);
    };

    render() {
        const { loading, watchList } = this.props;
        const {
            currentPageNumber,
            numItemsPerPage,
            items,
            totalCount,
            grandTotal,
            grandTotalUSD,
        } = this.state;

        if (loading) {
            return (
                <div className={style.container}>
                    <Spinner />
                </div>
            );
        }

        return (
            <div className={style.container}>
                <WatchListView
                    openEdit={this.openEdit}
                    hangePagination={this.changePagination}
                    watchList={watchList}
                    currentPageNumber={currentPageNumber}
                    numItemsPerPage={numItemsPerPage}
                    items={items}
                    totalCount={totalCount}
                    grandTotal={grandTotal}
                    grandTotalUSD={grandTotalUSD}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        getWatchList: {
            data: { watchListArr: watchList },
            success,
            loading,
        },
    } = state;

    return {
        watchList,
        success,
        loading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getWatchList: getWatchListAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Watch List' }),
    connect(mapStateToProps, mapDispatchToProps),
)(WatchList);

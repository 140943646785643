import { GET_TRANSACTION_STATUS } from '../constants';

const getTransactionStatusRequested = () => ({
    type: GET_TRANSACTION_STATUS.GET_TRANSACTION_STATUS_REQUEST,
});

const getTransactionStatusLoaded = data => ({
    type: GET_TRANSACTION_STATUS.GET_TRANSACTION_STATUS_SUCCESS,
    payload: data,
});

const getTransactionStatusError = error => ({
    type: GET_TRANSACTION_STATUS.GET_TRANSACTION_STATUS_FAILURE,
    payload: error,
});

const getTransactionStatusAction = getService => hash => dispatch => {
    dispatch(getTransactionStatusRequested());
    getService
        .getTransactionStatus(hash)
        .then(data => {
            dispatch(getTransactionStatusLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getTransactionStatusError(err));
        });
};

export default getTransactionStatusAction;

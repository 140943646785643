import notification from '../helpers/notifications';
import { PUT_WATCH_LIST, WATCHLIST_ADDRESS_NOT_FOUND, watchListPath } from '../constants';

const putWatchListRequested = () => ({
    type: PUT_WATCH_LIST.PUT_WATCH_LIST_REQUEST,
});

const putWatchListLoaded = data => ({
    type: PUT_WATCH_LIST.PUT_WATCH_LIST_SUCCESS,
    payload: data,
});

const putWatchListError = error => ({
    type: PUT_WATCH_LIST.PUT_WATCH_LIST_FAILURE,
    payload: error,
});

const putWatchListAction = postService => (values, t, history, page) => dispatch => {
    dispatch(putWatchListRequested());
    postService
        .updateWatchList(values)
        .then(data => {
            dispatch(putWatchListLoaded(data));
            notification('', t('updatedSuccessfully'), 'success');
            let path = watchListPath;
            if (page) {
                path = {
                    pathname: watchListPath,
                    search: `?page=${page}`,
                };
            }
            history.push(path);
        })
        .catch(error => {
            dispatch(putWatchListError(error.response.data.message));
            const {
                response: { data: { message = 'Something went wrong' } = {} } = {},
            } = error;
            let errorMessage = message;

            if (message === WATCHLIST_ADDRESS_NOT_FOUND) {
                errorMessage = t('error.addressNotFound');
            }

            notification('', errorMessage, 'danger');
        });
};

export default putWatchListAction;

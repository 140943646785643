import axios from 'axios';
import { Language } from '../helpers/request-header';

const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
export default class PostService {
    getResource = async (url, data, method) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const getToken = () => user.token;
        const Authorization = user ? `Bearer ${getToken()}` : '';

        const options = {
            url,
            method,
            headers: {
                Authorization,
                language: Language(),
            },
            data,
        };
        const response = await axios(options);

        if (response.status !== 200) {
            throw new Error(`${url},received ${response.status}`);
        }
        return response.data;
    };

    logout = () => {
        localStorage.removeItem('user');
        document.location.reload(true);
    };

    verificationUser = async data => {
        const res = await this.getResource(`${AUTH_URL}/auth/verify`, data, 'POST');
        return res;
    };

    passwordRecovery = async data => {
        const res = await this.getResource(`${AUTH_URL}/auth/recover`, data, 'POST');
        return res;
    };

    resetPassword = async data => {
        const res = await this.getResource(`${AUTH_URL}/auth/reset`, data, 'POST');
        return res;
    };

    checkResetToken = async data => {
        const res = await this.getResource(`${AUTH_URL}/auth/check-reset-token`, data, 'POST');
        return res;
    };

    changePassword = async data => {
        const res = await this.getResource(`${AUTH_URL}/auth/changePassword`, data, 'PUT');
        return res;
    };

    deleteAvatar = async data => {
        const res = await this.getResource(`${AUTH_URL}/user/upload`, data, 'DELETE');
        return res;
    };

    updateProfile = async data => {
        const res = await this.getResource(`${AUTH_URL}/user`, data, 'PUT');
        return res;
    };

    addWatchList = async data => {
        const res = await this.getResource(`${AUTH_URL}/watchlist`, data, 'POST');
        return res;
    };

    updateWatchList = async data => {
        const res = await this.getResource(`${AUTH_URL}/watchlist`, data, 'PUT');
        return res;
    };

    deleteWatchList = async data => {
        const res = await this.getResource(`${AUTH_URL}/watchlist`, data, 'DELETE');
        return res;
    };

    addPrivateNodeList = async data => {
        const res = await this.getResource(`${AUTH_URL}/privateNodeList`, data, 'POST');
        return res;
    };

    updatePrivateNodeList = async data => {
        const res = await this.getResource(`${AUTH_URL}/privateNodeList`, data, 'PUT');
        return res;
    };

    deletePrivateNodeList = async data => {
        const res = await this.getResource(`${AUTH_URL}/privateNodeList`, data, 'DELETE');
        return res;
    };

    deleteNotifier = async data => {
        const res = await this.getResource(`${AUTH_URL}/txNotifier`, data, 'DELETE');
        return res;
    };
}

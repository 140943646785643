/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import mobileWidth from '@helpers/mobile-width';
import truncate from '@helpers/truncate-string';
import formatNumber from '@helpers/format-number';
import { addressPath } from '@constants';
import style from './top-statistics.module.scss';

const Miners = ({ topMiners }) => {
    const { t } = useTranslation();

    return (
        <div className={style.container}>
            <h3 className={style.container__title}>{t('topMinersByBlocks')}</h3>
            {!topMiners?.length ? <p className={style.noData}>{t('noDataYet')}</p> : null}
            <div className={style.table}>
                {topMiners.map((item, index) => {
                    const { mined_by, blocksMined, blocks_reward, fee } = item;

                    return (
                        <div key={mined_by} className={style.table__row}>
                            <div className={style.table__column1}>
                                <p className={style.table__label}>{t('rank')}</p>
                                <p className={style.table__text}>{index + 1}</p>
                            </div>
                            <div className={style.table__column2}>
                                <p className={style.table__label}>{t('address')}</p>
                                <Tooltip placement="topLeft" title={mined_by}>
                                    <Link to={`${addressPath}/${mined_by}`} className={style.table__link}>
                                        {mobileWidth() ? truncate(mined_by, 31) : mined_by}
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className={style.table__column3}>
                                <p className={style.table__label}>{t('totalBlocksMined')}</p>
                                <p className={style.table__text}>{formatNumber(blocksMined)}</p>
                            </div>
                            <div className={style.table__column4}>
                                <p className={style.table__label}>{t('totalRewards')}</p>
                                <p className={style.table__text}>{formatNumber(blocks_reward)}</p>
                            </div>
                            <div className={style.table__column5}>
                                <p className={style.table__label}>{t('totalTxnFees')}</p>
                                <p className={style.table__text}>{formatNumber(fee)}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Miners.defaultProps = {
    topMiners: [],
};

Miners.propTypes = {
    topMiners: PropTypes.instanceOf(Array),
};

export default Miners;

import React from 'react';
import PropTypes from 'prop-types';

const SettingsIcon = ({ className }) => (
    <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.284 8.53589C15.3155 8.25084 15.3355 7.9618 15.3355 7.66775C15.3355 7.3737 15.3155 7.08465 15.2835 6.79911L13.0941 6.02048C12.9851 5.66242 12.8421 5.32037 12.6691 4.99531L13.6652 2.89897C13.3027 2.44433 12.8904 2.03169 12.436 1.66877L10.3397 2.66693C10.0129 2.49154 9.66951 2.34915 9.31452 2.24187L8.53639 0.0525086C8.25084 0.0205033 7.9618 0 7.66775 0C7.3737 0 7.08465 0.0200033 6.79911 0.0525086L6.02098 2.24187C5.66292 2.35038 5.32087 2.49291 4.99581 2.66693L2.89897 1.66877C2.4439 2.03133 2.03183 2.4439 1.66877 2.89897L2.66693 4.99431C2.49291 5.31937 2.35038 5.66192 2.24187 6.01948L0.0525086 6.79961C0.0205033 7.08465 0 7.3747 0 7.66825C0 7.9618 0.0200033 8.25084 0.0525086 8.53639L2.24187 9.31552C2.35038 9.67308 2.49291 10.0156 2.66693 10.3407L1.66927 12.437C2.03183 12.8921 2.4444 13.3037 2.89947 13.6662L4.99431 12.6686C5.31937 12.8426 5.66192 12.9856 6.01948 13.0946L6.79861 15.2835C7.08365 15.315 7.3727 15.335 7.66725 15.335C7.9613 15.335 8.24984 15.315 8.53539 15.283L9.31452 13.0936C9.67208 12.9846 10.0146 12.8416 10.3397 12.6686L12.436 13.6647C12.8911 13.3022 13.3027 12.8896 13.6652 12.4355L12.6686 10.3397C12.8426 10.0161 12.9856 9.67258 13.0946 9.31452L15.284 8.53589ZM7.66775 10.1031C7.34786 10.1032 7.0311 10.0403 6.73553 9.91792C6.43997 9.79556 6.1714 9.61619 5.94516 9.39004C5.48825 8.93332 5.23149 8.31379 5.23135 7.66775C5.23122 7.02171 5.48773 6.40208 5.94446 5.94516C6.40118 5.48825 7.02071 5.23149 7.66675 5.23135C8.31279 5.23122 8.93242 5.48773 9.38933 5.94446C9.84625 6.40118 10.103 7.02071 10.1031 7.66675C10.1033 8.31279 9.84677 8.93242 9.39004 9.38933C8.93332 9.84625 8.31379 10.103 7.66775 10.1031Z"
            fill="#262642"
        />
    </svg>
);

SettingsIcon.defaultProps = {
    className: '',
};

SettingsIcon.propTypes = {
    className: PropTypes.string,
};

export default SettingsIcon;

import { GET_TOP_NETWORK } from '../constants';

const getTopNetworkRequested = () => ({
    type: GET_TOP_NETWORK.GET_TOP_NETWORK_REQUEST,
});

const getTopNetworkLoaded = data => ({
    type: GET_TOP_NETWORK.GET_TOP_NETWORK_SUCCESS,
    payload: data,
});

const getTopNetworkError = error => ({
    type: GET_TOP_NETWORK.GET_TOP_NETWORK_FAILURE,
    payload: error,
});

const getTopNetworkAction = getService => timeframe => dispatch => {
    dispatch(getTopNetworkRequested());
    getService
        .getTopNetwork(timeframe)
        .then(data => {
            dispatch(getTopNetworkLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getTopNetworkError(err));
        });
};

export default getTopNetworkAction;

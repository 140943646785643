/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { compose } from '@utils';
import formatNumber from '@helpers/format-number';
import truncate from '@helpers/truncate-string';
import mobileWidth from '@helpers/mobile-width';
import Pagination from '@layouts/paginations';
import ArrowRight from '@assets/images/icons/arrow-right';
import SettingsIcon from '@assets/images/icons/settings-icon';
import { transactionPath, blockPath, addressPath } from '@constants';
import btcuTicker from '@assets/images/icons/btcu-ticker-icon.svg';
import plusIcon from '@assets/images/icons/plus-icon.svg';
import minusIcon from '@assets/images/icons/minus-icon.svg';
import style from './transactions.module.scss';

const Transactions = ({
    records,
    changePagination,
    currentPageNumber,
    numItemsPerPage,
    data,
    totalCount,
    match: {
        params: { id: address },
    },
}) => {
    const { t } = useTranslation();
    const [details, setDetails] = useState('');

    const switchActiveTab = hash => {
        setDetails(hash);
    };

    return (
        <Fragment>
            <div className={style.transactions}>
                {data.map(item => {
                    const {
                        hash,
                        block_id,
                        time,
                        output_total,
                        fee,
                        from,
                        outputs = [],
                        is_stacking,
                        id,
                        input_total,
                    } = item;

                    const getTime = new Date(+time);
                    const timeFromNow = moment(getTime).fromNow();

                    const isOpen = details === hash;

                    const detailsStyle = isOpen
                        ? classNames(style.details, style.detailsOpened)
                        : style.details;

                    const openInfo = (
                        <Fragment>
                            {from.length ? (
                                <div
                                    className={style.transactions__row_dotWrapper}
                                    onClick={() => switchActiveTab(isOpen ? '' : hash)}
                                >
                                    <div className={style.transactions__row_dot}>
                                        <img src={isOpen ? minusIcon : plusIcon} alt="icon" />
                                    </div>
                                </div>
                            ) : (
                                <div className={style.transactions__row_dotWrapperDisabled}>
                                    <div className={style.transactions__row_dot}>
                                        <SettingsIcon />
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    );

                    const blockValue = block_id === '-1' ? t('forkedFromBitcoin') : block_id;

                    return (
                        <Fragment key={`${id}${hash}`}>
                            <div className={style.transactions__row}>
                                {openInfo}
                                <div className={style.transactions__column1}>
                                    <p className={style.transactions__label}>{t('txnHash')}</p>
                                    <Link
                                        to={`${transactionPath}/${hash}`}
                                        className={style.transactions__link}
                                    >
                                        {`${mobileWidth() ? truncate(hash, 15) : hash}`}
                                    </Link>
                                </div>
                                <div className={style.transactions__column2}>
                                    <p className={style.transactions__label}>{t('block')}</p>
                                    <Link
                                        to={`${blockPath}/${block_id}`}
                                        className={style.transactions__link}
                                    >
                                        {blockValue}
                                    </Link>
                                </div>
                                <div className={style.transactions__column3}>
                                    <p className={style.transactions__label}>{t('age')}</p>
                                    <p className={style.transactions__text}>{timeFromNow}</p>
                                </div>
                                <div className={style.transactions__column6}>
                                    <p className={style.transactions__label}>{t('value')}</p>
                                    <p className={style.transactions__text}>{formatNumber(output_total)}</p>
                                </div>
                                <div className={style.transactions__column7}>
                                    <p className={style.transactions__label}>Txn {t('fee')}</p>
                                    <p className={style.transactions__text}>
                                        {fee === null ? '-' : formatNumber(fee)}
                                    </p>
                                </div>
                            </div>
                            <div className={detailsStyle}>
                                <div className={style.details__wrapper}>
                                    <DetailsLeftSide
                                        t={t}
                                        from={from}
                                        address={address}
                                        input_total={input_total}
                                    />
                                    <DetailsRightSide
                                        t={t}
                                        outputs={outputs}
                                        address={address}
                                        is_stacking={is_stacking}
                                    />
                                </div>
                                <div className={style.details__total}>
                                    <p>
                                        <img src={btcuTicker} alt="btcuTicker" />
                                        {t('fee')}: {formatNumber(fee)} BTCU
                                    </p>
                                    <p>
                                        <img src={btcuTicker} alt="btcuTicker" />
                                        {t('total')}: {formatNumber(output_total)} BTCU
                                    </p>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            <Pagination
                numItemsPerPage={numItemsPerPage}
                totalCount={totalCount}
                currentPageNumber={currentPageNumber}
                recordsOnClick={records}
                paginationOnChange={changePagination}
            />
        </Fragment>
    );
};

const DetailsLeftSide = ({ t, from, address, input_total }) => (
    <div className={style.details__leftSide}>
        <div className={style.details__labelWrapper}>
            <p className={style.details__label}>{t('inputs')}</p>
            <p className={style.details__label}>{t('totalInput')}</p>
        </div>
        {from.map((item, index) => {
            const link = mobileWidth() ? truncate(item, 15) : item;
            const linkWrapper = address === item ? <p>{link}</p> : <Link to={`${addressPath}/${item}`}>{link}</Link>;
            return (
                <div key={index} className={style.details__row}>
                    {linkWrapper}
                    <div className={style.details__row_value}>
                        <img src={btcuTicker} alt="btcuTicker" />
                        <p>{formatNumber(input_total)} BTCU</p>
                    </div>
                </div>
            );
        })}
    </div>
);

const DetailsRightSide = ({ t, outputs, address, is_stacking }) => {
    if (!outputs.length) {
        return null;
    }

    return (
        <Fragment>
            <ArrowRight className={style.details__arrow} />
            <div className={style.details__rightSide}>
                <div className={style.details__labelWrapper}>
                    <p className={style.details__label}>{t('outputs')}</p>
                    <p className={style.details__label}>{t('outputTotal')}</p>
                </div>
                <div className={style.details__rowWrapper}>
                    {is_stacking ? (
                        <div className={style.details__row}>
                            <p className={style.details__row_staking}>{t('stakingReward')}</p>
                        </div>
                    ) : null}
                    {outputs.map(items => {
                        const { recipient, value, id: Id } = items;

                        const link = mobileWidth() ? truncate(recipient, 15) : recipient;

                        const linkWrapper = address === recipient ? (
                            <p>{link}</p>
                        ) : (
                            <Link to={`${addressPath}/${recipient}`}>{link}</Link>
                        );

                        return (
                            <div key={`${Id}${value}`} className={style.details__row}>
                                {linkWrapper}
                                <div className={style.details__row_value}>
                                    <img src={btcuTicker} alt="btcuTicker" />
                                    <p>{formatNumber(value)} BTCU</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
};

Transactions.defaultProps = {
    records: () => {},
    changePagination: () => {},
    match: {},
    currentPageNumber: '',
    numItemsPerPage: '',
    data: '',
    totalCount: '',
};

DetailsLeftSide.defaultProps = {
    t: () => {},
    from: [],
    address: '',
    input_total: '',
};
DetailsRightSide.defaultProps = {
    t: () => {},
    outputs: [],
    address: '',
    is_stacking: false,
};

DetailsLeftSide.propTypes = {
    t: PropTypes.func,
    from: PropTypes.instanceOf(Array),
    address: PropTypes.string,
    input_total: PropTypes.any,
};
DetailsRightSide.propTypes = {
    t: PropTypes.func,
    outputs: PropTypes.instanceOf(Array),
    address: PropTypes.string,
    is_stacking: PropTypes.bool,
};

Transactions.propTypes = {
    records: PropTypes.func,
    changePagination: PropTypes.func,
    match: PropTypes.object,
    currentPageNumber: PropTypes.any,
    numItemsPerPage: PropTypes.any,
    data: PropTypes.instanceOf(Array),
    totalCount: PropTypes.any,
};

export default compose(withRouter)(Transactions);

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Tooltip } from 'antd';
import truncate from '@helpers/truncate-string';
import mobileWidth from '@helpers/mobile-width';
import Pagination from '@layouts/paginations';
import Button from '@UI/button';
import { compose } from '@utils';
import { addTxnPrivateNotesPath, transactionPath } from '@constants';
import addIcon from '@assets/images/icons/add-icon.svg';
import noDataIcon from '@assets/images/icons/no-data-icon.svg';
import style from './private-notes.module.scss';

const PrivateNotesView = ({
    openEdit,
    hangePagination,
    privateNodeList: { items = [] },
    currentPageNumber,
    numItemsPerPage,
    totalCount,
}) => {
    const { t } = useTranslation();

    const Header = () => (
        <div className={style.header}>
            <p className={style.header__title}>{t('myTXPrivateNotes')}</p>
            <Link to={addTxnPrivateNotesPath} className={style.header__add}>
                <img src={addIcon} alt="addIcon" /> {t('add')}
            </Link>
        </div>
    );

    if (!items.length) {
        return (
            <Fragment>
                <Header />
                <div className={style.noData}>
                    <img className={style.noData__icon} src={noDataIcon} alt="noData" />
                    <p className={style.noData__title}>{t('dontHavePrivateNote')}</p>
                    <Link to={addTxnPrivateNotesPath} className={style.header__add}>
                        <img src={addIcon} alt="addIcon" /> {t('add')}
                    </Link>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Header />
            <p className={style.totalContractsFound}>{t('totalFound', { digit: totalCount })}</p>
            <div className={style.table}>
                <div className={style.table__head}>
                    <div>{t('action')}</div>
                    <div>{t('txPrivateNote')}</div>
                    <div>{t('created')}</div>
                </div>
                {items.map(item => {
                    const { createdAt, description, txHash } = item;

                    return (
                        <div key={createdAt} className={style.table__row}>
                            <div>
                                <Button
                                    className={style.table__row_button}
                                    onClick={() => openEdit(txHash)}
                                    type="button"
                                >
                                    {t('edit')}
                                </Button>
                            </div>
                            <div className={style.table__row_tokenContractAddress}>
                                <span className={style.table__row_mobileTitle}>{t('txPrivateNote')}</span>
                                <Tooltip placement="topLeft" title={txHash}>
                                    <Link to={`${transactionPath}/${txHash}`}>
                                        {mobileWidth() ? truncate(txHash, 38) : truncate(txHash, 50)}
                                    </Link>
                                </Tooltip>
                                {description ? (
                                    <span className={style.table__row_appName}>
                                        {t('privateNote')}: {description}
                                    </span>
                                ) : null}
                            </div>
                            <div className={style.table__row_created}>
                                <span className={style.table__row_mobileTitle}>{t('created')}</span>
                                <p>
                                    <Moment format="DD.MM.YYYY" unix>
                                        {createdAt / 1000}
                                    </Moment>
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={style.pagination}>
                <Pagination
                    numItemsPerPage={numItemsPerPage}
                    totalCount={totalCount}
                    currentPageNumber={currentPageNumber}
                    paginationOnChange={hangePagination}
                    disabledRecords
                    minPagination={5}
                />
            </div>
        </Fragment>
    );
};

PrivateNotesView.defaultProps = {
    openEdit: () => {},
    hangePagination: () => {},
    privateNodeList: {},
    numItemsPerPage: '',
    totalCount: 1,
    currentPageNumber: '',
};

PrivateNotesView.propTypes = {
    openEdit: PropTypes.func,
    hangePagination: PropTypes.func,
    privateNodeList: PropTypes.object,
    numItemsPerPage: PropTypes.any,
    totalCount: PropTypes.any,
    currentPageNumber: PropTypes.any,
};

export default compose(connect(), withRouter)(PrivateNotesView);

const headTitle = () => {
    const networkTicker = localStorage.getItem('networkTicker');

    if (networkTicker === 'btcu') {
        return 'BTCU Explorer';
    }

    return 'Orion BTCU Explorer';
};

export default headTitle;

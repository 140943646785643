import { GET_TX_NOTIFIER } from '../constants';

const getTxNotifierRequested = () => ({
    type: GET_TX_NOTIFIER.GET_TX_NOTIFIER_REQUEST,
});

const getTxNotifierLoaded = data => ({
    type: GET_TX_NOTIFIER.GET_TX_NOTIFIER_SUCCESS,
    payload: data,
});

const getTxNotifierError = error => ({
    type: GET_TX_NOTIFIER.GET_TX_NOTIFIER_FAILURE,
    payload: error,
});

const getTxNotifierAction = getService => () => dispatch => {
    dispatch(getTxNotifierRequested());
    getService
        .getTxNotifier()
        .then(data => {
            dispatch(getTxNotifierLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getTxNotifierError(err));
        });
};

export default getTxNotifierAction;

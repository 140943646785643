/* eslint-disable react/no-danger */
import React from 'react';
import SearchIcon from '@assets/images/icons/search-icon';
import SearchFilter from '@layouts/search-filter/search-filter';
import HomeHeader from '@layouts/header/header-home';
import mobileWidth from '@helpers/mobile-width';
import style from './first-screen.module.scss';
import verctor1 from './images/vector1.svg';
import verctor2 from './images/vector2.svg';
import mobileVerctor from './images/mobile-vector.svg';

const FirstScreen = () => {
    const networkTicker = localStorage.getItem('networkTicker');

    const title = () => {
        if (networkTicker === 'btcu') {
            return 'The <span>BTCU</span> Explorer';
        }

        return 'The <span>Orion BTCU</span> Explorer';
    };

    return (
        <div className={style.firstScreen}>
            <HomeHeader />
            <div className={style.firstScreen__container}>
                <h1 className={style.firstScreen__title} dangerouslySetInnerHTML={{ __html: title() }} />
                <SearchFilter
                    button={(
                        <button className={style.filter__button} type="submit" aria-label="search">
                            <SearchIcon className={style.filter__searchIcon} />
                        </button>
                    )}
                    inputClassName={style.filter__input}
                    truncateMainString={mobileWidth() ? 12 : 38}
                    truncateHashString={25}
                    update
                />
            </div>
            <img className={style.firstScreen__vector1} src={verctor1} alt="verctor1" />
            <img className={style.firstScreen__vector2} src={verctor2} alt="verctor2" />
            <img className={style.firstScreen__vector3} src={mobileVerctor} alt="verctor" />
        </div>
    );
};

export default FirstScreen;

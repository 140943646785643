import notification from '../helpers/notifications';
import {
    DELETE_NOTIFIER,
} from '../constants';

const deleteNotifierRequested = () => ({
    type: DELETE_NOTIFIER.DELETE_NOTIFIER_REQUEST,
});

const deleteNotifierLoaded = data => ({
    type: DELETE_NOTIFIER.DELETE_NOTIFIER_SUCCESS,
    payload: data,
});

const deleteNotifierError = error => ({
    type: DELETE_NOTIFIER.DELETE_NOTIFIER_FAILURE,
    payload: error,
});

const deleteNotifierAction = postService => (values, t) => dispatch => {
    dispatch(deleteNotifierRequested());
    postService
        .deleteNotifier(values)
        .then(data => {
            dispatch(deleteNotifierLoaded(data));
            notification('', t('notificationRemoved'), 'success');
        })
        .catch(error => {
            dispatch(deleteNotifierError(error.response.data.message));
            const {
                response: { data: { message = 'Something went wrong' } = {} } = {},
            } = error;
            const errorMessage = message;

            notification('', errorMessage, 'danger');
        });
};

export default deleteNotifierAction;

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highcharts3d from '@helpers/highcharts-3d';
import mobileWidth from '@helpers/mobile-width';
import truncate from '@helpers/truncate-string';
import formatNumber from '@helpers/format-number';
import { addressPath } from '@constants';
import style from './top-statistics.module.scss';

highcharts3d(Highcharts);

const Network = ({ topNetwork }) => {
    const { t } = useTranslation();
    const optionsData = [];

    topNetwork.map((item, index) => {
        const { parcentage, totalTxs } = item;

        const data = {
            name: `#${index + 1}`,
            y: totalTxs,
            value: `${formatNumber(totalTxs)}`,
            percent: `${formatNumber(parcentage)}%`,
        };

        return optionsData.push(data);
    });

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: mobileWidth() ? '100%' : '75%',
            options3d: {
                enabled: true,
                alpha: 45,
            },
        },
        title: {
            margin: 30,
            text: t('accountVsTxnCount'),
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.value:.1f}</b>',
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        colors: ['#FF2911', '#ffa9a0', '#262642'],
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: mobileWidth() ? false : true,
                    format: '<b>{point.name}</b>: {point.percent:.1f}',
                },
            },
        },
        series: [
            {
                name: t('count'),
                colorByPoint: true,
                data: optionsData,
            },
        ],
    };

    return (
        <div className={style.container}>
            <h3 className={style.container__title}>{t('topAccountsbyTxnCount')}</h3>
            {!topNetwork?.length ? (
                <p className={style.noData}>{t('noDataYet')}</p>
            ) : (
                <div className={style.network}>
                    <div className={style.network__leftSide}>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                    <div className={style.table}>
                        {topNetwork?.map((item, index) => {
                            const { address, parcentage, totalTxs } = item;

                            return (
                                <div key={address} className={style.table__row}>
                                    <div className={style.table__column1}>
                                        <p className={style.table__label}>{t('rank')}</p>
                                        <p className={style.table__text}>{index + 1}</p>
                                    </div>
                                    <div className={style.table__column2}>
                                        <p className={style.table__label}>{t('address')}</p>
                                        <Tooltip placement="topLeft" title={address}>
                                            <Link
                                                to={`${addressPath}/${address}`}
                                                className={style.table__link}
                                            >
                                                {mobileWidth()
                                                    ? truncate(address, 31)
                                                    : truncate(address, 33)}
                                            </Link>
                                        </Tooltip>
                                    </div>
                                    <div className={style.table__column3}>
                                        <p className={style.table__label}>{t('count')}</p>
                                        <p className={style.table__text}>
                                            {formatNumber(totalTxs)}
                                            {'  '}({formatNumber(parcentage, { decimal: 2 })}
                                            %)
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

Network.defaultProps = {
    topNetwork: [],
};

Network.propTypes = {
    topNetwork: PropTypes.instanceOf(Array),
};

export default Network;

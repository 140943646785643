import { GET_WATCH_LIST } from '../constants';

const getWatchListRequested = () => ({
    type: GET_WATCH_LIST.GET_WATCH_LIST_REQUEST,
});

const getWatchListLoaded = data => ({
    type: GET_WATCH_LIST.GET_WATCH_LIST_SUCCESS,
    payload: data,
});

const getWatchListError = error => ({
    type: GET_WATCH_LIST.GET_WATCH_LIST_FAILURE,
    payload: error,
});

const getWatchListAction = getService => (itemsPerPage, pageNumber) => dispatch => {
    dispatch(getWatchListRequested());
    getService
        .getWatchList(itemsPerPage, pageNumber)
        .then(data => {
            dispatch(getWatchListLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getWatchListError(err));
        });
};

export default getWatchListAction;

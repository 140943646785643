import { GET_SUPPLY_CHART } from '../constants';

const getSupplyChartRequested = () => ({
    type: GET_SUPPLY_CHART.GET_SUPPLY_CHART_REQUEST,
});

const getSupplyChartLoaded = data => ({
    type: GET_SUPPLY_CHART.GET_SUPPLY_CHART_SUCCESS,
    payload: data,
});

const getSupplyChartError = error => ({
    type: GET_SUPPLY_CHART.GET_SUPPLY_CHART_FAILURE,
    payload: error,
});

const getSupplyChartAction = getService => timeframe => dispatch => {
    dispatch(getSupplyChartRequested());
    getService
        .getSupplyChart(timeframe)
        .then(data => {
            dispatch(getSupplyChartLoaded(data));
        })
        .catch(err => {
            dispatch(getSupplyChartError(err));
        });
};

export default getSupplyChartAction;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    loginPath,
    registartionPath,
    passowrdRecoveryPath,
    resetPasswordPath,
} from '@constants/pathLocation';
import logo from '@assets/images/logos/btcu.svg';
import { compose } from '@utils';
import companyIcon from '@assets/images/icons/company-icon.svg';
import locationIcon from '@assets/images/icons/location-icon.svg';
import contactIcon from '@assets/images/icons/contact-icon.svg';
import style from './footer.module.scss';

const Footer = ({ location: { pathname } }) => {
    const { t } = useTranslation();
    const hideList = [loginPath, registartionPath, passowrdRecoveryPath, resetPasswordPath];

    if (hideList.includes(pathname)) {
        return null;
    }

    const btcuLink = 'https://btcu.io';

    return (
        <footer className={style.footer} id="footer">
            <div className={style.footer__top}>
                <Link to="/" className={style.footer__logo}>
                    <img className={style.footer__logo_img} src={logo} alt="logo" />
                </Link>
                <div className={style.linksWrapper}>
                    <ul className={style.list}>
                        <li className={style.list__title}>
                            <img src={companyIcon} alt="companyIcon" />
                            {t('company')}
                        </li>
                        <li className={style.list__link}>
                            <a href={`${btcuLink}/who-we-are`} target="_blank" rel="noopener noreferrer">
                                {t('whoWeAre')}
                            </a>
                        </li>
                        <li className={style.list__link}>
                            <a
                                href={`${btcuLink}/how-does-it-work`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('howItWorks')}
                            </a>
                        </li>
                        <li className={style.list__link}>
                            <a href={`${btcuLink}/partnership`} target="_blank" rel="noopener noreferrer">
                                {t('partnership')}
                            </a>
                        </li>
                        <li className={style.list__link}>
                            <a href={`${btcuLink}/community`} target="_blank" rel="noopener noreferrer">
                                {t('community')}
                            </a>
                        </li>
                    </ul>
                    <ul className={style.list}>
                        <li className={style.list__title}>
                            <img src={locationIcon} alt="location" />
                            {t('location')}
                        </li>
                        <li className={style.list__text}>{t('hongKongChina')}</li>
                        <li className={style.list__text}>7/F MW Tower 111</li>
                        <li className={style.list__text}>Bonham Strand</li>
                        <li className={style.list__text}>Sheung Wan</li>
                    </ul>
                    <ul className={style.list}>
                        <li className={style.list__title}>
                            <img src={contactIcon} alt="contact" />
                            {t('contact')}
                        </li>
                        <li className={style.list__link}>
                            <a href="mailto:support@btcu.io">support@btcu.io</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={style.footer__bottom}>
                <p>Prof-IT Blockchain Limited</p>
                <p className={style.footer__bottom__right}>{t('allRightsReserved')}, Bitcoin Ultimatum</p>
            </div>
        </footer>
    );
};

Footer.defaultProps = {
    location: {},
};

Footer.propTypes = {
    location: PropTypes.object,
};

export default compose(withRouter)(Footer);

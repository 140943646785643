import { GET_TOP_MINERS } from '../constants';

const getTopMinersRequested = () => ({
    type: GET_TOP_MINERS.GET_TOP_MINERS_REQUEST,
});

const getTopMinersLoaded = data => ({
    type: GET_TOP_MINERS.GET_TOP_MINERS_SUCCESS,
    payload: data,
});

const getTopMinersError = error => ({
    type: GET_TOP_MINERS.GET_TOP_MINERS_FAILURE,
    payload: error,
});

const getTopMinersAction = getService => timeframe => dispatch => {
    dispatch(getTopMinersRequested());
    getService
        .getTopMiners(timeframe)
        .then(data => {
            dispatch(getTopMinersLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getTopMinersError(err));
        });
};

export default getTopMinersAction;

import notification from '../helpers/notifications';
import {
    DELETE_PRIVATE_NODE_LIST,
    TX_HASH_NOT_FOUND,
    txnPrivateNotesPath,
} from '../constants';

const deletePrivateNodeListRequested = () => ({
    type: DELETE_PRIVATE_NODE_LIST.DELETE_PRIVATE_NODE_LIST_REQUEST,
});

const deletePrivateNodeListLoaded = data => ({
    type: DELETE_PRIVATE_NODE_LIST.DELETE_PRIVATE_NODE_LIST_SUCCESS,
    payload: data,
});

const deletePrivateNodeListError = error => ({
    type: DELETE_PRIVATE_NODE_LIST.DELETE_PRIVATE_NODE_LIST_FAILURE,
    payload: error,
});

const deletePrivateNodeListAction = postService => (values, t, history) => dispatch => {
    dispatch(deletePrivateNodeListRequested());
    postService
        .deletePrivateNodeList(values)
        .then(data => {
            dispatch(deletePrivateNodeListLoaded(data));
            notification('', t('privateNoteRemoved'), 'success');
            history.push(txnPrivateNotesPath);
        })
        .catch(error => {
            dispatch(deletePrivateNodeListError(error.response.data.message));
            const {
                response: { data: { message = 'Something went wrong' } = {} } = {},
            } = error;
            let errorMessage = message;

            if (message === TX_HASH_NOT_FOUND) {
                errorMessage = t('error.txNotFound');
            }

            notification('', errorMessage, 'danger');
        });
};

export default deletePrivateNodeListAction;

import { GET_TOP_TRANSACTIONS } from '../constants';

const getTopTransactionsRequested = () => ({
    type: GET_TOP_TRANSACTIONS.GET_TOP_TRANSACTIONS_REQUEST,
});

const getTopTransactionsLoaded = data => ({
    type: GET_TOP_TRANSACTIONS.GET_TOP_TRANSACTIONS_SUCCESS,
    payload: data,
});

const getTopTransactionsError = error => ({
    type: GET_TOP_TRANSACTIONS.GET_TOP_TRANSACTIONS_FAILURE,
    payload: error,
});

const getTopTransactionsAction = getService => timeframe => dispatch => {
    dispatch(getTopTransactionsRequested());
    getService
        .getTopTransactions(timeframe)
        .then(data => {
            dispatch(getTopTransactionsLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getTopTransactionsError(err));
        });
};

export default getTopTransactionsAction;

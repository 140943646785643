const networkTitle = () => {
    const networkTicker = localStorage.getItem('networkTicker');

    if (networkTicker === 'btcu_testnet') {
        return 'btcu_testnet';
    }

    return 'btcu';
};

export default networkTitle;

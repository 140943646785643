import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import withGetService from '@hoc/with-get-service';
import formatNumber from '@helpers/format-number';
import getPrivateNodeListAction from '@actions/get-private-node-list.actions';
import getTransactionStatusAction from '@actions/get-transaction-status.actions';
import truncate from '@helpers/truncate-string';
import { compose } from '@utils';
import { addressPath, PENDING_TX_STATUS } from '@constants';
import mobileWidth from '@helpers/mobile-width';
import ArrowRight from '@assets/images/icons/arrow-right';
import btcuTicker from '@assets/images/icons/btcu-ticker-icon.svg';
import style from './transaction-page.module.scss';

class Details extends Component {
    static defaultProps = {
        t: () => {},
        getPrivateNodeList: () => {},
        getTransactionStatus: () => {},
        transactionInfo: {},
        match: {},
        loggingIn: false,
        transactionStatus: '',
    };

    static propTypes = {
        t: PropTypes.func,
        getPrivateNodeList: PropTypes.func,
        getTransactionStatus: PropTypes.func,
        transactionInfo: PropTypes.object,
        match: PropTypes.object,
        loggingIn: PropTypes.bool,
        transactionStatus: PropTypes.string,
    };

    componentDidMount() {
        const {
            getPrivateNodeList,
            getTransactionStatus,
            loggingIn,
            match: {
                params: { id },
            },
        } = this.props;
        if (loggingIn) {
            getPrivateNodeList(1000, 1);
        }
        getTransactionStatus(id);
    }

    render() {
        const {
            t,
            transactionInfo: { outputs = [], fee, output_total, is_stacking, input_total, inputs, block_id },
            transactionStatus,
        } = this.props;

        if (!inputs.length) {
            return (
                <div className={classNames(style.details, style.noData)}>
                    <div className={style.details__wrapper}>
                        <div>
                            <p className={style.details__label}>{t('inputs')}</p>
                            <div className={style.details__row}>
                                <p className={style.noData__value}>
                                    {block_id === '-1'
                                        ? t('forkedFromBitcoin')
                                        : t('noInputsNewlyGeneratedCoins')}
                                </p>
                            </div>
                        </div>
                        <ArrowRight className={style.details__arrow} />
                        <div className={style.details__rightSide}>
                            <div className={style.details__labelWrapper}>
                                <p className={style.details__label}>{t('outputs')}</p>
                            </div>
                            <div className={style.details__rowWrapper}>
                                {is_stacking ? (
                                    <div className={style.details__row}>
                                        <p className={style.details__row_staking}>{t('stakingReward')}</p>
                                    </div>
                                ) : null}
                                {outputs.map(item => {
                                    const { recipient, value, id } = item;

                                    const isPending = transactionStatus === PENDING_TX_STATUS;
                                    const link = mobileWidth() ? truncate(recipient, 15) : recipient;

                                    const linkWrapper = isPending ? (
                                        <p>{link}</p>
                                    ) : (
                                        <Link to={`${addressPath}/${recipient}`}>{link}</Link>
                                    );

                                    return (
                                        <div key={`${id}${value}`} className={style.details__row}>
                                            {linkWrapper}
                                            <div className={style.details__row_value}>
                                                <img src={btcuTicker} alt="btcuTicker" />
                                                <p>{formatNumber(value)} BTCU</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={style.details__total}>
                        <p>
                            <img src={btcuTicker} alt="btcuTicker" />
                            {t('fee')}: {formatNumber(fee)} BTCU
                        </p>
                        <p>
                            <img src={btcuTicker} alt="btcuTicker" />
                            {t('total')}: {formatNumber(output_total)} BTCU
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <div className={style.details}>
                <div className={style.details__wrapper}>
                    <DetailsLeftSide
                        t={t}
                        transactionStatus={transactionStatus}
                        input_total={input_total}
                        inputs={inputs}
                    />
                    <DetailsRightSide
                        t={t}
                        outputs={outputs}
                        transactionStatus={transactionStatus}
                        is_stacking={is_stacking}
                    />
                </div>
                <div className={style.details__total}>
                    <p>
                        <img src={btcuTicker} alt="btcuTicker" />
                        {t('fee')}: {formatNumber(fee)} BTCU
                    </p>
                    <p>
                        <img src={btcuTicker} alt="btcuTicker" />
                        {t('total')}: {formatNumber(output_total)} BTCU
                    </p>
                </div>
            </div>
        );
    }
}

const DetailsLeftSide = ({ t, transactionStatus, inputs }) => (
    <div className={style.details__leftSide}>
        <div className={style.details__labelWrapper}>
            <p className={style.details__label}>{t('inputs')}</p>
            <p className={style.details__label}>{t('totalInput')}</p>
        </div>
        {inputs.map((item, index) => {
            const { recipient, value } = item;
            const isPending = transactionStatus === PENDING_TX_STATUS;
            const link = mobileWidth() ? truncate(recipient, 15) : recipient;

            const linkWrapper = isPending ? (
                <p>{link}</p>
            ) : (
                <Link to={`${addressPath}/${recipient}`}>{link}</Link>
            );
            return (
                <div key={index} className={style.details__row}>
                    {linkWrapper}
                    <div className={style.details__row_value}>
                        <img src={btcuTicker} alt="btcuTicker" />
                        <p>{formatNumber(value)} BTCU</p>
                    </div>
                </div>
            );
        })}
    </div>
);

const DetailsRightSide = ({ t, outputs, transactionStatus, is_stacking }) => {
    if (!outputs.length) {
        return null;
    }

    return (
        <Fragment>
            <ArrowRight className={style.details__arrow} />
            <div className={style.details__rightSide}>
                <div className={style.details__labelWrapper}>
                    <p className={style.details__label}>{t('outputs')}</p>
                    <p className={style.details__label}>{t('outputTotal')}</p>
                </div>
                <div className={style.details__rowWrapper}>
                    {is_stacking ? (
                        <div className={style.details__row}>
                            <p className={style.details__row_staking}>{t('stakingReward')}</p>
                        </div>
                    ) : null}
                    {outputs.map(item => {
                        const { recipient, value, id } = item;

                        const isPending = transactionStatus === PENDING_TX_STATUS;
                        const link = mobileWidth() ? truncate(recipient, 15) : recipient;

                        const linkWrapper = isPending ? (
                            <p>{link}</p>
                        ) : (
                            <Link to={`${addressPath}/${recipient}`}>{link}</Link>
                        );

                        return (
                            <div key={`${id}${value}`} className={style.details__row}>
                                {linkWrapper}
                                <div className={style.details__row_value}>
                                    <img src={btcuTicker} alt="btcuTicker" />
                                    <p>{formatNumber(value)} BTCU</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
};

DetailsLeftSide.defaultProps = {
    t: () => {},
    transactionStatus: '',
    inputs: [],
};
DetailsRightSide.defaultProps = {
    t: () => {},
    outputs: [],
    transactionStatus: '',
    is_stacking: false,
};

DetailsLeftSide.propTypes = {
    t: PropTypes.func,
    transactionStatus: PropTypes.string,
    inputs: PropTypes.instanceOf(Array),
};
DetailsRightSide.propTypes = {
    t: PropTypes.func,
    outputs: PropTypes.instanceOf(Array),
    transactionStatus: PropTypes.string,
    is_stacking: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        transactionStatus: {
            data: { status: transactionStatus },
        },
    } = state;

    return {
        transactionStatus,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getPrivateNodeList: getPrivateNodeListAction(getService),
        getTransactionStatus: getTransactionStatusAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Details);

import React from 'react';
import PropTypes from 'prop-types';

const ClockIcon = ({ className }) => (
    <svg
        className={className}
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 7.65309C14 9.43936 13.2625 11.1525 11.9497 12.4155C10.637 13.6786 8.85652 14.3882 7 14.3882C5.14348 14.3882 3.36301 13.6786 2.05025 12.4155C0.737498 11.1525 0 9.43936 0 7.65309C0 5.86683 0.737498 4.15372 2.05025 2.89064C3.36301 1.62756 5.14348 0.917969 7 0.917969C8.85652 0.917969 10.637 1.62756 11.9497 2.89064C13.2625 4.15372 14 5.86683 14 7.65309ZM7 3.86458C7 3.75294 6.95391 3.64587 6.87186 3.56693C6.78981 3.48799 6.67853 3.44364 6.5625 3.44364C6.44647 3.44364 6.33519 3.48799 6.25314 3.56693C6.17109 3.64587 6.125 3.75294 6.125 3.86458V8.49498C6.12502 8.56918 6.14543 8.64205 6.18415 8.70622C6.22288 8.77038 6.27854 8.82356 6.3455 8.86036L9.408 10.5441C9.5085 10.5964 9.62626 10.6088 9.73611 10.5786C9.84596 10.5484 9.93919 10.4781 9.99587 10.3827C10.0525 10.2872 10.0682 10.1743 10.0394 10.0679C10.0107 9.9615 9.93982 9.87016 9.842 9.81338L7 8.25083V3.86458Z"
            fill="#1F1F3B"
        />
    </svg>
);

ClockIcon.defaultProps = {
    className: '',
};

ClockIcon.propTypes = {
    className: PropTypes.string,
};

export default ClockIcon;

import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ className }) => (
    <svg
        className={className}
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 4C10.6131 4 8.32387 4.94821 6.63604 6.63604C4.94821 8.32387 4 10.6131 4 13C4 14.1819 4.23279 15.3522 4.68508 16.4442C5.13738 17.5361 5.80031 18.5282 6.63604 19.364C7.47177 20.1997 8.46392 20.8626 9.55585 21.3149C10.6478 21.7672 11.8181 22 13 22C14.1819 22 15.3522 21.7672 16.4442 21.3149C17.5361 20.8626 18.5282 20.1997 19.364 19.364C20.1997 18.5282 20.8626 17.5361 21.3149 16.4442C21.7672 15.3522 22 14.1819 22 13C22 10.6131 21.0518 8.32387 19.364 6.63604C17.6761 4.94821 15.3869 4 13 4ZM5.22183 5.22183C7.28473 3.15893 10.0826 2 13 2C15.9174 2 18.7153 3.15893 20.7782 5.22183C22.8411 7.28473 24 10.0826 24 13C24 14.4445 23.7155 15.8749 23.1627 17.2095C22.6099 18.5441 21.7996 19.7567 20.7782 20.7782C19.7567 21.7996 18.5441 22.6099 17.2095 23.1627C15.8749 23.7155 14.4445 24 13 24C11.5555 24 10.1251 23.7155 8.79048 23.1627C7.4559 22.6099 6.24327 21.7996 5.22183 20.7782C4.20038 19.7567 3.39013 18.5441 2.83733 17.2095C2.28452 15.8749 2 14.4445 2 13C2 10.0826 3.15893 7.28473 5.22183 5.22183ZM12 8.55556C12 8.00327 12.4477 7.55556 13 7.55556H13.0111C13.5634 7.55556 14.0111 8.00327 14.0111 8.55556C14.0111 9.10784 13.5634 9.55556 13.0111 9.55556H13C12.4477 9.55556 12 9.10784 12 8.55556ZM10.8889 13C10.8889 12.4477 11.3366 12 11.8889 12H13C13.5523 12 14 12.4477 14 13V16.4444H14.1111C14.6634 16.4444 15.1111 16.8922 15.1111 17.4444C15.1111 17.9967 14.6634 18.4444 14.1111 18.4444H13C12.4477 18.4444 12 17.9967 12 17.4444V14H11.8889C11.3366 14 10.8889 13.5523 10.8889 13Z"
            fill="#888996"
        />
    </svg>
);

InfoIcon.defaultProps = {
    className: '',
};

InfoIcon.propTypes = {
    className: PropTypes.string,
};

export default InfoIcon;

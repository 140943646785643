import React from 'react';
import PropTypes from 'prop-types';

const NetworkTicker = ({ className }) => (
    <svg
        className={className}
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 5C0 2.23858 2.23858 0 5 0H18C20.7614 0 23 2.23858 23 5V18C23 20.7614 20.7614 23 18 23H5C2.23858 23 0 20.7614 0 18V5Z"
            fill="#BDBDC7"
            fillOpacity="0.1"
        />
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="7"
            y="15"
            width="10"
            height="3"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19233 15.6177C7.08607 15.6177 7 15.7019 7 15.8058V17.2698C7 17.3737 7.08607 17.4579 7.19233 17.4579H15.8349C15.9412 17.4579 16.0273 17.3737 16.0273 17.2698V15.8058C16.0273 15.7019 15.9412 15.6177 15.8349 15.6177H7.19233Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19233 15.6177C7.08607 15.6177 7 15.7019 7 15.8058V17.2698C7 17.3737 7.08607 17.4579 7.19233 17.4579H15.8349C15.9412 17.4579 16.0273 17.3737 16.0273 17.2698V15.8058C16.0273 15.7019 15.9412 15.6177 15.8349 15.6177H7.19233Z"
                fill="white"
            />
        </g>
        <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="7"
            y="5"
            width="9"
            height="9"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1024 5C13.6753 5 13.3291 5.33678 13.3291 5.75222V9.14123C13.3291 10.3899 12.5791 10.9932 11.4974 10.9932C10.4445 10.9932 9.66558 10.3899 9.66558 9.14123V5.75222C9.66558 5.33678 9.31937 5 8.8923 5H7.84262C7.41555 5 7.06934 5.33678 7.06934 5.75222V9.01489C7.06934 10.3759 7.48763 11.4422 8.32419 12.1998C9.16071 12.9434 10.2137 13.3223 11.4974 13.3223C12.7811 13.3223 13.8339 12.9434 14.6705 12.1998C15.5027 11.4461 15.921 10.3869 15.9253 9.03588V5.74543C15.9216 5.33313 15.5768 5 15.152 5H14.1024Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask1)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1024 5C13.6753 5 13.3291 5.33678 13.3291 5.75222V9.14123C13.3291 10.3899 12.5791 10.9932 11.4974 10.9932C10.4445 10.9932 9.66558 10.3899 9.66558 9.14123V5.75222C9.66558 5.33678 9.31937 5 8.8923 5H7.84262C7.41555 5 7.06934 5.33678 7.06934 5.75222V9.01489C7.06934 10.3759 7.48763 11.4422 8.32419 12.1998C9.16071 12.9434 10.2137 13.3223 11.4974 13.3223C12.7811 13.3223 13.8339 12.9434 14.6705 12.1998C15.5027 11.4461 15.921 10.3869 15.9253 9.03588V5.74543C15.9216 5.33313 15.5768 5 15.152 5H14.1024Z"
                fill="white"
            />
        </g>
    </svg>
);

NetworkTicker.defaultProps = {
    className: '',
};

NetworkTicker.propTypes = {
    className: PropTypes.string,
};

export default NetworkTicker;

/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import classNames from 'classnames';
import mobileWidth from '@helpers/mobile-width';
import truncate from '@helpers/truncate-string';
import { addressPath, transactionPath } from '@constants';
import formatNumber from '@helpers/format-number';
import { compose } from '@utils';
import noDataIcon from '@assets/images/icons/no-data-icon.svg';
import incomingIcon from '@assets/images/icons/incoming-tx.svg';
import outgoinIcon from '@assets/images/icons/outgoin-tx.svg';
import ArrowRight from '@assets/images/icons/arrow-right';
import plusIcon from '@assets/images/icons/plus-icon.svg';
import minusIcon from '@assets/images/icons/minus-icon.svg';
import removeIcon from '@assets/images/icons/remove-icon-red.svg';
import style from './notifications.module.scss';

const NotificationsView = ({
    removeNotifiction,
    txNotifier: { txNotifications: { notifications = [] } = {} },
}) => {
    const { t } = useTranslation();
    const [details, setDetails] = useState('');

    const switchActiveTab = id => {
        setDetails(id);
    };

    const Header = () => (
        <div className={style.header}>
            <p className={style.header__title}>{t('myNotifications')}</p>
        </div>
    );

    if (!notifications.length) {
        return (
            <Fragment>
                <Header />
                <div className={style.noData}>
                    <img className={style.noData__icon} src={noDataIcon} alt="noData" />
                    <p className={style.noData__title}>{t('youHaveNoNotifications')}</p>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Header />
            <p className={style.totalFound}>{t('totalNotifications', { digit: notifications.length })}</p>
            <div className={style.wrapper}>
                {notifications.map(item => {
                    const {
                        recipient,
                        sender,
                        timestamp,
                        totalOutput,
                        transactionType,
                        txHash,
                        id,
                        is_stacking,
                    } = item;

                    const isIncoming = transactionType === 'incoming';

                    const isOpen = details === id;
                    const detailsStyle = isOpen
                        ? classNames(style.details, style.detailsOpened)
                        : style.details;

                    const openInfo = (
                        <div className={style.dotWrapper} onClick={() => switchActiveTab(isOpen ? '' : id)}>
                            <div className={style.dotWrapper__dot}>
                                <img src={isOpen ? minusIcon : plusIcon} alt="icon" />
                                <span>{isOpen ? t('hide') : t('viewMore')}</span>
                            </div>
                        </div>
                    );

                    return (
                        <Fragment key={id}>
                            <div className={style.notifications}>
                                <div className={style.notifications__status}>
                                    <div className={style.notifications__statusWr}>
                                        <img
                                            className={style.notifications__status_img}
                                            src={isIncoming ? outgoinIcon : incomingIcon}
                                            alt="icon"
                                        />
                                        <p className={style.notifications__status_text}>
                                            {isIncoming ? t('incomingTransaction') : t('outgoingTransaction')}
                                            :
                                        </p>
                                    </div>
                                    <Link
                                        className={style.notifications__status_link}
                                        to={`${transactionPath}/${txHash}`}
                                    >
                                        {mobileWidth() ? truncate(txHash, 32) : txHash}
                                    </Link>
                                </div>
                                <div className={style.notifications__content}>
                                    <div className={style.notifications__leftSide}>
                                        <p className={style.notifications__leftSide_text}>
                                            {t('transactionDate')}:
                                            <span>
                                                <Moment format="DD.MM.YYYY, hh:mm A" unix>
                                                    {timestamp / 1000}
                                                </Moment>
                                            </span>
                                        </p>
                                    </div>
                                    <div className={style.notifications__rightSide}>
                                        <p className={style.notifications__rightSide_text}>
                                            {t('totalOutput')}:<span>{formatNumber(totalOutput)} BTCU</span>
                                        </p>
                                    </div>
                                </div>
                                <div className={style.removeWr}>
                                    {openInfo}
                                    <div className={style.dotWrapper} onClick={() => removeNotifiction(id)}>
                                        <div className={style.dotWrapper__dot}>
                                            <img src={removeIcon} alt="icon" />
                                            <span>{t('remove')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={detailsStyle}>
                                <div className={style.details__wrapper}>
                                    <LeftSideDetails t={t} sender={sender} />
                                    <RightSideDetails
                                        t={t}
                                        recipient={recipient}
                                        sender={sender}
                                        is_stacking={is_stacking}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </Fragment>
    );
};

const LeftSideDetails = ({ t, sender }) => (
    <div style={{ width: '100%' }}>
        <p className={style.details__label}>{t('inputs')}</p>

        {sender.map(items => (
            <div key={items} className={style.details__row}>
                <Tooltip placement="topLeft" title={items}>
                    <Link to={`${addressPath}/${items}`}>{truncate(items, 32)}</Link>
                </Tooltip>
            </div>
        ))}
    </div>
);

const RightSideDetails = ({ t, recipient, sender, is_stacking }) => {
    if (!sender.length) {
        return null;
    }

    return (
        <Fragment>
            <ArrowRight className={style.details__arrow} />
            <div className={style.details__rightSide}>
                <div className={style.details__labelWrapper}>
                    <p className={style.details__label}>{t('outputs')}</p>
                </div>
                <div className={style.details__rowWrapper}>
                    {is_stacking ? (
                        <div className={style.details__row}>
                            <p className={style.details__row_staking}>{t('stakingReward')}</p>
                        </div>
                    ) : null}
                    {recipient.map((items, index) => (
                        <div key={index} className={style.details__row}>
                            <Tooltip placement="topLeft" title={items}>
                                <Link to={`${addressPath}/${items}`}>{truncate(items, 32)}</Link>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

NotificationsView.defaultProps = {
    txNotifier: {},
    removeNotifiction: () => {},
};
LeftSideDetails.defaultProps = {
    t: () => {},
    sender: [],
};
RightSideDetails.defaultProps = {
    t: () => {},
    recipient: [],
    sender: [],
    is_stacking: false,
};

NotificationsView.propTypes = {
    txNotifier: PropTypes.object,
    removeNotifiction: PropTypes.func,
};
LeftSideDetails.propTypes = {
    t: PropTypes.func,
    sender: PropTypes.instanceOf(Array),
};
RightSideDetails.propTypes = {
    t: PropTypes.func,
    recipient: PropTypes.instanceOf(Array),
    sender: PropTypes.instanceOf(Array),
    is_stacking: PropTypes.bool,
};

export default compose(connect(), withRouter)(NotificationsView);

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import truncate from '@helpers/truncate-string';
import ArrowRightIcon from '@assets/images/icons/arrow-right';
import withGetService from '@hoc/with-get-service';
import { compose } from '@utils';
import mobileWidth from '@helpers/mobile-width';
import { blockPath, transactionPath, allTransactionsPath, allBlocksPath } from '@constants';
import toFixedBigValue from '@helpers/big-number';
import getLatestTransactionsAction from '@actions/get-latest-transactions.actions';
import getLatestBlocksAction from '@actions/get-latest-blocks.actions';
import logo from '@assets/images/icons/btcu-blue-icon.svg';
import btcuTicker from '@assets/images/icons/btcu-ticker-icon.svg';
import Spinner from '@spinner';
import style from './latest-screen.module.scss';

class LatestScreen extends Component {
    static defaultProps = {
        t: () => {},
        getLatestTransactions: () => {},
        getLatestBlocks: () => {},
        latestBlocks: [],
        latestTransactions: [],
    };

    static propTypes = {
        t: PropTypes.func,
        getLatestTransactions: PropTypes.func,
        getLatestBlocks: PropTypes.func,
        latestBlocks: PropTypes.instanceOf(Array),
        latestTransactions: PropTypes.instanceOf(Array),
    };

    componentDidMount() {
        const { getLatestTransactions, getLatestBlocks } = this.props;
        getLatestTransactions();
        getLatestBlocks();
    }

    render() {
        const { t, latestBlocks, latestTransactions } = this.props;

        const toLocalString = value => {
            const toFixed = toFixedBigValue(value?.toString(), 4);
            return (+toFixed).toLocaleString();
        };

        return (
            <div className={style.latest}>
                <div className={style.latest__column}>
                    <div className={style.latest__header}>
                        <h3 className={style.latest__column_title}>{t('latestsBlocks')}</h3>
                        <Link to={allBlocksPath} className={style.latest__header_button}>
                            <ArrowRightIcon className={style.latest__header_buttonIcon} />
                            {t('viewAll')}
                        </Link>
                    </div>
                    {!latestBlocks.length ? (
                        <div className={style.spinner}>
                            <Spinner />
                        </div>
                    ) : null}
                    {latestBlocks.map(item => {
                        const { height, time, hash, block_reward } = item;

                        const getTime = new Date(+time);
                        const timeFromNow = moment(getTime).fromNow();

                        return (
                            <div key={height} className={style.card}>
                                <img className={style.card__logo} src={logo} alt="logo" />
                                <div className={classNames(style.card__item, style.card__item_info)}>
                                    <Link
                                        to={`${blockPath}/${height}`}
                                        className={classNames(style.card__item_red, style.card__item_tx)}
                                    >
                                        {height}
                                    </Link>
                                    <p
                                        className={classNames(
                                            style.card__item_gray,
                                            style.card__item_blockTime,
                                        )}
                                    >
                                        <Tooltip placement="top" title={timeFromNow}>
                                            {timeFromNow}
                                        </Tooltip>
                                    </p>
                                </div>
                                <div className={classNames(style.card__item, style.card__item_addresses)}>
                                    <div className={style.card__item_blockRow}>
                                        <p className={style.card__item_label}>{t('hash')}</p>
                                        <Link
                                            to={`${blockPath}/${height}`}
                                            className={classNames(
                                                style.card__item_red,
                                                style.card__item_address,
                                            )}
                                        >
                                            <Tooltip placement="top" title={hash}>
                                                {`${truncate(hash, mobileWidth() ? 31 : 28)}`}
                                            </Tooltip>
                                        </Link>
                                    </div>
                                </div>
                                <div className={style.card__info}>
                                    <p className={style.latestTxn__info_label}>{t('blockReward')}</p>
                                    <Tooltip placement="top" title={`${block_reward} BTCU`}>
                                        <div className={style.latestTxn__info_reward}>
                                            <img src={btcuTicker} alt="ticker" />
                                            <span className={style.latestTxn__info_value}>
                                                {toLocalString(block_reward)}
                                            </span>
                                            BTCU
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={style.latest__column}>
                    <div className={style.latest__header}>
                        <h3 className={style.latest__column_title}>{t('latestsTransactions')}</h3>
                        <Link to={allTransactionsPath} className={style.latest__header_button}>
                            <ArrowRightIcon className={style.latest__header_buttonIcon} />
                            {t('viewAll')}
                        </Link>
                    </div>
                    {!latestTransactions.length ? (
                        <div className={style.spinner}>
                            <Spinner />
                        </div>
                    ) : null}
                    {latestTransactions.map(item => {
                        const { hash, time, output_total, input_total } = item;

                        const getTime = new Date(+time);
                        const timeFromNow = moment(getTime).fromNow();

                        return (
                            <div key={hash} className={style.latestTxn}>
                                <img className={style.latestTxn__logo} src={logo} alt="logo" />
                                <div className={style.latestTxn__wrapper}>
                                    <Link to={`${transactionPath}/${hash}`} className={style.latestTxn__hash}>
                                        <Tooltip placement="top" title={hash}>
                                            {`${truncate(hash, mobileWidth() ? 30 : 24)}`}
                                        </Tooltip>
                                    </Link>
                                    <p className={style.latestTxn__time}>{timeFromNow}</p>
                                </div>
                                <div className={style.latestTxn__info}>
                                    <p className={style.latestTxn__info_label}>{t('totalInput')}</p>
                                    <Tooltip placement="top" title={`${input_total} BTCU`}>
                                        <div className={style.latestTxn__info_reward}>
                                            <img src={btcuTicker} alt="ticker" />
                                            <span className={style.latestTxn__info_value}>
                                                {toLocalString(input_total)}
                                            </span>
                                            BTCU
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className={style.latestTxn__info}>
                                    <p className={style.latestTxn__info_label}>{t('outputTotal')}</p>
                                    <Tooltip placement="top" title={`${output_total} BTCU`}>
                                        <div className={style.latestTxn__info_reward}>
                                            <img src={btcuTicker} alt="ticker" />
                                            <span className={style.latestTxn__info_value}>
                                                {toLocalString(output_total)}
                                            </span>
                                            BTCU
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        latestBlocks: { data: latestBlocks },
        latestTransactions: { data: latestTransactions },
    } = state;

    return {
        latestBlocks,
        latestTransactions,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getLatestTransactions: getLatestTransactionsAction(getService),
        getLatestBlocks: getLatestBlocksAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(LatestScreen);

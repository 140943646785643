const getCurrentLocale = () => {
    const currentLang = localStorage.getItem('i18nextLngBTCU');
    let locale = currentLang;

    if (currentLang === 'cn') {
        locale = 'zh-cn';
    } else {
        locale = currentLang.toLowerCase();
    }

    return locale;
};

export default getCurrentLocale;

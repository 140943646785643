const ERROR_LOGIN_PASSWORD_INVALID = 'ERROR_LOGIN_PASSWORD_INVALID';
const ERROR_USER_NOT_VERIFICATION = 'ERROR_USER_NOT_VERIFICATION';
const ERROR_EMAIL_TAKEN = 'ERROR_EMAIL_TAKEN';
const ERROR_LOGIN_TAKEN = 'ERROR_LOGIN_TAKEN';
const ERROR_NO_USER = 'ERROR_NO_USER';
const ERROR_TOKEN_INVALID = 'ERROR_TOKEN_INVALID';
const ERROR_OLD_PASSWORD_INVALID = 'ERROR_OLD_PASSWORD_INVALID';
const INVALID_PASSWORD_RECOVERY_LINK = 'INVALID_PASSWORD_RECOVERY_LINK';
const WATCHLIST_ADDRESS_ALREADY_EXIST = 'WATCHLIST_ADDRESS_ALREADY_EXIST';
const WATCHLIST_ADDRESS_NOT_FOUND = 'WATCHLIST_ADDRESS_NOT_FOUND';
const TX_HASH_NOT_FOUND = 'TX_HASH_NOT_FOUND';
const TX_HASH_ALREADY_EXIST = 'TX_HASH_ALREADY_EXIST';
const ERROR_WRONG_ADDRESS = 'ERROR_WRONG_ADDRESS';
const SEND_LIMIT_EXCEEDED = 'SEND_LIMIT_EXCEEDED';

export {
    ERROR_LOGIN_PASSWORD_INVALID,
    ERROR_USER_NOT_VERIFICATION,
    ERROR_EMAIL_TAKEN,
    ERROR_LOGIN_TAKEN,
    ERROR_NO_USER,
    ERROR_TOKEN_INVALID,
    ERROR_OLD_PASSWORD_INVALID,
    INVALID_PASSWORD_RECOVERY_LINK,
    WATCHLIST_ADDRESS_ALREADY_EXIST,
    WATCHLIST_ADDRESS_NOT_FOUND,
    TX_HASH_NOT_FOUND,
    TX_HASH_ALREADY_EXIST,
    ERROR_WRONG_ADDRESS,
    SEND_LIMIT_EXCEEDED,
};

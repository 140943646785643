import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import withTitle from '@hoc/with-title';
import notFoundIcon from '@assets/images/icons/search-not-found.svg';
import LeasingPoolsView from './leasing-pools-view';
import { compose } from '@utils';
import Spinner from '@spinner';
import style from './leasing-pools.module.scss';

class LeasingPools extends Component {
    static defaultProps = {
        t: () => {},
        loading: false,
    };

    static propTypes = {
        t: PropTypes.func,
        loading: PropTypes.bool,
    };

    state = {};

    render() {
        const { t, loading } = this.props;
        const data = [
            {
                rank: '1',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '2',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'Validator',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '3',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '4',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '5',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '6',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'Validator',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '7',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '8',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '9',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
            {
                rank: '10',
                block: '10923003',
                name: 'BTCU World Node',
                address: '0x0030a981d7afbd2408465fdd30x0030a981d7',
                type: 'MasterNode',
                inLeasing: '200 BTCU',
                users: '215',
                activity: '09.10.2015',
                rating: '9.4',
            },
        ];

        if (loading) {
            return (
                <div className={style.container}>
                    <Spinner />
                </div>
            );
        }

        if (!data.length) {
            return (
                <div className={style.notFoundIcon}>
                    <img src={notFoundIcon} alt="notFoundIcon" />
                    <p className={style.notFoundIcon__title}>{t('nothingFound')}</p>
                </div>
            );
        }

        return (
            <div className={style.container}>
                <LeasingPoolsView data={data} />
            </div>
        );
    }
}

export default compose(withTranslation(), withTitle({ title: '| Leasing pools' }), withRouter)(LeasingPools);

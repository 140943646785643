import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import networkTitle from '@helpers/network';
import getPrivateNodeListAction from '@actions/get-private-node-list.actions';
import deletePrivateNodeListAction from '@actions/delete-private-node-list.actions';
import putPrivateNodeListAction from '@actions/put-private-node-list.actions';
import withTitle from '@hoc/with-title';
import Field from '@UI/field/field';
import withPostService from '@hoc/with-post-service';
import withGetService from '@hoc/with-get-service';
import { compose } from '@utils';
import InfoIcon from '@assets/images/icons/info_icon';
import removeIcon from '@assets/images/icons/remove-icon.svg';
import Button from '@UI/button/button';
import Spinner from '@spinner';
import { txnPrivateNotesPath } from '@constants';
import style from './private-notes.module.scss';

export class PrivateNotesEdit extends Component {
    static defaultProps = {
        t: () => {},
        getPrivateNodeList: () => {},
        putPrivateNodeList: () => {},
        deletePrivateNodeList: () => {},
        history: {},
        match: {},
        privateNodeList: {},
        location: {},
        successPrivateNodeList: false,
    };

    static propTypes = {
        t: PropTypes.func,
        getPrivateNodeList: PropTypes.func,
        putPrivateNodeList: PropTypes.func,
        deletePrivateNodeList: PropTypes.func,
        history: PropTypes.object,
        match: PropTypes.object,
        privateNodeList: PropTypes.object,
        location: PropTypes.object,
        successPrivateNodeList: PropTypes.bool,
    };

    state = {
        txHash: '',
        description: '',
        descriptionError: '',
        descripMinLengthError: '',
        loading: true,
    };

    componentDidMount() {
        const { getPrivateNodeList } = this.props;
        getPrivateNodeList(100, 1);
    }

    componentDidUpdate(prevProps) {
        const {
            successPrivateNodeList,
            privateNodeList,
            match: {
                params: { id },
            },
            history,
        } = this.props;

        if (successPrivateNodeList && successPrivateNodeList !== prevProps.successPrivateNodeList) {
            try {
                const findList = privateNodeList.items.find(item => item.txHash === id);
                const { description, txHash } = findList;

                this.setState({
                    txHash,
                    description,
                    loading: false,
                });
            } catch (e) {
                history.push(txnPrivateNotesPath);
            }
        }
    }

    inputOnChange = event => {
        const { name, value } = event.target;

        if (name === 'description') {
            this.validateDescription(name, value);
        }
    };

    validateDescription = (name, value) => {
        const { t } = this.props;
        const { description } = this.state;
        const minLength = 1;
        const maxLength = 75;

        const minLengthError = value.length < minLength ? t('error.min_length', { digit: minLength }) : '';

        const maxLengthError = value.length > maxLength ? t('error.max_length', { digit: maxLength }) : '';

        this.setState({
            [name]: maxLengthError ? description : value,
            descriptionError: minLengthError || maxLengthError,
            descripMinLengthError: minLengthError,
        });
    };

    back = () => {
        const { history } = this.props;
        history.goBack();
    };

    save = async () => {
        const {
            t,
            putPrivateNodeList,
            history,
            location: { search },
        } = this.props;
        const parse = search.split('=');

        const { txHash, description, descripMinLengthError } = this.state;
        const isError = !description || descripMinLengthError;

        const data = {
            txHash,
            description,
            currency: networkTitle(),
        };
        if (!isError) {
            putPrivateNodeList(data, t, history, parse[1]);
        }
    };

    remove = () => {
        const { t, deletePrivateNodeList, history } = this.props;

        const { txHash } = this.state;

        const data = {
            txHash,
            currency: networkTitle(),
        };

        deletePrivateNodeList(data, t, history);
    };

    render() {
        const {
            t,
            match: {
                params: { id },
            },
        } = this.props;
        const { description, descriptionError, loading, descripMinLengthError } = this.state;

        if (loading) {
            return (
                <div className={style.container}>
                    <Spinner />
                </div>
            );
        }

        const disabledSaveBtn = !description || descripMinLengthError;

        return (
            <div className={style.container}>
                <div className={style.header}>
                    <p className={style.header__title}>{t('editPrivateNote')}</p>
                </div>
                <div className={style.edit}>
                    <div className={style.edit__row}>
                        <p className={style.edit__row_label}>{t('txnHash')}</p>
                        <p className={style.edit__row_value}>{id}</p>
                    </div>
                    <div className={style.edit__row}>
                        <p className={style.edit__row_labelSecond}>{t('updatePrivateNote')}</p>
                        <div className={style.edit__inputContainer}>
                            <Field
                                id="description"
                                type="text"
                                name="description"
                                value={description}
                                onChange={this.inputOnChange}
                                inputStyle={style.edit__input}
                                labelStyle={style.edit__label}
                                labelStyleActive={style.edit__labelActive}
                            />
                            {descriptionError ? (
                                <div className={style.edit__error}>
                                    <InfoIcon className={style.edit__error_icon} />
                                    <p className={style.edit__error_text}>{descriptionError}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className={style.edit__buttonWrapper}>
                        <div className={style.edit__buttonWrapper_leftSide}>
                            <Button
                                type="button"
                                className={style.edit__buttonWrapper_back}
                                onClick={this.back}
                            >
                                {t('back')}
                            </Button>
                            <Button
                                type="submit"
                                className={style.edit__buttonWrapper_save}
                                onClick={this.save}
                                disabled={!!disabledSaveBtn}
                            >
                                {t('save')}
                            </Button>
                        </div>
                        <Button
                            type="submit"
                            className={style.edit__buttonWrapper_remove}
                            onClick={this.remove}
                        >
                            <img src={removeIcon} alt="removeIcon" />
                            {t('remove')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        updatePrivateNodeList: { loading },
        privateNodeList: {
            data: { privateNodeListArr: privateNodeList },
            success: successPrivateNodeList,
        },
    } = state;

    return {
        loading,
        privateNodeList,
        successPrivateNodeList,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        getPrivateNodeList: getPrivateNodeListAction(getService),
        putPrivateNodeList: putPrivateNodeListAction(postService),
        deletePrivateNodeList: deletePrivateNodeListAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    withGetService(),
    withTitle({ title: '| Edit Private Notes' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(PrivateNotesEdit);

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import formatNumber from '@helpers/format-number';
import { compose } from '@utils';
import ChangePassword from './change-password';
import ProfileSettings from './profile-settings';
import style from './profile.module.scss';

const ProfileView = ({
    user: { login, email, jwtCreatedAt },
    watchList: { grandTotal, grandTotalUSD, totalCount, totalTags },
    privateNodeList: { totalCount: totalprivateNode },
    onTabClick,
}) => {
    const { t } = useTranslation();
    const { TabPane } = Tabs;

    const overviewData = [
        {
            title: t('yourUsername'),
            value: login,
        },
        {
            title: t('yourEmailAddress'),
            value: email,
        },
        {
            title: t('addressWatchList'),
            value: t('outOfAvailableLimit', { count: totalCount, max: 1000 }),
        },
        {
            title: t('transactionNotes'),
            value: t('outOfAvailableLimit', { count: totalprivateNode, max: 1000 }),
        },
        {
            title: t('addressTags'),
            value: t('outOfAvailableLimit', { count: totalTags, max: 1000 }),
        },
        {
            title: t('emailNotificationLimit'),
            value: '0 emails sent out 200 daily limit available',
        },
        {
            title: t('totalBTCBalance'),
            value: `${formatNumber(grandTotal)} BTCU @ ($${grandTotalUSD})`,
        },
        {
            title: t('lastLogin'),
            value: moment.unix(jwtCreatedAt / 1000).format('ddd, Do MMM YYYY, h:mm:ss a'),
        },
    ];

    return (
        <div id="profile">
            <Tabs defaultActiveKey="profileSettings" onTabClick={onTabClick}>
                <TabPane tab={t('profileSettings')} key="1">
                    <ProfileSettings />
                </TabPane>
                <TabPane tab={t('changePassword')} key="changePassword">
                    <ChangePassword />
                </TabPane>
                <TabPane tab={t('overview')} key="overview">
                    <div className={style.overview}>
                        {overviewData.map(item => {
                            const { title, value } = item;

                            return (
                                <div key={title} className={style.overview__item}>
                                    <p className={style.overview__item_label}>{title}</p>
                                    <p className={style.overview__item_value}>{value}</p>
                                </div>
                            );
                        })}
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
};

const mapStateToProps = state => {
    const {
        authentication: { user },
        getWatchList: {
            data: { watchListArr: watchList },
        },
    } = state;

    return {
        user,
        watchList,
    };
};

ProfileView.defaultProps = {
    onTabClick: () => {},
    user: {},
    watchList: {},
    privateNodeList: {},
};

ProfileView.propTypes = {
    onTabClick: PropTypes.func,
    user: PropTypes.object,
    watchList: PropTypes.object,
    privateNodeList: PropTypes.object,
};

export default compose(connect(mapStateToProps))(ProfileView);

import React from 'react';
import PropTypes from 'prop-types';

const AddPhotoIcon = ({ className }) => (
    <svg
        className={className}
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M83.5 0H76.5C75.7 0 75 0.7 75 1.5C75 2.3 75.7 3 76.5 3H83.5C91 3 97 9 97 16.5V23.5C97 24.3 97.7 25 98.5 25C99.3 25 100 24.3 100 23.5V16.5C100 7.4 92.6 0 83.5 0ZM0 16.5V23.5C0 24.3 0.7 25 1.5 25C2.3 25 3 24.3 3 23.5V16.5C3 9 9 3 16.5 3H23.5C24.3 3 25 2.3 25 1.5C25 0.7 24.3 0 23.5 0H16.5C7.4 0 0 7.4 0 16.5ZM3 83.5V76.5C3 75.7 2.3 75 1.5 75C0.7 75 0 75.7 0 76.5V83.5C0 92.6 7.4 100 16.5 100H23.5C24.3 100 25 99.3 25 98.5C25 97.7 24.3 97 23.5 97H16.5C9 97 3 91 3 83.5ZM97 83.5C97 91 91 97 83.5 97H76.5C75.7 97 75 97.7 75 98.5C75 99.3 75.7 100 76.5 100H83.5C92.6 100 100 92.6 100 83.5V76.5C100 75.7 99.3 75 98.5 75C97.7 75 97 75.7 97 76.5V83.5Z"
            fill="#888996"
        />
        <path
            d="M71.3001 68.5H67.8001V65C67.8001 64.2 67.1001 63.5 66.3001 63.5C65.5001 63.5 64.8001 64.2 64.8001 65V68.5H61.3C60.5 68.5 59.8 69.2 59.8 70C59.8 70.8 60.5 71.5 61.3 71.5H64.8001V75C64.8001 75.8 65.5001 76.5 66.3001 76.5C67.1001 76.5 67.8001 75.8 67.8001 75V71.5H71.3001C72.1001 71.5 72.8001 70.8 72.8001 70C72.8001 69.2 72.1001 68.5 71.3001 68.5Z"
            fill="#888996"
        />
        <path
            d="M49.6 48.8C53.2 48.8 56.3 47.5 58.8 45.1C61.3 42.6 62.6 39.6 62.6 36.1C62.6 32.6 61.3 29.6 58.8 27.1C56.3 24.6 53.2 23.4 49.6 23.4C46 23.4 42.9 24.7 40.4 27.1C37.9 29.6 36.6 32.6 36.6 36.1C36.6 39.6 37.9 42.6 40.4 45.1C42.9 47.5 46 48.8 49.6 48.8ZM42.3 28.9C44.3 26.9 46.7 25.9 49.6 25.9C52.5 25.9 54.8 26.9 56.9 28.9C58.9 30.9 59.9 33.2 59.9 36C59.9 38.8 58.9 41.1 56.9 43.1C54.9 45.1 52.5 46.1 49.6 46.1C46.7 46.1 44.4 45.1 42.3 43.1C40.3 41.1 39.3 38.8 39.3 36C39.3 33.2 40.3 30.9 42.3 28.9ZM71.3 57.8C70.9 56.7 70.5 55.7 69.9 54.6C69.3 53.6 68.6 52.6 67.8 51.9C67 51.1 65.9 50.5 64.8 50C63.6 49.5 62.3 49.3 60.9 49.3C60.4 49.3 59.9 49.5 58.9 50.2C58.3 50.6 57.5 51.1 56.6 51.6C55.8 52.1 54.7 52.6 53.3 53C51.2 53.6 48.8 53.6 46.7 53C45.3 52.6 44.2 52.1 43.4 51.6C42.6 51.1 41.8 50.6 41.1 50.2C40.1 49.6 39.6 49.3 39.1 49.3C37.7 49.3 36.4 49.5 35.2 50C34 50.5 33 51.1 32.2 51.9C31.4 52.7 30.7 53.6 30.1 54.6C29.5 55.6 29 56.7 28.7 57.8C28.4 58.8 28.1 60 27.9 61.2C27.7 62.4 27.5 63.5 27.5 64.6C27.4 65.6 27.4 66.7 27.4 67.8C27.4 70.5 28.3 72.8 30 74.4C31.7 76 34 76.8 36.8 76.8H56.6C57.3 76.8 57.9 76.2 57.9 75.5C57.9 74.8 57.3 74.2 56.6 74.2H36.7C34.6 74.2 33 73.6 31.8 72.5C30.6 71.4 30 69.8 30 67.8C30 66.8 30 65.8 30.1 64.8C30.2 63.9 30.3 62.8 30.5 61.7C30.7 60.6 31 59.6 31.3 58.6C31.6 57.7 32 56.8 32.5 56C33 55.2 33.5 54.5 34.1 53.9C34.7 53.4 35.4 52.9 36.3 52.6C37.1 52.3 38 52.1 38.9 52.1H39H39.1C39.2 52.2 39.4 52.3 39.8 52.5C40.5 52.9 41.2 53.4 42.1 53.9C43.1 54.5 44.5 55.1 46.2 55.6C48.8 56.4 51.6 56.4 54.2 55.6C55.9 55.1 57.2 54.5 58.3 53.9C59.2 53.4 59.9 52.9 60.6 52.5C60.9 52.3 61.1 52.2 61.3 52.1H61.4H61.5C62.5 52.1 63.3 52.3 64.1 52.6C65 52.9 65.7 53.4 66.3 53.9C66.9 54.5 67.5 55.2 67.9 56C68.4 56.9 68.8 57.7 69.1 58.6C69.3 59.2 69.5 59.8 69.6 60.5C69.7 61.1 70.3 61.5 70.9 61.5H71C71.9 61.5 72.5 60.7 72.3 59.9C71.7 59.1 71.5 58.4 71.3 57.8Z"
            fill="#888996"
        />
    </svg>
);

AddPhotoIcon.defaultProps = {
    className: '',
};

AddPhotoIcon.propTypes = {
    className: PropTypes.string,
};

export default AddPhotoIcon;

import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import notification from '@helpers/notifications';
import copyToClipboard from '@helpers/copy-to-clipboard';
import truncate from '@helpers/truncate-string';
import formatNumber from '@helpers/format-number';
import getTransactionInfoAction from '@actions/get-transaction-info.actions';
import mobileWidth from '@helpers/mobile-width';
import Spinner from '@spinner/spinner';
// import StateBlock from './state-block';
// import Comments from '@layouts/comments';
// import Logs from './logs';
import { compose } from '@utils';
import withGetService from '@hoc/with-get-service';
import {
    loginPath,
    addTxnPrivateNotesPath,
    editTxnPrivateNotesPath,
    blockPath,
    CONFIRMED_TX_STATUS,
    PENDING_TX_STATUS,
} from '@constants';
import withTitle from '@hoc/with-title';
import CopyIcon from '@assets/images/icons/copy-icon';
import ClockIcon from '@assets/images/icons/clock-icon';
import successIcon from '@assets/images/icons/success-icon.svg';
import clockIcon from '@assets/images/icons/clock-icon.svg';
import notFoundIcon from '@assets/images/icons/search-not-found.svg';
import eyeIcon from '@assets/images/icons/eye-icon.svg';
import addIcon from '@assets/images/icons/add-icon.svg';
import Code from './raw-block';
import Details from './details';
import style from './transaction-page.module.scss';

class TransactionPage extends Component {
    static defaultProps = {
        t: () => {},
        getTransactionInfo: () => {},
        match: {},
        transactionInfo: {},
        privateNodeList: {},
        loading: false,
        loggingIn: false,
        transactionStatus: '',
    };

    static propTypes = {
        t: PropTypes.func,
        getTransactionInfo: PropTypes.func,
        match: PropTypes.object,
        transactionInfo: PropTypes.object,
        privateNodeList: PropTypes.object,
        loading: PropTypes.bool,
        loggingIn: PropTypes.bool,
        transactionStatus: PropTypes.string,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const {
            match: {
                params: { id },
            },
        } = this.props;

        if (id !== prevProps.match.params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const {
            t,
            getTransactionInfo,
            match: {
                params: { id },
            },
        } = this.props;
        getTransactionInfo(id, t);
    };

    copied = hash => {
        const { t } = this.props;
        copyToClipboard(hash);
        notification(t('successNotification'), t('сopiedToClipboard'), 'success');
    };

    render() {
        const {
            t,
            transactionInfo,
            loading,
            loggingIn,
            transactionInfo: { hash, time, output_total, block_id, confirmations },
            privateNodeList,
        } = this.props;
        const { TabPane } = Tabs;

        if (loading) {
            return <Spinner />;
        }

        if (!Object.keys(transactionInfo).length) {
            return (
                <div className={style.notFoundIcon}>
                    <img src={notFoundIcon} alt="notFoundIcon" />
                    <p className={style.notFoundIcon__title}>{t('nothingFound')}</p>
                </div>
            );
        }

        const getTime = new Date(+time);
        const timeFromNow = moment(getTime).fromNow();

        const findPrivateNodeList = privateNodeList?.items?.find(item => item.txHash === hash);

        let privateNote = (
            <div>
                {t('notAvailable')},{' '}
                <Link className={style.topBlock__link} to={loginPath}>
                    {t('loginToUpdate')}
                </Link>
            </div>
        );

        if (loggingIn && !findPrivateNodeList?.description) {
            privateNote = (
                <Link to={`${addTxnPrivateNotesPath}/${hash}`} className={style.addPrivateNote}>
                    <img src={addIcon} alt="addIcon" />
                    {t('addPrivateNote')}
                </Link>
            );
        }

        if (loggingIn && findPrivateNodeList?.description) {
            privateNote = (
                <div className={style.tagInfoWrapper}>
                    <p className={style.tagInfoWrapper__tag}>{findPrivateNodeList.description}</p>
                    <Link to={`${editTxnPrivateNotesPath}/${hash}`} className={style.addPrivateNote}>
                        <img src={eyeIcon} alt="eyeIcon" />
                        {t('edit')}
                    </Link>
                </div>
            );
        }

        const TransactionStatus = () => {
            const { transactionStatus } = this.props;
            let icon = '';
            let label = '';
            let labelStyle = '';

            if (transactionStatus === CONFIRMED_TX_STATUS) {
                icon = successIcon;
                label = t('success');
                labelStyle = style.totalOutput__top_success;
            }

            if (transactionStatus === PENDING_TX_STATUS) {
                icon = clockIcon;
                label = t('pending');
                labelStyle = style.totalOutput__top_pending;
            }

            if (icon && label) {
                return (
                    <Fragment>
                        <img className={style.totalOutput__top_icon} src={icon} alt="icon" />
                        <span className={labelStyle}>{label}</span>
                    </Fragment>
                );
            }

            return null;
        };

        const blockUi = () => {
            if (block_id === '-1') {
                return <span className={style.transBlock__block_forked}>{t('forkedFromBitcoin')}</span>;
            }

            if (block_id) {
                return (
                    <Link to={`${blockPath}/${block_id}`} className={style.transBlock__block_blockNumber}>
                        {block_id}
                    </Link>
                );
            }
        };

        return (
            <div className={style.block}>
                <h1 className={style.block__title}>{t('transactionDetails')}</h1>
                <div className={style.totalOutput}>
                    <div className={style.totalOutput__leftSide}>
                        <div className={style.totalOutput__top}>
                            <span className={style.totalOutput__top_totalOutput}>{t('totalOutput')}:</span>
                            <span className={style.totalOutput__top_amount}>
                                {formatNumber(output_total)} BTCU
                            </span>
                        </div>
                        <div className={style.totalOutput__bottom}>
                            <Moment format="DD.MM.YYYY, hh:mm A" unix>
                                {time / 1000}
                            </Moment>
                        </div>
                    </div>
                    <div className={style.privateNote}>
                        <div className={style.privateNote__title}>{t('privateNote')}:</div>
                        {privateNote}
                    </div>
                </div>
                <div className={style.transBlock}>
                    <div className={style.transBlock__hash}>
                        <p className={style.transBlock__label}>{t('transactionHash')}:</p>
                        <div className={style.transBlock__hashWrapper}>
                            <p>{mobileWidth() ? truncate(hash, 30) : hash}</p>
                            <div onClick={() => this.copied(hash)} className={style.transBlock__copy}>
                                <CopyIcon />
                            </div>
                        </div>
                    </div>
                    {block_id ? (
                        <div className={style.transBlock__block}>
                            <p className={style.transBlock__label}>{t('block')}:</p>
                            <div>
                                {blockUi()}
                                <span className={style.transBlock__block_confirmations}>
                                    {confirmations} {t('blockConfirmations')}
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <div className={style.transBlock__block}>
                        <p className={style.transBlock__label}>{t('status')}:</p>
                        <TransactionStatus />
                    </div>
                    <div className={style.transBlock__timestamp}>
                        <p className={style.transBlock__label}>{t('timestamp')}:</p>
                        <div className={style.transBlock__timestampWrapper}>
                            <p className={style.transBlock__timestampWrapper_left}>
                                <ClockIcon />
                                {timeFromNow} (
                                <Moment format="DD.MM.YYYY, hh:mm A" unix>
                                    {time / 1000}
                                </Moment>
                                )
                            </p>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('details')} key="1">
                        <Details transactionInfo={transactionInfo} loggingIn={loggingIn} />
                    </TabPane>
                    <TabPane tab={t('rawTransaction')} key="2">
                        <div className={style.rawBlock}>
                            <div className={style.rawBlock__wrapper}>
                                <Code code={transactionInfo} />
                            </div>
                        </div>
                    </TabPane>
                    {/*
                    <TabPane tab={t('logs')} key="3">
                        <Logs />
                    </TabPane>
                    <TabPane tab={t('state')} key="4">
                        <StateBlock />
                    </TabPane>
                    <TabPane tab={t('comments')} key="5">
                        <Comments />
                    </TabPane>
                    */}
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        transactionInfo: { data: transactionInfo, loading },
        authentication: { loggingIn },
        privateNodeList: {
            data: { privateNodeListArr: privateNodeList },
        },
        transactionStatus: {
            data: { status: transactionStatus },
        },
    } = state;

    return {
        transactionInfo,
        loading,
        loggingIn,
        privateNodeList,
        transactionStatus,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getTransactionInfo: getTransactionInfoAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Transaction Page' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(TransactionPage);

import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import headTitle from '../../../helpers/title';

const TitleComponent = ({ title }) => {
    const defaultTitle = headTitle();

    return (
        <Helmet>
            <title>{`${headTitle()} ${title}` || defaultTitle}</title>
        </Helmet>
    );
};

TitleComponent.defaultProps = {
    title: '',
};

TitleComponent.propTypes = {
    title: PropTypes.string,
};

export default TitleComponent;

import notification from '../helpers/notifications';
import {
    POST_PRIVATE_NODE_LIST,
    TX_HASH_ALREADY_EXIST,
    TX_HASH_NOT_FOUND,
    SEND_LIMIT_EXCEEDED,
    txnPrivateNotesPath,
} from '../constants';

const postPrivateNodeListRequested = () => ({
    type: POST_PRIVATE_NODE_LIST.POST_PRIVATE_NODE_LIST_REQUEST,
});

const postPrivateNodeListLoaded = data => ({
    type: POST_PRIVATE_NODE_LIST.POST_PRIVATE_NODE_LIST_SUCCESS,
    payload: data,
});

const postPrivateNodeListError = error => ({
    type: POST_PRIVATE_NODE_LIST.POST_PRIVATE_NODE_LIST_FAILURE,
    payload: error,
});

const postPrivateNodeListAction = postService => (values, t, history) => dispatch => {
    dispatch(postPrivateNodeListRequested());
    postService
        .addPrivateNodeList(values)
        .then(data => {
            dispatch(postPrivateNodeListLoaded(data));
            notification('', t('privateNoteAdded'), 'success');
            history.push(txnPrivateNotesPath);
        })
        .catch(error => {
            dispatch(postPrivateNodeListError(error.response.data.message));
            const {
                response: { data: { message = 'Something went wrong' } = {} } = {},
            } = error;
            let errorMessage = message;

            if (message === TX_HASH_ALREADY_EXIST) {
                errorMessage = t('error.privateNotesAlreadyAdded');
            }

            if (message === TX_HASH_NOT_FOUND) {
                errorMessage = t('error.txNotFound');
            }

            if (message === SEND_LIMIT_EXCEEDED) {
                errorMessage = t('error.limitIsExceeded');
            }

            notification('', errorMessage, 'danger');
        });
};

export default postPrivateNodeListAction;

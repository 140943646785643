/* eslint-disable */
/*
 Highcharts JS v8.2.2 (2020-10-22)

 3D features for Highcharts JS

 License: www.highcharts.com/license
*/
(function (b) {
    typeof module === 'object' && module.exports ? (b.default = b, module.exports = b) : typeof define === 'function' && define.amd ? define('highcharts/highcharts-3d', ['highcharts'], G => {
        b(G);
        b.Highcharts = G;
        return b;
    }) : b(typeof Highcharts !== 'undefined' ? Highcharts : void 0);
}(b => {
    function G(b, u, w, J) {
        b.hasOwnProperty(u) || (b[u] = J.apply(null, w));
    }

    b = b ? b._modules : {};
    G(b, 'Extensions/Math3D.js', [b['Core/Globals.js'], b['Core/Utilities.js']], (b, u) => {
        const w = u.pick;
        const y = b.deg2rad;
        const c = b.perspective3D = function (c, n, f) {
            n = f > 0 && f < Number.POSITIVE_INFINITY ? f / (c.z + n.z + f) : 1;
            return {
                x: c.x * n,
                y: c.y * n,
            };
        };
        const x = b.perspective = function (q, n, f, r) {
            let m = n.options.chart.options3d;
            const b = w(r, f ? n.inverted : !1);
            const h = {
                x: n.plotWidth / 2,
                y: n.plotHeight / 2,
                z: m.depth / 2,
                vd: w(m.depth, 1) * w(m.viewDistance, 0),
            };
            const k = n.scale3d || 1;
            r = y * m.beta * (b ? -1 : 1);
            m = y * m.alpha * (b ? -1 : 1);
            const a = Math.cos(m);
            const g = Math.cos(-r);
            const C = Math.sin(m);
            const d = Math.sin(-r);
            f || (h.x += n.plotLeft, h.y += n.plotTop);
            return q.map(p => {
                let f = (b ? p.y : p.x) - h.x;
                let m = (b ? p.x : p.y) - h.y;
                p = (p.z || 0) - h.z;
                f = {
                    x: g * f - d * p,
                    y: -C * d * f + a * m - g * C * p,
                    z: a * d * f + C * m + a * g * p,
                };
                m = c(f, h, h.vd);
                m.x = m.x * k + h.x;
                m.y = m.y * k + h.y;
                m.z = f.z * k + h.z;
                return {
                    x: b ? m.y : m.x,
                    y: b ? m.x : m.y,
                    z: m.z,
                };
            });
        };

        u = b.pointCameraDistance = function (c, b) {
            let f = b.options.chart.options3d;
            const r = b.plotWidth / 2;
            b = b.plotHeight / 2;
            f = w(f.depth, 1) * w(f.viewDistance, 0) + f.depth;
            return Math.sqrt(Math.pow(r - w(c.plotX, c.x), 2) + Math.pow(b - w(c.plotY, c.y), 2) + Math.pow(f - w(c.plotZ, c.z), 2));
        };

        const h = b.shapeArea = function (c) {
            let b = 0;
            let f;

            for (f = 0; f < c.length; f++) {
                const r = (f + 1) % c.length;
                b += c[f].x * c[r].y - c[r].x * c[f].y;
            }

            return b / 2;
        };

        b = b.shapeArea3d = function (c, b, f) {
            return h(x(c, b, f));
        };

        return {
            perspective: x,
            perspective3D: c,
            pointCameraDistance: u,
            shapeArea: h,
            shapeArea3D: b,
        };
    });
    G(b, 'Core/Renderer/SVG/SVGRenderer3D.js', [b['Core/Animation/AnimationUtilities.js'], b['Core/Color/Color.js'], b['Core/Globals.js'], b['Extensions/Math3D.js'], b['Core/Renderer/SVG/SVGElement.js'], b['Core/Renderer/SVG/SVGRenderer.js'], b['Core/Utilities.js']], (b, u, w, J, c, x, h) => {
        function q(a, d, e, K, g, l, c, t) {
            let z = [];
            const v = l - g;
            return l > g && l - g > Math.PI / 2 + 0.0001 ? (z = z.concat(q(a, d, e, K, g, g + Math.PI / 2, c, t)), z = z.concat(q(a, d, e, K, g + Math.PI / 2, l, c, t))) : l < g && g - l > Math.PI / 2 + 0.0001 ? (z = z.concat(q(a, d, e, K, g, g - Math.PI / 2, c, t)), z = z.concat(q(a, d, e, K, g - Math.PI / 2, l, c, t))) : [['C', a + e * Math.cos(g) - e * F * v * Math.sin(g) + c, d + K * Math.sin(g) + K * F * v * Math.cos(g) + t, a + e * Math.cos(l) + e * F * v * Math.sin(l) + c, d + K * Math.sin(l) - K * F * v * Math.cos(l) + t, a + e * Math.cos(l) + c, d + K * Math.sin(l) + t]];
        }

        const n = b.animObject;
        const f = u.parse;
        const r = J.perspective;
        const m = J.shapeArea;
        const A = h.defined;
        const y = h.extend;
        const k = h.merge;
        const a = h.objectEach;
        const g = h.pick;
        const C = Math.cos;
        const d = Math.PI;
        const p = Math.sin;
        const I = w.charts;
        const L = w.deg2rad;
        var F = 4 * (Math.sqrt(2) - 1) / 3 / (d / 2);

        x.prototype.toLinePath = function (a, d) {
            const e = [];
            a.forEach(a => {
                e.push(['L', a.x, a.y]);
            });
            a.length && (e[0][0] = 'M', d && e.push(['Z']));
            return e;
        };

        x.prototype.toLineSegments = function (a) {
            const d = [];
            let e = !0;
            a.forEach(a => {
                d.push(e ? ['M', a.x, a.y] : ['L', a.x, a.y]);
                e = !e;
            });
            return d;
        };

        x.prototype.face3d = function (a) {
            const d = this;
            const e = this.createElement('path');
            e.vertexes = [];
            e.insidePlotArea = !1;
            e.enabled = !0;

            e.attr = function (a) {
                if (typeof a === 'object' && (A(a.enabled) || A(a.vertexes) || A(a.insidePlotArea))) {
                    this.enabled = g(a.enabled, this.enabled);
                    this.vertexes = g(a.vertexes, this.vertexes);
                    this.insidePlotArea = g(a.insidePlotArea, this.insidePlotArea);
                    delete a.enabled;
                    delete a.vertexes;
                    delete a.insidePlotArea;
                    let e = r(this.vertexes, I[d.chartIndex], this.insidePlotArea);
                    const l = d.toLinePath(e, !0);
                    e = m(e);
                    e = this.enabled && e > 0 ? 'visible' : 'hidden';
                    a.d = l;
                    a.visibility = e;
                }

                return c.prototype.attr.apply(this, arguments);
            };

            e.animate = function (a) {
                if (typeof a === 'object' && (A(a.enabled) || A(a.vertexes) || A(a.insidePlotArea))) {
                    this.enabled = g(a.enabled, this.enabled);
                    this.vertexes = g(a.vertexes, this.vertexes);
                    this.insidePlotArea = g(a.insidePlotArea, this.insidePlotArea);
                    delete a.enabled;
                    delete a.vertexes;
                    delete a.insidePlotArea;
                    let e = r(this.vertexes, I[d.chartIndex], this.insidePlotArea);
                    const l = d.toLinePath(e, !0);
                    e = m(e);
                    e = this.enabled && e > 0 ? 'visible' : 'hidden';
                    a.d = l;
                    this.attr('visibility', e);
                }

                return c.prototype.animate.apply(this, arguments);
            };

            return e.attr(a);
        };

        x.prototype.polyhedron = function (a) {
            const d = this;
            const e = this.g();
            const g = e.destroy;
            this.styledMode || e.attr({
                'stroke-linejoin': 'round',
            });
            e.faces = [];

            e.destroy = function () {
                for (let a = 0; a < e.faces.length; a++) e.faces[a].destroy();

                return g.call(this);
            };

            e.attr = function (a, l, g, t) {
                if (typeof a === 'object' && A(a.faces)) {
                    for (; e.faces.length > a.faces.length;) e.faces.pop().destroy();

                    for (; e.faces.length < a.faces.length;) e.faces.push(d.face3d().add(e));

                    for (let z = 0; z < a.faces.length; z++) d.styledMode && delete a.faces[z].fill, e.faces[z].attr(a.faces[z], null, g, t);

                    delete a.faces;
                }

                return c.prototype.attr.apply(this, arguments);
            };

            e.animate = function (a, l, g) {
                if (a && a.faces) {
                    for (; e.faces.length > a.faces.length;) e.faces.pop().destroy();

                    for (; e.faces.length < a.faces.length;) e.faces.push(d.face3d().add(e));

                    for (let t = 0; t < a.faces.length; t++) e.faces[t].animate(a.faces[t], l, g);

                    delete a.faces;
                }

                return c.prototype.animate.apply(this, arguments);
            };

            return e.attr(a);
        };

        b = {
            initArgs(a) {
                const d = this;
                const e = d.renderer;
                const g = e[`${d.pathType}Path`](a);
                const c = g.zIndexes;
                d.parts.forEach(a => {
                    d[a] = e.path(g[a]).attr({
                        class: `highcharts-3d-${a}`,
                        zIndex: c[a] || 0,
                    }).add(d);
                });
                d.attr({
                    'stroke-linejoin': 'round',
                    zIndex: c.group,
                });
                d.originalDestroy = d.destroy;
                d.destroy = d.destroyParts;
                d.forcedSides = g.forcedSides;
            },
            singleSetterForParts(d, g, e, c, p, l) {
                const b = {};
                c = [null, null, c || 'attr', p, l];
                const t = e && e.zIndexes;
                e ? (t && t.group && this.attr({
                    zIndex: t.group,
                }), a(e, (a, v) => {
                    b[v] = {};
                    b[v][d] = a;
                    t && (b[v].zIndex = e.zIndexes[v] || 0);
                }), c[1] = b) : (b[d] = g, c[0] = b);
                return this.processParts.apply(this, c);
            },
            processParts(a, d, e, c, p) {
                const l = this;
                l.parts.forEach(b => {
                    d && (a = g(d[b], !1));
                    if (!1 !== a) l[b][e](a, c, p);
                });
                return l;
            },
            destroyParts() {
                this.processParts(null, null, 'destroy');
                return this.originalDestroy();
            },
        };
        var H = k(b, {
            parts: ['front', 'top', 'side'],
            pathType: 'cuboid',
            attr(a, d, e, g) {
                if (typeof a === 'string' && typeof d !== 'undefined') {
                    const p = a;
                    a = {};
                    a[p] = d;
                }

                return a.shapeArgs || A(a.x) ? this.singleSetterForParts('d', null, this.renderer[`${this.pathType}Path`](a.shapeArgs || a)) : c.prototype.attr.call(this, a, void 0, e, g);
            },
            animate(a, d, e) {
                if (A(a.x) && A(a.y)) {
                    a = this.renderer[`${this.pathType}Path`](a);
                    const g = a.forcedSides;
                    this.singleSetterForParts('d', null, a, 'animate', d, e);
                    this.attr({
                        zIndex: a.zIndexes.group,
                    });
                    g !== this.forcedSides && (this.forcedSides = g, H.fillSetter.call(this, this.fill));
                } else c.prototype.animate.call(this, a, d, e);

                return this;
            },
            fillSetter(a) {
                this.forcedSides = this.forcedSides || [];
                this.singleSetterForParts('fill', null, {
                    front: a,
                    top: f(a).brighten(this.forcedSides.indexOf('top') >= 0 ? 0 : 0.1).get(),
                    side: f(a).brighten(this.forcedSides.indexOf('side') >= 0 ? 0 : -0.1).get(),
                });
                this.color = this.fill = a;
                return this;
            },
        });
        x.prototype.elements3d = {
            base: b,
            cuboid: H,
        };

        x.prototype.element3d = function (a, d) {
            const e = this.g();
            y(e, this.elements3d[a]);
            e.initArgs(d);
            return e;
        };

        x.prototype.cuboid = function (a) {
            return this.element3d('cuboid', a);
        };

        x.prototype.cuboidPath = function (a) {
            function d(a) {
                return p === 0 && a > 1 && a < 6 ? {
                    x: E[a].x,
                    y: E[a].y + 10,
                    z: E[a].z,
                } : E[0].x === E[7].x && a >= 4 ? {
                    x: E[a].x + 10,
                    y: E[a].y,
                    z: E[a].z,
                } : z === 0 && a < 2 || a > 5 ? {
                    x: E[a].x,
                    y: E[a].y,
                    z: E[a].z + 10,
                } : E[a];
            }

            function e(a) {
                return E[a];
            }

            const g = a.x;
            const c = a.y;
            const l = a.z || 0;
            var p = a.height;
            let t = a.width;
            var z = a.depth;
            const v = I[this.chartIndex];
            const B = v.options.chart.options3d.alpha;
            let b = 0;
            var E = [{
                x: g,
                y: c,
                z: l,
            }, {
                x: g + t,
                y: c,
                z: l,
            }, {
                x: g + t,
                y: c + p,
                z: l,
            }, {
                x: g,
                y: c + p,
                z: l,
            }, {
                x: g,
                y: c + p,
                z: l + z,
            }, {
                x: g + t,
                y: c + p,
                z: l + z,
            }, {
                x: g + t,
                y: c,
                z: l + z,
            }, {
                x: g,
                y: c,
                z: l + z,
            }];
            const f = [];
            E = r(E, v, a.insidePlotArea);

            let D = function (a, v, g) {
                let c = [[], -1];
                const l = a.map(e);
                const B = v.map(e);
                a = a.map(d);
                v = v.map(d);
                m(l) < 0 ? c = [l, 0] : m(B) < 0 ? c = [B, 1] : g && (f.push(g), c = m(a) < 0 ? [l, 0] : m(v) < 0 ? [B, 1] : [l, 0]);
                return c;
            };

            let C = D([3, 2, 1, 0], [7, 6, 5, 4], 'front');
            a = C[0];
            const k = C[1];
            C = D([1, 6, 7, 0], [4, 5, 2, 3], 'top');
            t = C[0];
            const F = C[1];
            C = D([1, 2, 5, 6], [0, 7, 4, 3], 'side');
            D = C[0];
            C = C[1];
            C === 1 ? b += 1E6 * (v.plotWidth - g) : C || (b += 1E6 * g);
            b += 10 * (!F || B >= 0 && B <= 180 || B < 360 && B > 357.5 ? v.plotHeight - c : 10 + c);
            k === 1 ? b += 100 * l : k || (b += 100 * (1E3 - l));
            return {
                front: this.toLinePath(a, !0),
                top: this.toLinePath(t, !0),
                side: this.toLinePath(D, !0),
                zIndexes: {
                    group: Math.round(b),
                },
                forcedSides: f,
                isFront: k,
                isTop: F,
            };
        };

        x.prototype.arc3d = function (a) {
            function d(a) {
                let d = !1;
                const e = {};
                let g;
                a = k(a);

                for (g in a) b.indexOf(g) !== -1 && (e[g] = a[g], delete a[g], d = !0);

                return d ? [e, a] : !1;
            }

            const e = this.g();
            const p = e.renderer;
            var b = 'x y r innerR start end depth'.split(' ');
            a = k(a);
            a.alpha = (a.alpha || 0) * L;
            a.beta = (a.beta || 0) * L;
            e.top = p.path();
            e.side1 = p.path();
            e.side2 = p.path();
            e.inn = p.path();
            e.out = p.path();

            e.onAdd = function () {
                const a = e.parentGroup;
                const d = e.attr('class');
                e.top.add(e);
                ['out', 'inn', 'side1', 'side2'].forEach(g => {
                    e[g].attr({
                        class: `${d} highcharts-3d-side`,
                    }).add(a);
                });
            };

            ['addClass', 'removeClass'].forEach(a => {
                e[a] = function () {
                    const d = arguments;
                    ['top', 'out', 'inn', 'side1', 'side2'].forEach(g => {
                        e[g][a].apply(e[g], d);
                    });
                };
            });

            e.setPaths = function (a) {
                const d = e.renderer.arc3dPath(a);
                const g = 100 * d.zTop;
                e.attribs = a;
                e.top.attr({
                    d: d.top,
                    zIndex: d.zTop,
                });
                e.inn.attr({
                    d: d.inn,
                    zIndex: d.zInn,
                });
                e.out.attr({
                    d: d.out,
                    zIndex: d.zOut,
                });
                e.side1.attr({
                    d: d.side1,
                    zIndex: d.zSide1,
                });
                e.side2.attr({
                    d: d.side2,
                    zIndex: d.zSide2,
                });
                e.zIndex = g;
                e.attr({
                    zIndex: g,
                });
                a.center && (e.top.setRadialReference(a.center), delete a.center);
            };

            e.setPaths(a);

            e.fillSetter = function (a) {
                const d = f(a).brighten(-0.1).get();
                this.fill = a;
                this.side1.attr({
                    fill: d,
                });
                this.side2.attr({
                    fill: d,
                });
                this.inn.attr({
                    fill: d,
                });
                this.out.attr({
                    fill: d,
                });
                this.top.attr({
                    fill: a,
                });
                return this;
            };

            ['opacity', 'translateX', 'translateY', 'visibility'].forEach(a => {
                e[`${a}Setter`] = function (a, d) {
                    e[d] = a;
                    ['out', 'inn', 'side1', 'side2', 'top'].forEach(g => {
                        e[g].attr(d, a);
                    });
                };
            });

            e.attr = function (a) {
                let g;

                if (typeof a === 'object' && (g = d(a))) {
                    const p = g[0];
                    arguments[0] = g[1];
                    y(e.attribs, p);
                    e.setPaths(e.attribs);
                }

                return c.prototype.attr.apply(e, arguments);
            };

            e.animate = function (a, p, b) {
                const z = this.attribs;
                const v = `data-${Math.random().toString(26).substring(2, 9)}`;
                delete a.center;
                delete a.z;
                delete a.alpha;
                delete a.beta;
                const B = n(g(p, this.renderer.globalAnimation));

                if (B.duration) {
                    p = d(a);
                    e[v] = 0;
                    a[v] = 1;
                    e[`${v}Setter`] = w.noop;

                    if (p) {
                        const l = p[0];

                        B.step = function (a, d) {
                            function e(a) {
                                return z[a] + (g(l[a], z[a]) - z[a]) * d.pos;
                            }

                            d.prop === v && d?.elem?.setPaths(k(z, {
                                x: e('x'),
                                y: e('y'),
                                r: e('r'),
                                innerR: e('innerR'),
                                start: e('start'),
                                end: e('end'),
                                depth: e('depth'),
                            }));
                        };
                    }

                    p = B;
                }

                return c.prototype.animate.call(this, a, p, b);
            };

            e.destroy = function () {
                this.top.destroy();
                this.out.destroy();
                this.inn.destroy();
                this.side1.destroy();
                this.side2.destroy();
                return c.prototype.destroy.call(this);
            };

            e.hide = function () {
                this.top.hide();
                this.out.hide();
                this.inn.hide();
                this.side1.hide();
                this.side2.hide();
            };

            e.show = function (a) {
                this.top.show(a);
                this.out.show(a);
                this.inn.show(a);
                this.side1.show(a);
                this.side2.show(a);
            };

            return e;
        };

        x.prototype.arc3dPath = function (a) {
            function g(a) {
                a %= 2 * Math.PI;
                a > Math.PI && (a = 2 * Math.PI - a);
                return a;
            }

            let e = a.x;
            let c = a.y;
            let b = a.start;
            let l = a.end - 0.00001;
            let f = a.r;
            let t = a.innerR || 0;
            let z = a.depth || 0;
            let v = a.alpha;
            let B = a.beta;
            let m = Math.cos(b);
            const E = Math.sin(b);
            a = Math.cos(l);
            let k = Math.sin(l);
            const D = f * Math.cos(B);
            f *= Math.cos(v);
            const F = t * Math.cos(B);
            const r = t * Math.cos(v);
            t = z * Math.sin(B);
            const h = z * Math.sin(v);
            z = [['M', e + D * m, c + f * E]];
            z = z.concat(q(e, c, D, f, b, l, 0, 0));
            z.push(['L', e + F * a, c + r * k]);
            z = z.concat(q(e, c, F, r, l, b, 0, 0));
            z.push(['Z']);
            let n = B > 0 ? Math.PI / 2 : 0;
            B = v > 0 ? 0 : Math.PI / 2;
            n = b > -n ? b : l > -n ? -n : b;
            const H = l < d - B ? l : b < d - B ? d - B : l;
            const I = 2 * d - B;
            v = [['M', e + D * C(n), c + f * p(n)]];
            v = v.concat(q(e, c, D, f, n, H, 0, 0));
            l > I && b < I ? (v.push(['L', e + D * C(H) + t, c + f * p(H) + h]), v = v.concat(q(e, c, D, f, H, I, t, h)), v.push(['L', e + D * C(I), c + f * p(I)]), v = v.concat(q(e, c, D, f, I, l, 0, 0)), v.push(['L', e + D * C(l) + t, c + f * p(l) + h]), v = v.concat(q(e, c, D, f, l, I, t, h)), v.push(['L', e + D * C(I), c + f * p(I)]), v = v.concat(q(e, c, D, f, I, H, 0, 0))) : l > d - B && b < d - B && (v.push(['L', e + D * Math.cos(H) + t, c + f * Math.sin(H) + h]), v = v.concat(q(e, c, D, f, H, l, t, h)), v.push(['L', e + D * Math.cos(l), c + f * Math.sin(l)]), v = v.concat(q(e, c, D, f, l, H, 0, 0)));
            v.push(['L', e + D * Math.cos(H) + t, c + f * Math.sin(H) + h]);
            v = v.concat(q(e, c, D, f, H, n, t, h));
            v.push(['Z']);
            B = [['M', e + F * m, c + r * E]];
            B = B.concat(q(e, c, F, r, b, l, 0, 0));
            B.push(['L', e + F * Math.cos(l) + t, c + r * Math.sin(l) + h]);
            B = B.concat(q(e, c, F, r, l, b, t, h));
            B.push(['Z']);
            m = [['M', e + D * m, c + f * E], ['L', e + D * m + t, c + f * E + h], ['L', e + F * m + t, c + r * E + h], ['L', e + F * m, c + r * E], ['Z']];
            e = [['M', e + D * a, c + f * k], ['L', e + D * a + t, c + f * k + h], ['L', e + F * a + t, c + r * k + h], ['L', e + F * a, c + r * k], ['Z']];
            k = Math.atan2(h, -t);
            c = Math.abs(l + k);
            a = Math.abs(b + k);
            b = Math.abs((b + l) / 2 + k);
            c = g(c);
            a = g(a);
            b = g(b);
            b *= 1E5;
            l = 1E5 * a;
            c *= 1E5;
            return {
                top: z,
                zTop: 1E5 * Math.PI + 1,
                out: v,
                zOut: Math.max(b, l, c),
                inn: B,
                zInn: Math.max(b, l, c),
                side1: m,
                zSide1: 0.99 * c,
                side2: e,
                zSide2: 0.99 * l,
            };
        };

        return x;
    });
    G(b, 'Core/Axis/Tick3D.js', [b['Core/Utilities.js']], b => {
        const u = b.addEvent;
        const w = b.extend;
        const y = b.wrap;
        return (function () {
            function c() {}

            c.compose = function (b) {
                u(b, 'afterGetLabelPosition', c.onAfterGetLabelPosition);
                y(b.prototype, 'getMarkPath', c.wrapGetMarkPath);
            };

            c.onAfterGetLabelPosition = function (c) {
                const b = this.axis.axis3D;
                b && w(c.pos, b.fix3dPosition(c.pos));
            };

            c.wrapGetMarkPath = function (c) {
                let b = this.axis.axis3D;
                const q = c.apply(this, [].slice.call(arguments, 1));

                if (b) {
                    const n = q[0];
                    const f = q[1];
                    if (n[0] === 'M' && f[0] === 'L') {
                        return b = [b.fix3dPosition({
                            x: n[1],
                            y: n[2],
                            z: 0,
                        }), b.fix3dPosition({
                            x: f[1],
                            y: f[2],
                            z: 0,
                        })], this.axis.chart.renderer.toLineSegments(b);
                    }
                }

                return q;
            };

            return c;
        }());
    });
    G(b, 'Core/Axis/Axis3D.js', [b['Core/Globals.js'], b['Extensions/Math3D.js'], b['Core/Axis/Tick.js'], b['Core/Axis/Tick3D.js'], b['Core/Utilities.js']], (b, u, w, J, c) => {
        const x = u.perspective;
        const h = u.perspective3D;
        const q = u.shapeArea;
        const n = c.addEvent;
        const f = c.merge;
        const r = c.pick;
        const m = c.wrap;
        const A = b.deg2rad;
        const y = (function () {
            function c(a) {
                this.axis = a;
            }

            c.prototype.fix3dPosition = function (a, c) {
                const g = this.axis;
                let d = g.chart;
                if (g.coll === 'colorAxis' || !d.chart3d || !d.is3d()) return a;
                let b = A * d.options.chart.options3d.alpha;
                let f = A * d.options.chart.options3d.beta;
                let m = r(c && g.options.title.position3d, g.options.labels.position3d);
                c = r(c && g.options.title.skew3d, g.options.labels.skew3d);
                const k = d.chart3d.frame3d;
                let h = d.plotLeft;
                const n = d.plotWidth + h;
                const u = d.plotTop;
                const e = d.plotHeight + u;
                d = !1;
                let w = 0;
                let y = 0;
                let l = {
                    x: 0,
                    y: 1,
                    z: 0,
                };
                a = g.axis3D.swapZ({
                    x: a.x,
                    y: a.y,
                    z: 0,
                });
                if (g.isZAxis) {
                    if (g.opposite) {
                        if (k.axes.z.top === null) return {};
                        y = a.y - u;
                        a.x = k.axes.z.top.x;
                        a.y = k.axes.z.top.y;
                        h = k.axes.z.top.xDir;
                        d = !k.top.frontFacing;
                    } else {
                        if (k.axes.z.bottom === null) return {};
                        y = a.y - e;
                        a.x = k.axes.z.bottom.x;
                        a.y = k.axes.z.bottom.y;
                        h = k.axes.z.bottom.xDir;
                        d = !k.bottom.frontFacing;
                    }
                } else if (g.horiz) {
                    if (g.opposite) {
                        if (k.axes.x.top === null) return {};
                        y = a.y - u;
                        a.y = k.axes.x.top.y;
                        a.z = k.axes.x.top.z;
                        h = k.axes.x.top.xDir;
                        d = !k.top.frontFacing;
                    } else {
                        if (k.axes.x.bottom === null) return {};
                        y = a.y - e;
                        a.y = k.axes.x.bottom.y;
                        a.z = k.axes.x.bottom.z;
                        h = k.axes.x.bottom.xDir;
                        d = !k.bottom.frontFacing;
                    }
                } else if (g.opposite) {
                    if (k.axes.y.right === null) return {};
                    w = a.x - n;
                    a.x = k.axes.y.right.x;
                    a.z = k.axes.y.right.z;
                    h = k.axes.y.right.xDir;
                    h = {
                        x: h.z,
                        y: h.y,
                        z: -h.x,
                    };
                } else {
                    if (k.axes.y.left === null) return {};
                    w = a.x - h;
                    a.x = k.axes.y.left.x;
                    a.z = k.axes.y.left.z;
                    h = k.axes.y.left.xDir;
                }
                m !== 'chart' && (m === 'flap' ? g.horiz ? (f = Math.sin(b), b = Math.cos(b), g.opposite && (f = -f), d && (f = -f), l = {
                    x: h.z * f,
                    y: b,
                    z: -h.x * f,
                }) : h = {
                    x: Math.cos(f),
                    y: 0,
                    z: Math.sin(f),
                } : m === 'ortho' ? g.horiz ? (l = Math.cos(b), m = Math.sin(f) * l, b = -Math.sin(b), f = -l * Math.cos(f), l = {
                    x: h.y * f - h.z * b,
                    y: h.z * m - h.x * f,
                    z: h.x * b - h.y * m,
                }, b = 1 / Math.sqrt(l.x * l.x + l.y * l.y + l.z * l.z), d && (b = -b), l = {
                    x: b * l.x,
                    y: b * l.y,
                    z: b * l.z,
                }) : h = {
                    x: Math.cos(f),
                    y: 0,
                    z: Math.sin(f),
                } : g.horiz ? l = {
                    x: Math.sin(f) * Math.sin(b),
                    y: Math.cos(b),
                    z: -Math.cos(f) * Math.sin(b),
                } : h = {
                    x: Math.cos(f),
                    y: 0,
                    z: Math.sin(f),
                });
                a.x += w * h.x + y * l.x;
                a.y += w * h.y + y * l.y;
                a.z += w * h.z + y * l.z;
                d = x([a], g.chart)[0];
                c && (q(x([a, {
                    x: a.x + h.x,
                    y: a.y + h.y,
                    z: a.z + h.z,
                }, {
                    x: a.x + l.x,
                    y: a.y + l.y,
                    z: a.z + l.z,
                }], g.chart)) < 0 && (h = {
                    x: -h.x,
                    y: -h.y,
                    z: -h.z,
                }), a = x([{
                    x: a.x,
                    y: a.y,
                    z: a.z,
                }, {
                    x: a.x + h.x,
                    y: a.y + h.y,
                    z: a.z + h.z,
                }, {
                    x: a.x + l.x,
                    y: a.y + l.y,
                    z: a.z + l.z,
                }], g.chart), d.matrix = [a[1].x - a[0].x, a[1].y - a[0].y, a[2].x - a[0].x, a[2].y - a[0].y, d.x, d.y], d.matrix[4] -= d.x * d.matrix[0] + d.y * d.matrix[2], d.matrix[5] -= d.x * d.matrix[1] + d.y * d.matrix[3]);
                return d;
            };

            c.prototype.swapZ = function (a, g) {
                const c = this.axis;
                return c.isZAxis ? (g = g ? 0 : c.chart.plotLeft, {
                    x: g + a.z,
                    y: a.y,
                    z: a.x - g,
                }) : a;
            };

            return c;
        }());

        return (function () {
            function c() {}

            c.compose = function (a) {
                f(!0, a.defaultOptions, c.defaultOptions);
                a.keepProps.push('axis3D');
                n(a, 'init', c.onInit);
                n(a, 'afterSetOptions', c.onAfterSetOptions);
                n(a, 'drawCrosshair', c.onDrawCrosshair);
                n(a, 'destroy', c.onDestroy);
                a = a.prototype;
                m(a, 'getLinePath', c.wrapGetLinePath);
                m(a, 'getPlotBandPath', c.wrapGetPlotBandPath);
                m(a, 'getPlotLinePath', c.wrapGetPlotLinePath);
                m(a, 'getSlotWidth', c.wrapGetSlotWidth);
                m(a, 'getTitlePosition', c.wrapGetTitlePosition);
                J.compose(w);
            };

            c.onAfterSetOptions = function () {
                const a = this.chart;
                const c = this.options;
                a.is3d && a.is3d() && this.coll !== 'colorAxis' && (c.tickWidth = r(c.tickWidth, 0), c.gridLineWidth = r(c.gridLineWidth, 1));
            };

            c.onDestroy = function () {
                ['backFrame', 'bottomFrame', 'sideFrame'].forEach(function (a) {
                    this[a] && (this[a] = this[a].destroy());
                }, this);
            };

            c.onDrawCrosshair = function (a) {
                this.chart.is3d() && this.coll !== 'colorAxis' && a.point && (a.point.crosshairPos = this.isXAxis ? a.point.axisXpos : this.len - a.point.axisYpos);
            };

            c.onInit = function () {
                this.axis3D || (this.axis3D = new y(this));
            };

            c.wrapGetLinePath = function (a) {
                return this.chart.is3d() && this.coll !== 'colorAxis' ? [] : a.apply(this, [].slice.call(arguments, 1));
            };

            c.wrapGetPlotBandPath = function (a) {
                if (!this.chart.is3d() || this.coll === 'colorAxis') return a.apply(this, [].slice.call(arguments, 1));
                let c = arguments;
                let b = c[2];
                const d = [];
                c = this.getPlotLinePath({
                    value: c[1],
                });
                b = this.getPlotLinePath({
                    value: b,
                });
                if (c && b) {
                    for (let f = 0; f < c.length; f += 2) {
                        const h = c[f];
                        const m = c[f + 1];
                        const k = b[f];
                        const r = b[f + 1];
                        h[0] === 'M' && m[0] === 'L' && k[0] === 'M' && r[0] === 'L' && d.push(h, m, r, ['L', k[1], k[2]], ['Z']);
                    }
                }
                return d;
            };

            c.wrapGetPlotLinePath = function (a) {
                let c = this.axis3D;
                const b = this.chart;
                let d = a.apply(this, [].slice.call(arguments, 1));
                if (this.coll === 'colorAxis' || !b.chart3d || !b.is3d() || d === null) return d;
                let f = b.options.chart.options3d;
                const h = this.isZAxis ? b.plotWidth : f.depth;
                f = b.chart3d.frame3d;
                const m = d[0];
                const k = d[1];
                d = [];
                m[0] === 'M' && k[0] === 'L' && (c = [c.swapZ({
                    x: m[1],
                    y: m[2],
                    z: 0,
                }), c.swapZ({
                    x: m[1],
                    y: m[2],
                    z: h,
                }), c.swapZ({
                    x: k[1],
                    y: k[2],
                    z: 0,
                }), c.swapZ({
                    x: k[1],
                    y: k[2],
                    z: h,
                })], this.horiz ? (this.isZAxis ? (f.left.visible && d.push(c[0], c[2]), f.right.visible && d.push(c[1], c[3])) : (f.front.visible && d.push(c[0], c[2]), f.back.visible && d.push(c[1], c[3])), f.top.visible && d.push(c[0], c[1]), f.bottom.visible && d.push(c[2], c[3])) : (f.front.visible && d.push(c[0], c[2]), f.back.visible && d.push(c[1], c[3]), f.left.visible && d.push(c[0], c[1]), f.right.visible && d.push(c[2], c[3])), d = x(d, this.chart, !1));
                return b.renderer.toLineSegments(d);
            };

            c.wrapGetSlotWidth = function (a, c) {
                let b = this.chart;
                let d = this.ticks;
                let f = this.gridGroup;

                if (this.categories && b.frameShapes && b.is3d() && f && c && c.label) {
                    f = f.element.childNodes[0].getBBox();
                    const g = b.frameShapes.left.getBBox();
                    let m = b.options.chart.options3d;
                    b = {
                        x: b.plotWidth / 2,
                        y: b.plotHeight / 2,
                        z: m.depth / 2,
                        vd: r(m.depth, 1) * r(m.viewDistance, 0),
                    };
                    let k; let
                        n;
                    m = c.pos;
                    const q = d[m - 1];
                    d = d[m + 1];
                    m !== 0 && q && q.label.xy && (k = h({
                        x: q.label.xy.x,
                        y: q.label.xy.y,
                        z: null,
                    }, b, b.vd));
                    d && d.label.xy && (n = h({
                        x: d.label.xy.x,
                        y: d.label.xy.y,
                        z: null,
                    }, b, b.vd));
                    d = {
                        x: c.label.xy.x,
                        y: c.label.xy.y,
                        z: null,
                    };
                    d = h(d, b, b.vd);
                    return Math.abs(k ? d.x - k.x : n ? n.x - d.x : f.x - g.x);
                }

                return a.apply(this, [].slice.call(arguments, 1));
            };

            c.wrapGetTitlePosition = function (a) {
                const c = a.apply(this, [].slice.call(arguments, 1));
                return this.axis3D ? this.axis3D.fix3dPosition(c, !0) : c;
            };

            c.defaultOptions = {
                labels: {
                    position3d: 'offset',
                    skew3d: !1,
                },
                title: {
                    position3d: null,
                    skew3d: null,
                },
            };
            return c;
        }());
    });
    G(b, 'Core/Axis/ZAxis.js', [b['Core/Axis/Axis.js'], b['Core/Utilities.js']], function (b, u) {
        const w = this && this.__extends || (function () {
            var c = function (b, f) {
                c = Object.setPrototypeOf || {
                    __proto__: [],
                } instanceof Array && function (c, b) {
                    c.__proto__ = b;
                } || function (c, b) {
                    for (const f in b) b.hasOwnProperty(f) && (c[f] = b[f]);
                };

                return c(b, f);
            };

            return function (b, f) {
                function h() {
                    this.constructor = b;
                }

                c(b, f);
                b.prototype = f === null ? Object.create(f) : (h.prototype = f.prototype, new h());
            };
        }());
        const y = u.addEvent;
        const c = u.merge;
        const x = u.pick;
        const h = u.splat;
        const q = (function () {
            function c() {}

            c.compose = function (b) {
                y(b, 'afterGetAxes', c.onAfterGetAxes);
                b = b.prototype;
                b.addZAxis = c.wrapAddZAxis;
                b.collectionsWithInit.zAxis = [b.addZAxis];
                b.collectionsWithUpdate.push('zAxis');
            };

            c.onAfterGetAxes = function () {
                const c = this;
                let b = this.options;
                b = b.zAxis = h(b.zAxis || {});
                c.is3d() && (c.zAxis = [], b.forEach((b, f) => {
                    b.index = f;
                    b.isX = !0;
                    c.addZAxis(b).setScale();
                }));
            };

            c.wrapAddZAxis = function (c) {
                return new n(this, c);
            };

            return c;
        }());
        var n = (function (b) {
            function f(c, f) {
                c = b.call(this, c, f) || this;
                c.isZAxis = !0;
                return c;
            }

            w(f, b);

            f.prototype.getSeriesExtremes = function () {
                const c = this;
                const b = c.chart;
                c.hasVisibleSeries = !1;
                c.dataMin = c.dataMax = c.ignoreMinPadding = c.ignoreMaxPadding = void 0;
                c.stacking && c.stacking.buildStacks();
                c.series.forEach(f => {
                    !f.visible && b.options.chart && b.options.chart.ignoreHiddenSeries || (c.hasVisibleSeries = !0, f = f.zData, f.length && (c.dataMin = Math.min(x(c.dataMin, f[0]), Math.min.apply(null, f)), c.dataMax = Math.max(x(c.dataMax, f[0]), Math.max.apply(null, f))));
                });
            };

            f.prototype.setAxisSize = function () {
                const c = this.chart;
                b.prototype.setAxisSize.call(this);
                this.width = this.len = c.options.chart && c.options.chart.options3d && c.options.chart.options3d.depth || 0;
                this.right = c.chartWidth - this.width - this.left;
            };

            f.prototype.setOptions = function (f) {
                f = c({
                    offset: 0,
                    lineWidth: 0,
                }, f);
                b.prototype.setOptions.call(this, f);
                this.coll = 'zAxis';
            };

            f.ZChartComposition = q;
            return f;
        }(b));

        return n;
    });
    G(b, 'Core/Chart/Chart3D.js', [b['Core/Axis/Axis.js'], b['Core/Axis/Axis3D.js'], b['Core/Chart/Chart.js'], b['Core/Animation/Fx.js'], b['Core/Globals.js'], b['Extensions/Math3D.js'], b['Core/Options.js'], b['Core/Utilities.js'], b['Core/Axis/ZAxis.js']], (b, u, w, J, c, x, h, q, n) => {
        const f = x.perspective;
        const r = x.shapeArea3D;
        const m = h.defaultOptions;
        const A = q.addEvent;
        const y = q.isArray;
        const k = q.merge;
        const a = q.pick;
        const g = q.wrap;
        let C;

        (function (b) {
            function d(a) {
                this.is3d() && a.options.type === 'scatter' && (a.options.type = 'scatter3d');
            }

            function h() {
                if (this.chart3d && this.is3d()) {
                    const a = this.renderer;
                    let b = this.options.chart.options3d;
                    const d = this.chart3d.get3dFrame();
                    const f = this.plotLeft;
                    const e = this.plotLeft + this.plotWidth;
                    const g = this.plotTop;
                    const h = this.plotTop + this.plotHeight;
                    b = b.depth;
                    const k = f - (d.left.visible ? d.left.size : 0);
                    const l = e + (d.right.visible ? d.right.size : 0);
                    const p = g - (d.top.visible ? d.top.size : 0);
                    const m = h + (d.bottom.visible ? d.bottom.size : 0);
                    const n = 0 - (d.front.visible ? d.front.size : 0);
                    const q = b + (d.back.visible ? d.back.size : 0);
                    const r = this.hasRendered ? 'animate' : 'attr';
                    this.chart3d.frame3d = d;
                    this.frameShapes || (this.frameShapes = {
                        bottom: a.polyhedron().add(),
                        top: a.polyhedron().add(),
                        left: a.polyhedron().add(),
                        right: a.polyhedron().add(),
                        back: a.polyhedron().add(),
                        front: a.polyhedron().add(),
                    });
                    this.frameShapes.bottom[r]({
                        class: 'highcharts-3d-frame highcharts-3d-frame-bottom',
                        zIndex: d.bottom.frontFacing ? -1E3 : 1E3,
                        faces: [{
                            fill: c.color(d.bottom.color).brighten(0.1).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: k,
                                y: m,
                                z: q,
                            }],
                            enabled: d.bottom.visible,
                        }, {
                            fill: c.color(d.bottom.color).brighten(0.1).get(),
                            vertexes: [{
                                x: f,
                                y: h,
                                z: b,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }],
                            enabled: d.bottom.visible,
                        }, {
                            fill: c.color(d.bottom.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: k,
                                y: m,
                                z: q,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }],
                            enabled: d.bottom.visible && !d.left.visible,
                        }, {
                            fill: c.color(d.bottom.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }],
                            enabled: d.bottom.visible && !d.right.visible,
                        }, {
                            fill: c.color(d.bottom.color).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }],
                            enabled: d.bottom.visible && !d.front.visible,
                        }, {
                            fill: c.color(d.bottom.color).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: q,
                            }, {
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }],
                            enabled: d.bottom.visible && !d.back.visible,
                        }],
                    });
                    this.frameShapes.top[r]({
                        class: 'highcharts-3d-frame highcharts-3d-frame-top',
                        zIndex: d.top.frontFacing ? -1E3 : 1E3,
                        faces: [{
                            fill: c.color(d.top.color).brighten(0.1).get(),
                            vertexes: [{
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: k,
                                y: p,
                                z: n,
                            }],
                            enabled: d.top.visible,
                        }, {
                            fill: c.color(d.top.color).brighten(0.1).get(),
                            vertexes: [{
                                x: f,
                                y: g,
                                z: 0,
                            }, {
                                x: e,
                                y: g,
                                z: 0,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }, {
                                x: f,
                                y: g,
                                z: b,
                            }],
                            enabled: d.top.visible,
                        }, {
                            fill: c.color(d.top.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: k,
                                y: p,
                                z: n,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }, {
                                x: f,
                                y: g,
                                z: b,
                            }],
                            enabled: d.top.visible && !d.left.visible,
                        }, {
                            fill: c.color(d.top.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }, {
                                x: e,
                                y: g,
                                z: 0,
                            }],
                            enabled: d.top.visible && !d.right.visible,
                        }, {
                            fill: c.color(d.top.color).get(),
                            vertexes: [{
                                x: k,
                                y: p,
                                z: n,
                            }, {
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: e,
                                y: g,
                                z: 0,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }],
                            enabled: d.top.visible && !d.front.visible,
                        }, {
                            fill: c.color(d.top.color).get(),
                            vertexes: [{
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: f,
                                y: g,
                                z: b,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }],
                            enabled: d.top.visible && !d.back.visible,
                        }],
                    });
                    this.frameShapes.left[r]({
                        class: 'highcharts-3d-frame highcharts-3d-frame-left',
                        zIndex: d.left.frontFacing ? -1E3 : 1E3,
                        faces: [{
                            fill: c.color(d.left.color).brighten(0.1).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }, {
                                x: k,
                                y: m,
                                z: q,
                            }],
                            enabled: d.left.visible && !d.bottom.visible,
                        }, {
                            fill: c.color(d.left.color).brighten(0.1).get(),
                            vertexes: [{
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: f,
                                y: g,
                                z: b,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }, {
                                x: k,
                                y: p,
                                z: n,
                            }],
                            enabled: d.left.visible && !d.top.visible,
                        }, {
                            fill: c.color(d.left.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: q,
                            }, {
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: k,
                                y: p,
                                z: n,
                            }, {
                                x: k,
                                y: m,
                                z: n,
                            }],
                            enabled: d.left.visible,
                        }, {
                            fill: c.color(d.left.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: f,
                                y: g,
                                z: b,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }],
                            enabled: d.left.visible,
                        }, {
                            fill: c.color(d.left.color).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: k,
                                y: p,
                                z: n,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }],
                            enabled: d.left.visible && !d.front.visible,
                        }, {
                            fill: c.color(d.left.color).get(),
                            vertexes: [{
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: k,
                                y: m,
                                z: q,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }, {
                                x: f,
                                y: g,
                                z: b,
                            }],
                            enabled: d.left.visible && !d.back.visible,
                        }],
                    });
                    this.frameShapes.right[r]({
                        class: 'highcharts-3d-frame highcharts-3d-frame-right',
                        zIndex: d.right.frontFacing ? -1E3 : 1E3,
                        faces: [{
                            fill: c.color(d.right.color).brighten(0.1).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }, {
                                x: l,
                                y: m,
                                z: n,
                            }],
                            enabled: d.right.visible && !d.bottom.visible,
                        }, {
                            fill: c.color(d.right.color).brighten(0.1).get(),
                            vertexes: [{
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: e,
                                y: g,
                                z: 0,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }, {
                                x: l,
                                y: p,
                                z: q,
                            }],
                            enabled: d.right.visible && !d.top.visible,
                        }, {
                            fill: c.color(d.right.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: e,
                                y: g,
                                z: 0,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }],
                            enabled: d.right.visible,
                        }, {
                            fill: c.color(d.right.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: l,
                                y: m,
                                z: q,
                            }],
                            enabled: d.right.visible,
                        }, {
                            fill: c.color(d.right.color).get(),
                            vertexes: [{
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }, {
                                x: e,
                                y: g,
                                z: 0,
                            }],
                            enabled: d.right.visible && !d.front.visible,
                        }, {
                            fill: c.color(d.right.color).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }],
                            enabled: d.right.visible && !d.back.visible,
                        }],
                    });
                    this.frameShapes.back[r]({
                        class: 'highcharts-3d-frame highcharts-3d-frame-back',
                        zIndex: d.back.frontFacing ? -1E3 : 1E3,
                        faces: [{
                            fill: c.color(d.back.color).brighten(0.1).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: k,
                                y: m,
                                z: q,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }],
                            enabled: d.back.visible && !d.bottom.visible,
                        }, {
                            fill: c.color(d.back.color).brighten(0.1).get(),
                            vertexes: [{
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }, {
                                x: f,
                                y: g,
                                z: b,
                            }],
                            enabled: d.back.visible && !d.top.visible,
                        }, {
                            fill: c.color(d.back.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: q,
                            }, {
                                x: k,
                                y: p,
                                z: q,
                            }, {
                                x: f,
                                y: g,
                                z: b,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }],
                            enabled: d.back.visible && !d.left.visible,
                        }, {
                            fill: c.color(d.back.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }],
                            enabled: d.back.visible && !d.right.visible,
                        }, {
                            fill: c.color(d.back.color).get(),
                            vertexes: [{
                                x: f,
                                y: g,
                                z: b,
                            }, {
                                x: e,
                                y: g,
                                z: b,
                            }, {
                                x: e,
                                y: h,
                                z: b,
                            }, {
                                x: f,
                                y: h,
                                z: b,
                            }],
                            enabled: d.back.visible,
                        }, {
                            fill: c.color(d.back.color).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: q,
                            }, {
                                x: l,
                                y: m,
                                z: q,
                            }, {
                                x: l,
                                y: p,
                                z: q,
                            }, {
                                x: k,
                                y: p,
                                z: q,
                            }],
                            enabled: d.back.visible,
                        }],
                    });
                    this.frameShapes.front[r]({
                        class: 'highcharts-3d-frame highcharts-3d-frame-front',
                        zIndex: d.front.frontFacing ? -1E3 : 1E3,
                        faces: [{
                            fill: c.color(d.front.color).brighten(0.1).get(),
                            vertexes: [{
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }],
                            enabled: d.front.visible && !d.bottom.visible,
                        }, {
                            fill: c.color(d.front.color).brighten(0.1).get(),
                            vertexes: [{
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: k,
                                y: p,
                                z: n,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }, {
                                x: e,
                                y: g,
                                z: 0,
                            }],
                            enabled: d.front.visible && !d.top.visible,
                        }, {
                            fill: c.color(d.front.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: k,
                                y: p,
                                z: n,
                            }, {
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }],
                            enabled: d.front.visible && !d.left.visible,
                        }, {
                            fill: c.color(d.front.color).brighten(-0.1).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: l,
                                y: p,
                                z: n,
                            }, {
                                x: e,
                                y: g,
                                z: 0,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }],
                            enabled: d.front.visible && !d.right.visible,
                        }, {
                            fill: c.color(d.front.color).get(),
                            vertexes: [{
                                x: e,
                                y: g,
                                z: 0,
                            }, {
                                x: f,
                                y: g,
                                z: 0,
                            }, {
                                x: f,
                                y: h,
                                z: 0,
                            }, {
                                x: e,
                                y: h,
                                z: 0,
                            }],
                            enabled: d.front.visible,
                        }, {
                            fill: c.color(d.front.color).get(),
                            vertexes: [{
                                x: l,
                                y: m,
                                z: n,
                            }, {
                                x: k,
                                y: m,
                                z: n,
                            }, {
                                x: k,
                                y: p,
                                z: n,
                            }, {
                                x: l,
                                y: p,
                                z: n,
                            }],
                            enabled: d.front.visible,
                        }],
                    });
                }
            }

            function n() {
                this.styledMode && (this.renderer.definition({
                    tagName: 'style',
                    textContent: '.highcharts-3d-top{filter: url(#highcharts-brighter)}\n.highcharts-3d-side{filter: url(#highcharts-darker)}\n',
                }), [{
                    name: 'darker',
                    slope: 0.6,
                }, {
                    name: 'brighter',
                    slope: 1.4,
                }].forEach(function (a) {
                    this.renderer.definition({
                        tagName: 'filter',
                        id: `highcharts-${a.name}`,
                        children: [{
                            tagName: 'feComponentTransfer',
                            children: [{
                                tagName: 'feFuncR',
                                type: 'linear',
                                slope: a.slope,
                            }, {
                                tagName: 'feFuncG',
                                type: 'linear',
                                slope: a.slope,
                            }, {
                                tagName: 'feFuncB',
                                type: 'linear',
                                slope: a.slope,
                            }],
                        }],
                    });
                }, this));
            }

            function q() {
                const a = this.options;
                this.is3d() && (a.series || []).forEach(d => {
                    (d.type || a.chart.type || a.chart.defaultSeriesType) === 'scatter' && (d.type = 'scatter3d');
                });
            }

            function C() {
                const a = this.options.chart.options3d;

                if (this.chart3d && this.is3d()) {
                    a && (a.alpha = a.alpha % 360 + (a.alpha >= 0 ? 0 : 360), a.beta = a.beta % 360 + (a.beta >= 0 ? 0 : 360));
                    const d = this.inverted;
                    const c = this.clipBox;
                    const b = this.margin;
                    c[d ? 'y' : 'x'] = -(b[3] || 0);
                    c[d ? 'x' : 'y'] = -(b[0] || 0);
                    c[d ? 'height' : 'width'] = this.chartWidth + (b[3] || 0) + (b[1] || 0);
                    c[d ? 'width' : 'height'] = this.chartHeight + (b[0] || 0) + (b[2] || 0);
                    this.scale3d = 1;
                    !0 === a.fitToPlot && (this.scale3d = this.chart3d.getScale(a.depth));
                    this.chart3d.frame3d = this.chart3d.get3dFrame();
                }
            }

            function u() {
                this.is3d() && (this.isDirtyBox = !0);
            }

            function w() {
                this.chart3d && this.is3d() && (this.chart3d.frame3d = this.chart3d.get3dFrame());
            }

            function e() {
                this.chart3d || (this.chart3d = new G(this));
            }

            function x(a) {
                return this.is3d() || a.apply(this, [].slice.call(arguments, 1));
            }

            function J(a) {
                let d = this.series.length;
                if (this.is3d()) for (; d--;) a = this.series[d], a.translate(), a.render(); else a.call(this);
            }

            function l(a) {
                a.apply(this, [].slice.call(arguments, 1));
                this.is3d() && (this.container.className += ' highcharts-3d-chart');
            }

            var G = (function () {
                function d(a) {
                    this.frame3d = void 0;
                    this.chart = a;
                }

                d.prototype.get3dFrame = function () {
                    const d = this.chart;
                    let c = d.options.chart.options3d;
                    let b = c.frame;
                    let e = d.plotLeft;
                    const g = d.plotLeft + d.plotWidth;
                    const h = d.plotTop;
                    let k = d.plotTop + d.plotHeight;
                    const l = c.depth;
                    let p = function (a) {
                        a = r(a, d);
                        return a > 0.5 ? 1 : a < -0.5 ? -1 : 0;
                    };
                    let m = p([{
                        x: e,
                        y: k,
                        z: l,
                    }, {
                        x: g,
                        y: k,
                        z: l,
                    }, {
                        x: g,
                        y: k,
                        z: 0,
                    }, {
                        x: e,
                        y: k,
                        z: 0,
                    }]);
                    let n = p([{
                        x: e,
                        y: h,
                        z: 0,
                    }, {
                        x: g,
                        y: h,
                        z: 0,
                    }, {
                        x: g,
                        y: h,
                        z: l,
                    }, {
                        x: e,
                        y: h,
                        z: l,
                    }]);
                    let q = p([{
                        x: e,
                        y: h,
                        z: 0,
                    }, {
                        x: e,
                        y: h,
                        z: l,
                    }, {
                        x: e,
                        y: k,
                        z: l,
                    }, {
                        x: e,
                        y: k,
                        z: 0,
                    }]);
                    let t = p([{
                        x: g,
                        y: h,
                        z: l,
                    }, {
                        x: g,
                        y: h,
                        z: 0,
                    }, {
                        x: g,
                        y: k,
                        z: 0,
                    }, {
                        x: g,
                        y: k,
                        z: l,
                    }]);
                    const C = p([{
                        x: e,
                        y: k,
                        z: 0,
                    }, {
                        x: g,
                        y: k,
                        z: 0,
                    }, {
                        x: g,
                        y: h,
                        z: 0,
                    }, {
                        x: e,
                        y: h,
                        z: 0,
                    }]);

                    p = p([{
                        x: e,
                        y: h,
                        z: l,
                    }, {
                        x: g,
                        y: h,
                        z: l,
                    }, {
                        x: g,
                        y: k,
                        z: l,
                    }, {
                        x: e,
                        y: k,
                        z: l,
                    }]);
                    let u = !1;
                    let F = !1;
                    let w = !1;
                    let y = !1;
                    [].concat(d.xAxis, d.yAxis, d.zAxis).forEach(a => {
                        a && (a.horiz ? a.opposite ? F = !0 : u = !0 : a.opposite ? y = !0 : w = !0);
                    });

                    const x = function (d, b, c) {
                        for (var e = ['size', 'color', 'visible'], f = {}, g = 0; g < e.length; g++) {
                            for (let h = e[g], k = 0; k < d.length; k++) {
                                if (typeof d[k] === 'object') {
                                    const l = d[k][h];

                                    if (typeof l !== 'undefined' && l !== null) {
                                        f[h] = l;
                                        break;
                                    }
                                }
                            }
                        }

                        d = c;
                        !0 === f.visible || !1 === f.visible ? d = f.visible : f.visible === 'auto' && (d = b > 0);
                        return {
                            size: a(f.size, 1),
                            color: a(f.color, 'none'),
                            frontFacing: b > 0,
                            visible: d,
                        };
                    };

                    b = {
                        axes: {},
                        bottom: x([b.bottom, b.top, b], m, u),
                        top: x([b.top, b.bottom, b], n, F),
                        left: x([b.left, b.right, b.side, b], q, w),
                        right: x([b.right, b.left, b.side, b], t, y),
                        back: x([b.back, b.front, b], p, !0),
                        front: x([b.front, b.back, b], C, !1),
                    };
                    c.axisLabelPosition === 'auto' ? (t = function (a, d) {
                        return a.visible !== d.visible || a.visible && d.visible && a.frontFacing !== d.frontFacing;
                    }, c = [], t(b.left, b.front) && c.push({
                        y: (h + k) / 2,
                        x: e,
                        z: 0,
                        xDir: {
                            x: 1,
                            y: 0,
                            z: 0,
                        },
                    }), t(b.left, b.back) && c.push({
                        y: (h + k) / 2,
                        x: e,
                        z: l,
                        xDir: {
                            x: 0,
                            y: 0,
                            z: -1,
                        },
                    }), t(b.right, b.front) && c.push({
                        y: (h + k) / 2,
                        x: g,
                        z: 0,
                        xDir: {
                            x: 0,
                            y: 0,
                            z: 1,
                        },
                    }), t(b.right, b.back) && c.push({
                        y: (h + k) / 2,
                        x: g,
                        z: l,
                        xDir: {
                            x: -1,
                            y: 0,
                            z: 0,
                        },
                    }), m = [], t(b.bottom, b.front) && m.push({
                        x: (e + g) / 2,
                        y: k,
                        z: 0,
                        xDir: {
                            x: 1,
                            y: 0,
                            z: 0,
                        },
                    }), t(b.bottom, b.back) && m.push({
                        x: (e + g) / 2,
                        y: k,
                        z: l,
                        xDir: {
                            x: -1,
                            y: 0,
                            z: 0,
                        },
                    }), n = [], t(b.top, b.front) && n.push({
                        x: (e + g) / 2,
                        y: h,
                        z: 0,
                        xDir: {
                            x: 1,
                            y: 0,
                            z: 0,
                        },
                    }), t(b.top, b.back) && n.push({
                        x: (e + g) / 2,
                        y: h,
                        z: l,
                        xDir: {
                            x: -1,
                            y: 0,
                            z: 0,
                        },
                    }), q = [], t(b.bottom, b.left) && q.push({
                        z: (0 + l) / 2,
                        y: k,
                        x: e,
                        xDir: {
                            x: 0,
                            y: 0,
                            z: -1,
                        },
                    }), t(b.bottom, b.right) && q.push({
                        z: (0 + l) / 2,
                        y: k,
                        x: g,
                        xDir: {
                            x: 0,
                            y: 0,
                            z: 1,
                        },
                    }), k = [], t(b.top, b.left) && k.push({
                        z: (0 + l) / 2,
                        y: h,
                        x: e,
                        xDir: {
                            x: 0,
                            y: 0,
                            z: -1,
                        },
                    }), t(b.top, b.right) && k.push({
                        z: (0 + l) / 2,
                        y: h,
                        x: g,
                        xDir: {
                            x: 0,
                            y: 0,
                            z: 1,
                        },
                    }), e = function (a, b, c) {
                        if (a.length === 0) return null;
                        if (a.length === 1) return a[0];

                        for (var e = 0, g = f(a, d, !1), h = 1; h < g.length; h++) c * g[h][b] > c * g[e][b] ? e = h : c * g[h][b] === c * g[e][b] && g[h].z < g[e].z && (e = h);

                        return a[e];
                    }, b.axes = {
                        y: {
                            left: e(c, 'x', -1),
                            right: e(c, 'x', 1),
                        },
                        x: {
                            top: e(n, 'y', -1),
                            bottom: e(m, 'y', 1),
                        },
                        z: {
                            top: e(k, 'y', -1),
                            bottom: e(q, 'y', 1),
                        },
                    }) : b.axes = {
                        y: {
                            left: {
                                x: e,
                                z: 0,
                                xDir: {
                                    x: 1,
                                    y: 0,
                                    z: 0,
                                },
                            },
                            right: {
                                x: g,
                                z: 0,
                                xDir: {
                                    x: 0,
                                    y: 0,
                                    z: 1,
                                },
                            },
                        },
                        x: {
                            top: {
                                y: h,
                                z: 0,
                                xDir: {
                                    x: 1,
                                    y: 0,
                                    z: 0,
                                },
                            },
                            bottom: {
                                y: k,
                                z: 0,
                                xDir: {
                                    x: 1,
                                    y: 0,
                                    z: 0,
                                },
                            },
                        },
                        z: {
                            top: {
                                x: w ? g : e,
                                y: h,
                                xDir: w ? {
                                    x: 0,
                                    y: 0,
                                    z: 1,
                                } : {
                                    x: 0,
                                    y: 0,
                                    z: -1,
                                },
                            },
                            bottom: {
                                x: w ? g : e,
                                y: k,
                                xDir: w ? {
                                    x: 0,
                                    y: 0,
                                    z: 1,
                                } : {
                                    x: 0,
                                    y: 0,
                                    z: -1,
                                },
                            },
                        },
                    };
                    return b;
                };

                d.prototype.getScale = function (a) {
                    const d = this.chart;
                    const b = d.plotLeft;
                    const c = d.plotWidth + b;
                    const e = d.plotTop;
                    const g = d.plotHeight + e;
                    const h = b + d.plotWidth / 2;
                    const k = e + d.plotHeight / 2;
                    let l = Number.MAX_VALUE;
                    let p = -Number.MAX_VALUE;
                    let m = Number.MAX_VALUE;
                    let n = -Number.MAX_VALUE;
                    let q = 1;
                    let t = [{
                        x: b,
                        y: e,
                        z: 0,
                    }, {
                        x: b,
                        y: e,
                        z: a,
                    }];
                    [0, 1].forEach(a => {
                        t.push({
                            x: c,
                            y: t[a].y,
                            z: t[a].z,
                        });
                    });
                    [0, 1, 2, 3].forEach(a => {
                        t.push({
                            x: t[a].x,
                            y: g,
                            z: t[a].z,
                        });
                    });
                    t = f(t, d, !1);
                    t.forEach(a => {
                        l = Math.min(l, a.x);
                        p = Math.max(p, a.x);
                        m = Math.min(m, a.y);
                        n = Math.max(n, a.y);
                    });
                    b > l && (q = Math.min(q, 1 - Math.abs((b + h) / (l + h)) % 1));
                    c < p && (q = Math.min(q, (c - h) / (p - h)));
                    e > m && (q = m < 0 ? Math.min(q, (e + k) / (-m + e + k)) : Math.min(q, 1 - (e + k) / (m + k) % 1));
                    g < n && (q = Math.min(q, Math.abs((g - k) / (n - k))));
                    return q;
                };

                return d;
            }());

            b.Composition = G;
            b.defaultOptions = {
                chart: {
                    options3d: {
                        enabled: !1,
                        alpha: 0,
                        beta: 0,
                        depth: 100,
                        fitToPlot: !0,
                        viewDistance: 25,
                        axisLabelPosition: null,
                        frame: {
                            visible: 'default',
                            size: 1,
                            bottom: {},
                            top: {},
                            left: {},
                            right: {},
                            back: {},
                            front: {},
                        },
                    },
                },
            };

            b.compose = function (a, f) {
                const p = a.prototype;
                f = f.prototype;

                p.is3d = function () {
                    return this.options.chart.options3d && this.options.chart.options3d.enabled;
                };

                p.propsRequireDirtyBox.push('chart.options3d');
                p.propsRequireUpdateSeries.push('chart.options3d');

                f.matrixSetter = function () {
                    if (this.pos < 1 && (y(this.start) || y(this.end))) {
                        const a = this.start || [1, 0, 0, 1, 0, 0];
                        const d = this.end || [1, 0, 0, 1, 0, 0];
                        var b = [];

                        for (let c = 0; c < 6; c++) b.push(this.pos * d[c] + (1 - this.pos) * a[c]);
                    } else b = this.end;

                    this.elem.attr(this.prop, b, null, !0);
                };

                k(!0, m, b.defaultOptions);
                A(a, 'init', e);
                A(a, 'addSeries', d);
                A(a, 'afterDrawChartBox', h);
                A(a, 'afterGetContainer', n);
                A(a, 'afterInit', q);
                A(a, 'afterSetChartSize', C);
                A(a, 'beforeRedraw', u);
                A(a, 'beforeRender', w);
                g(c.Chart.prototype, 'isInsidePlot', x);
                g(a, 'renderSeries', J);
                g(a, 'setClassName', l);
            };
        }(C || (C = {})));

        C.compose(w, J);
        n.ZChartComposition.compose(w);
        u.compose(b);
        '';
        return C;
    });
    G(b, 'Core/Series/Series3D.js', [b['Core/Globals.js'], b['Extensions/Math3D.js'], b['Core/Utilities.js']], (b, u, w) => {
        const y = u.perspective;
        u = w.addEvent;
        const c = w.pick;
        u(b.Series, 'afterTranslate', function () {
            this.chart.is3d() && this.translate3dPoints();
        });

        b.Series.prototype.translate3dPoints = function () {
            let b = this.chart;
            let h = c(this.zAxis, b.options.zAxis[0]);
            const q = [];
            let n;

            for (n = 0; n < this.data.length; n++) {
                var f = this.data[n];

                if (h && h.translate) {
                    const r = h.logarithmic && h.val2lin ? h.val2lin(f.z) : f.z;
                    f.plotZ = h.translate(r);
                    f.isInside = f.isInside ? r >= h.min && r <= h.max : !1;
                } else f.plotZ = 0;

                f.axisXpos = f.plotX;
                f.axisYpos = f.plotY;
                f.axisZpos = f.plotZ;
                q.push({
                    x: f.plotX,
                    y: f.plotY,
                    z: f.plotZ,
                });
            }

            b = y(q, b, !0);

            for (n = 0; n < this.data.length; n++) f = this.data[n], h = b[n], f.plotX = h.x, f.plotY = h.y, f.plotZ = h.z;
        };
    });
    G(b, 'Series/Column3DSeries.js', [b['Core/Series/Series.js'], b['Core/Globals.js'], b['Extensions/Math3D.js'], b['Extensions/Stacking.js'], b['Core/Utilities.js']], (b, u, w, J, c) => {
        function x(b, a) {
            const c = b.series;
            const f = {};
            let d;
            let h = 1;
            c.forEach(b => {
                d = r(b.options.stack, a ? 0 : c.length - 1 - b.index);
                f[d] ? f[d].series.push(b) : (f[d] = {
                    series: [b],
                    position: h,
                }, h++);
            });
            f.totalStacks = h + 1;
            return f;
        }

        function h(b) {
            const a = b.apply(this, [].slice.call(arguments, 1));
            this.chart.is3d && this.chart.is3d() && (a.stroke = this.options.edgeColor || a.fill, a['stroke-width'] = r(this.options.edgeWidth, 1));
            return a;
        }

        function q(b, a, c) {
            const f = this.chart.is3d && this.chart.is3d();
            f && (this.options.inactiveOtherPoints = !0);
            b.call(this, a, c);
            f && (this.options.inactiveOtherPoints = !1);
        }

        function n(b) {
            for (var a = [], c = 1; c < arguments.length; c++) a[c - 1] = arguments[c];

            return this.series.chart.is3d() ? this.graphic && this.graphic.element.nodeName !== 'g' : b.apply(this, a);
        }

        const f = w.perspective;
        w = c.addEvent;
        var r = c.pick;
        c = c.wrap;
        const m = u.Series;
        const A = b.seriesTypes.column.prototype;
        const y = u.svg;
        c(A, 'translate', function (b) {
            b.apply(this, [].slice.call(arguments, 1));
            this.chart.is3d() && this.translate3dShapes();
        });
        c(m.prototype, 'justifyDataLabel', function (b) {
            return arguments[2].outside3dPlot ? !1 : b.apply(this, [].slice.call(arguments, 1));
        });

        A.translate3dPoints = function () {};

        A.translate3dShapes = function () {
            const b = this;
            const a = b.chart;
            const c = b.options;
            const h = c.depth;
            let d = (c.stacking ? c.stack || 0 : b.index) * (h + (c.groupZPadding || 1));
            let p = b.borderWidth % 2 ? 0.5 : 0;
            let m;
            a.inverted && !b.yAxis.reversed && (p *= -1);
            !1 !== c.grouping && (d = 0);
            d += c.groupZPadding || 1;
            b.data.forEach(c => {
                c.outside3dPlot = null;

                if (c.y !== null) {
                    const g = c.shapeArgs;
                    let k = c.tooltipPos;
                    let n;
                    [['x', 'width'], ['y', 'height']].forEach(a => {
                        n = g[a[0]] - p;
                        n < 0 && (g[a[1]] += g[a[0]] + p, g[a[0]] = -p, n = 0);
                        n + g[a[1]] > b[`${a[0]}Axis`].len && g[a[1]] !== 0 && (g[a[1]] = b[`${a[0]}Axis`].len - g[a[0]]);

                        if (g[a[1]] !== 0 && (g[a[0]] >= b[`${a[0]}Axis`].len || g[a[0]] + g[a[1]] <= p)) {
                            for (const d in g) g[d] = 0;

                            c.outside3dPlot = !0;
                        }
                    });
                    c.shapeType === 'rect' && (c.shapeType = 'cuboid');
                    g.z = d;
                    g.depth = h;
                    g.insidePlotArea = !0;
                    m = {
                        x: g.x + g.width / 2,
                        y: g.y,
                        z: d + h / 2,
                    };
                    a.inverted && (m.x = g.height, m.y = c.clientX);
                    c.plot3d = f([m], a, !0, !1)[0];
                    k = f([{
                        x: k[0],
                        y: k[1],
                        z: d + h / 2,
                    }], a, !0, !1)[0];
                    c.tooltipPos = [k.x, k.y];
                }
            });
            b.z = d;
        };

        c(A, 'animate', function (b) {
            if (this.chart.is3d()) {
                const a = arguments[1];
                const c = this.yAxis;
                const f = this;
                const d = this.yAxis.reversed;
                y && (a ? f.data.forEach(a => {
                    a.y !== null && (a.height = a.shapeArgs.height, a.shapey = a.shapeArgs.y, a.shapeArgs.height = 1, d || (a.shapeArgs.y = a.stackY ? a.plotY + c.translate(a.stackY) : a.plotY + (a.negative ? -a.height : a.height)));
                }) : (f.data.forEach(a => {
                    a.y !== null && (a.shapeArgs.height = a.height, a.shapeArgs.y = a.shapey, a.graphic && a.graphic.animate(a.shapeArgs, f.options.animation));
                }), this.drawDataLabels()));
            } else b.apply(this, [].slice.call(arguments, 1));
        });
        c(A, 'plotGroup', function (b, a, c, f, d, h) {
            a !== 'dataLabelsGroup' && this.chart.is3d() && (this[a] && delete this[a], h && (this.chart.columnGroup || (this.chart.columnGroup = this.chart.renderer.g('columnGroup').add(h)), this[a] = this.chart.columnGroup, this.chart.columnGroup.attr(this.getPlotBox()), this[a].survive = !0, a === 'group' || a === 'markerGroup')) && (arguments[3] = 'visible');
            return b.apply(this, Array.prototype.slice.call(arguments, 1));
        });
        c(A, 'setVisible', function (b, a) {
            const c = this;
            let f;
            c.chart.is3d() && c.data.forEach(b => {
                f = (b.visible = b.options.visible = a = typeof a === 'undefined' ? !r(c.visible, b.visible) : a) ? 'visible' : 'hidden';
                c.options.data[c.data.indexOf(b)] = b.options;
                b.graphic && b.graphic.attr({
                    visibility: f,
                });
            });
            b.apply(this, Array.prototype.slice.call(arguments, 1));
        });
        A.handle3dGrouping = !0;
        w(m, 'afterInit', function () {
            if (this.chart.is3d() && this.handle3dGrouping) {
                const b = this.options;
                let a = b.grouping;
                let c = b.stacking;
                const f = r(this.yAxis.options.reversedStacks, !0);
                let d = 0;

                if (typeof a === 'undefined' || a) {
                    a = x(this.chart, c);
                    d = b.stack || 0;

                    for (c = 0; c < a[d].series.length && a[d].series[c] !== this; c++);

                    d = 10 * (a.totalStacks - a[d].position) + (f ? c : -c);
                    this.xAxis.reversed || (d = 10 * a.totalStacks - d);
                }

                b.depth = b.depth || 25;
                this.z = this.z || 0;
                b.zIndex = d;
            }
        });
        c(A, 'pointAttribs', h);
        c(A, 'setState', q);
        c(A.pointClass.prototype, 'hasNewShapeType', n);
        b.seriesTypes.columnRange && (u = b.seriesTypes.columnrange.prototype, c(u, 'pointAttribs', h), c(u, 'setState', q), c(u.pointClass.prototype, 'hasNewShapeType', n), u.plotGroup = A.plotGroup, u.setVisible = A.setVisible);
        c(m.prototype, 'alignDataLabel', function (b, a, c, h, d) {
            const g = this.chart;
            h.outside3dPlot = a.outside3dPlot;

            if (g.is3d() && this.is('column')) {
                let k = this.options;
                const m = r(h.inside, !!this.options.stacking);
                const n = g.options.chart.options3d;
                const q = a.pointWidth / 2 || 0;
                k = {
                    x: d.x + q,
                    y: d.y,
                    z: this.z + k.depth / 2,
                };
                g.inverted && (m && (d.width = 0, k.x += a.shapeArgs.height / 2), n.alpha >= 90 && n.alpha <= 270 && (k.y += a.shapeArgs.width));
                k = f([k], g, !0, !1)[0];
                d.x = k.x - q;
                d.y = a.outside3dPlot ? -9E9 : k.y;
            }

            b.apply(this, [].slice.call(arguments, 1));
        });
        c(J.prototype, 'getStackBox', function (c, a, g, h, d, m, n, q) {
            const k = c.apply(this, [].slice.call(arguments, 1));

            if (a.is3d() && g.base) {
                let p = +g.base.split(',')[0];
                let r = a.series[p];
                p = a.options.chart.options3d;
                r && r instanceof b.seriesTypes.column && (r = {
                    x: k.x + (a.inverted ? n : m / 2),
                    y: k.y,
                    z: r.options.depth / 2,
                }, a.inverted && (k.width = 0, p.alpha >= 90 && p.alpha <= 270 && (r.y += m)), r = f([r], a, !0, !1)[0], k.x = r.x - m / 2, k.y = r.y);
            }

            return k;
        });
    });
    G(b, 'Series/Pie3DSeries.js', [b['Core/Series/Series.js'], b['Core/Globals.js'], b['Core/Utilities.js']], (b, u, w) => {
        const y = u.deg2rad;
        const c = u.svg;
        const x = w.pick;
        u = w.wrap;
        b = b.seriesTypes;
        u(b.pie.prototype, 'translate', function (b) {
            b.apply(this, [].slice.call(arguments, 1));

            if (this.chart.is3d()) {
                const c = this;
                const h = c.options;
                const f = h.depth || 0;
                const r = c.chart.options.chart.options3d;
                const m = r.alpha;
                const u = r.beta;
                let w = h.stacking ? (h.stack || 0) * f : c._i * f;
                w += f / 2;
                !1 !== h.grouping && (w = 0);
                c.data.forEach(b => {
                    let a = b.shapeArgs;
                    b.shapeType = 'arc3d';
                    a.z = w;
                    a.depth = 0.75 * f;
                    a.alpha = m;
                    a.beta = u;
                    a.center = c.center;
                    a = (a.end + a.start) / 2;
                    b.slicedTranslation = {
                        translateX: Math.round(Math.cos(a) * h.slicedOffset * Math.cos(m * y)),
                        translateY: Math.round(Math.sin(a) * h.slicedOffset * Math.cos(m * y)),
                    };
                });
            }
        });
        u(b.pie.prototype.pointClass.prototype, 'haloPath', function (b) {
            const c = arguments;
            return this.series.chart.is3d() ? [] : b.call(this, c[1]);
        });
        u(b.pie.prototype, 'pointAttribs', function (b, c, n) {
            b = b.call(this, c, n);
            n = this.options;
            this.chart.is3d() && !this.chart.styledMode && (b.stroke = n.edgeColor || c.color || this.color, b['stroke-width'] = x(n.edgeWidth, 1));
            return b;
        });
        u(b.pie.prototype, 'drawDataLabels', function (b) {
            if (this.chart.is3d()) {
                const c = this.chart.options.chart.options3d;
                this.data.forEach(b => {
                    const f = b.shapeArgs;
                    const h = f.r;
                    const m = (f.start + f.end) / 2;
                    b = b.labelPosition;
                    const n = b.connectorPosition;
                    const q = -h * (1 - Math.cos((f.alpha || c.alpha) * y)) * Math.sin(m);
                    const k = h * (Math.cos((f.beta || c.beta) * y) - 1) * Math.cos(m);
                    [b.natural, n.breakAt, n.touchingSliceAt].forEach(a => {
                        a.x += k;
                        a.y += q;
                    });
                });
            }

            b.apply(this, [].slice.call(arguments, 1));
        });
        u(b.pie.prototype, 'addPoint', function (b) {
            b.apply(this, [].slice.call(arguments, 1));
            this.chart.is3d() && this.update(this.userOptions, !0);
        });
        u(b.pie.prototype, 'animate', function (b) {
            if (this.chart.is3d()) {
                let h = arguments[1];
                let n = this.options.animation;
                const f = this.center;
                const r = this.group;
                const m = this.markerGroup;
                c && (!0 === n && (n = {}), h ? (r.oldtranslateX = x(r.oldtranslateX, r.translateX), r.oldtranslateY = x(r.oldtranslateY, r.translateY), h = {
                    translateX: f[0],
                    translateY: f[1],
                    scaleX: 0.001,
                    scaleY: 0.001,
                }, r.attr(h), m && (m.attrSetters = r.attrSetters, m.attr(h))) : (h = {
                    translateX: r.oldtranslateX,
                    translateY: r.oldtranslateY,
                    scaleX: 1,
                    scaleY: 1,
                }, r.animate(h, n), m && m.animate(h, n)));
            } else b.apply(this, [].slice.call(arguments, 1));
        });
    });
    G(b, 'Series/Scatter3DSeries.js', [b['Core/Series/Series.js'], b['Extensions/Math3D.js'], b['Core/Series/Point.js']], (b, u, w) => {
        const y = b.seriesTypes;
        const c = u.pointCameraDistance;
        b.seriesType('scatter3d', 'scatter', {
            tooltip: {
                pointFormat: 'x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>z: <b>{point.z}</b><br/>',
            },
        }, {
            pointAttribs(b) {
                const h = y.scatter.prototype.pointAttribs.apply(this, arguments);
                this.chart.is3d() && b && (h.zIndex = c(b, this.chart));
                return h;
            },
            axisTypes: ['xAxis', 'yAxis', 'zAxis'],
            pointArrayMap: ['x', 'y', 'z'],
            parallelArrays: ['x', 'y', 'z'],
            directTouch: !0,
        }, {
            applyOptions() {
                w.prototype.applyOptions.apply(this, arguments);
                typeof this.z === 'undefined' && (this.z = 0);
                return this;
            },
        });
        '';
    });
    G(b, 'masters/highcharts-3d.src.js', [], () => {});
}));

import axios from 'axios';
import { Language } from '../helpers/request-header';

if (window.location.search === '?mainnet') {
    localStorage.setItem('networkTicker', 'btcu');
} else if (window.location.search === '?orion') {
    localStorage.setItem('networkTicker', 'btcu_testnet');
} else if (localStorage.getItem('networkTicker') === null) {
    localStorage.setItem('networkTicker', 'btcu');
}

const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
const BASE_URL = process.env.REACT_APP_API_URL;
const networkTicker = localStorage.getItem('networkTicker');
export default class GetService {
    getResource = async url => {
        const user = JSON.parse(localStorage.getItem('user'));
        const getToken = () => user.token;
        const Authorization = user ? `Bearer ${getToken()}` : '';

        const response = await axios.get(url, {
            headers: {
                Authorization,
                language: await Language(),
            },
        });

        if (response.status !== 200) {
            throw new Error(
                `Could not fetch ${process.env.REACT_APP_API_URL}${url},
                received ${response.status}`,
            );
        }
        return response.data;
    };

    logout = () => {
        localStorage.removeItem('user');
        document.location.reload(true);
    };

    getMainPageInfo = async () => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getMainPageInfo`,
        );
        return res;
    };

    getLatestBlocks = async () => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getlatestblocks`,
        );
        return res;
    };

    getLatestTransactions = async () => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getlatesttransactions`,
        );
        return res;
    };

    getSearchQuery = async searchQuery => {
        const res = await this.getResource(`${BASE_URL}/${networkTicker}/${searchQuery}`);
        return res;
    };

    getBlockByIndex = async index => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getBlockByIndex/${index}`,
        );
        return res;
    };

    getTransactionInfo = async hash => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/gettransactioninfo/${hash}`,
        );
        return res;
    };

    getAddressInfo = async address => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getaddressinfo/${address}`,
        );
        return res;
    };

    getBlockByHash = async hash => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getblockbyhash/${hash}`,
        );
        return res;
    };

    getAllTransactions = async (itemsPerPage, pageNumber) => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getpaginatedtransactions/${itemsPerPage}/${pageNumber}`,
        );
        return res;
    };

    getAllBlocks = async (itemsPerPage, pageNumber) => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getpaginatedblocks/${itemsPerPage}/${pageNumber}`,
        );
        return res;
    };

    getTransactionsByAddress = async (itemsPerPage, pageNumber, address) => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getpaginatedtransactionsbyaddress/${itemsPerPage}/${pageNumber}/${address}`,
        );
        return res;
    };

    getTransactionsByBlock = async (itemsPerPage, pageNumber, blockId) => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getpaginatedtransactionsbyblock/${itemsPerPage}/${pageNumber}/${blockId}`,
        );
        return res;
    };

    getAddressChart = async address => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getaddresschart/${address}`,
        );
        return res;
    };

    getRichList = async (itemsPerPage, pageNumber) => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getpaginatedrichlist/${itemsPerPage}/${pageNumber}`,
        );
        return res;
    };

    getLatestSingleBlock = async () => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getsinglelatestblock`,
        );
        return res;
    };

    getWatchList = async (itemsPerPage, pageNumber) => {
        const res = await this.getResource(
            `${AUTH_URL}/watchlist/${networkTicker}/${itemsPerPage}/${pageNumber}`,
        );
        return res;
    };

    getPrivateNodeList = async (itemsPerPage, pageNumber) => {
        const res = await this.getResource(
            `${AUTH_URL}/privateNodeList/${networkTicker}/${itemsPerPage}/${pageNumber}`,
        );
        return res;
    };

    getTopStatistics = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/gettopstatistics/${timeframe}`,
        );
        return res;
    };

    getTopTransactions = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/gettoptransactions/${timeframe}`,
        );
        return res;
    };

    getTopMiners = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/gettopminers/${timeframe}`,
        );
        return res;
    };

    getTopNetwork = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/gettopnetwork/${timeframe}`,
        );
        return res;
    };

    getTransactionStatus = async hash => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/gettransactionstatus/${hash}`,
        );
        return res;
    };

    getSupplyChart = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getsupplychart/${timeframe}`,
        );
        return res;
    };

    getDailyTransactionsChart = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getdailytransactionschart/${timeframe}`,
        );
        return res;
    };

    getUniqueAddressesChart = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getuniqueaddresseschart/${timeframe}`,
        );
        return res;
    };

    getDifficultyChart = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getdifficultychart/${timeframe}`,
        );
        return res;
    };

    getTransactionFeeChart = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/gettransactionfeechart/${timeframe}`,
        );
        return res;
    };

    getPendingTransactionsChart = async timeframe => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getpendingtransactionschart/${timeframe}`,
        );
        return res;
    };

    getTxNotifier = async () => {
        const res = await this.getResource(`${AUTH_URL}/txNotifier`);
        return res;
    };

    getPendingTransctions = async (numItemsPerPage, currentPageNumber) => {
        const res = await this.getResource(
            `${BASE_URL}/${networkTicker}/getpaginatedpendingtransctions/${numItemsPerPage}/${currentPageNumber}`,
        );
        return res;
    };

    startCaptcha = async () => {
        const res = await this.getResource(`${AUTH_URL}/captcha`);
        return res;
    };
}

import React from 'react';
import Highcharts from 'highcharts/highstock';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import style from './analytics.module.scss';

const Analytics = ({ data }) => {
    const { t } = useTranslation();

    const options = {
        plotOptions: {
            series: {
                color: '#FF2911',
            },
        },
        rangeSelector: {
            selected: 1,
            inputEnabled: false,
            buttonTheme: {
                width: window.innerWidth > 499 ? 60 : 35,
            },
            labelStyle: {
                display: 'none',
            },
        },

        navigator: {
            handles: {
                symbols: ['doublearrow', 'doublearrow'],
                lineWidth: 1,
                width: 17,
                height: 17,
            },
        },
        series: [
            {
                name: t('balance'),
                data,
                tooltip: {
                    valueDecimals: 2,
                },
                type: 'area',
                threshold: null,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 1,
                        x2: 0,
                        y2: 0,
                    },
                    stops: [
                        [0, 'rgba(255, 41, 17, 0)'],
                        [1, 'rgba(255, 41, 17, 0.3)'],
                    ],
                },
            },
        ],
    };

    return (
        <div className={style.analytics}>
            <div className={style.highcharts}>
                <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={options} />
            </div>
        </div>
    );
};

Analytics.defaultProps = {
    data: [],
};

Analytics.propTypes = {
    data: PropTypes.instanceOf(Array),
};

export default Analytics;

import { GET_PENDING_TRANSACTIONS_CHART } from '../constants';

const getPendingTransactionsChartRequested = () => ({
    type: GET_PENDING_TRANSACTIONS_CHART.GET_PENDING_TRANSACTIONS_CHART_REQUEST,
});

const getPendingTransactionsChartLoaded = data => ({
    type: GET_PENDING_TRANSACTIONS_CHART.GET_PENDING_TRANSACTIONS_CHART_SUCCESS,
    payload: data,
});

const getPendingTransactionsChartError = error => ({
    type: GET_PENDING_TRANSACTIONS_CHART.GET_PENDING_TRANSACTIONS_CHART_FAILURE,
    payload: error,
});

const getPendingTransactionsChartAction = getService => timeframe => dispatch => {
    dispatch(getPendingTransactionsChartRequested());
    getService
        .getPendingTransactionsChart(timeframe)
        .then(data => {
            dispatch(getPendingTransactionsChartLoaded(data));
        })
        .catch(err => {
            dispatch(getPendingTransactionsChartError(err));
        });
};

export default getPendingTransactionsChartAction;

import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import withTitle from '@hoc/with-title';
import Pagination from '@layouts/paginations';
import Spinner from '@spinner/spinner';
import truncate from '@helpers/truncate-string';
import formatNumber from '@helpers/format-number';
import getAllTransactionsAction from '@actions/get-all-transactions.actions';
import withGetService from '@hoc/with-get-service';
import SettingsIcon from '@assets/images/icons/settings-icon';
import notFoundIcon from '@assets/images/icons/search-not-found.svg';
import { transactionPath, blockPath, addressPath } from '@constants';
import { compose } from '@utils';
import mobileWidth from '@helpers/mobile-width';
import ArrowRight from '@assets/images/icons/arrow-right';
import btcuTicker from '@assets/images/icons/btcu-ticker-icon.svg';
import plusIcon from '@assets/images/icons/plus-icon.svg';
import minusIcon from '@assets/images/icons/minus-icon.svg';
import style from './all-transactions.module.scss';

class AllTransactions extends Component {
    static defaultProps = {
        t: () => {},
        getAllTransactions: () => {},
        allTransactions: {},
        success: false,
        loading: false,
    };

    static propTypes = {
        t: PropTypes.func,
        getAllTransactions: PropTypes.func,
        allTransactions: PropTypes.object,
        success: PropTypes.bool,
        loading: PropTypes.bool,
    };

    state = {
        currentPageNumber: '',
        numItemsPerPage: '',
        data: [],
        totalCount: '',
        details: '',
        spinner: true,
    };

    componentDidMount() {
        this.loadData(10, 1);
    }

    componentDidUpdate(prevProps) {
        const {
            success,
            allTransactions: { currentPageNumber, numItemsPerPage, items, totalCount },
        } = this.props;

        if (success && success !== prevProps.success) {
            this.setState({
                currentPageNumber,
                numItemsPerPage,
                data: items,
                totalCount,
                spinner: false,
            });
        }
    }

    loadData = (itemsPerPage, numberPage) => {
        const { getAllTransactions } = this.props;
        getAllTransactions(itemsPerPage, numberPage);
    };

    changePagination = number => {
        const { numItemsPerPage } = this.state;
        this.loadData(numItemsPerPage, number);
    };

    records = records => {
        this.loadData(records, 1);
    };

    switchActiveTab = hash => {
        this.setState({
            details: hash,
        });
    };

    render() {
        const { t, loading } = this.props;

        const { currentPageNumber, numItemsPerPage, data, totalCount, spinner, details } = this.state;

        if (loading && spinner) {
            return <Spinner />;
        }

        if (!data.length) {
            return (
                <div className={style.notFoundIcon}>
                    <img src={notFoundIcon} alt="notFoundIcon" />
                    <p className={style.notFoundIcon__title}>{t('nothingFound')}</p>
                </div>
            );
        }

        return (
            <div className={style.allTransactions}>
                <h1 className={style.allTransactions__title}>{t('allTransactions')}</h1>
                <div className={style.table}>
                    {data.map((item, index) => {
                        const {
                            hash,
                            block_id,
                            time,
                            output_total,
                            fee,
                            from,
                            outputs = [],
                            is_stacking,
                            input_total,
                        } = item;

                        const getTime = new Date(+time);
                        const timeFromNow = moment(getTime).fromNow();

                        const isOpen = details === hash;

                        const detailsStyle = isOpen
                            ? classNames(style.details, style.detailsOpened)
                            : style.details;

                        const openInfo = (
                            <Fragment>
                                {from.length ? (
                                    <div
                                        className={style.table__row_dotWrapper}
                                        onClick={() => this.switchActiveTab(isOpen ? '' : hash)}
                                    >
                                        <div className={style.table__row_dot}>
                                            <img src={isOpen ? minusIcon : plusIcon} alt="icon" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className={style.table__row_dotWrapperDisabled}>
                                        <div className={style.table__row_dot}>
                                            <SettingsIcon />
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        );

                        return (
                            <Fragment key={index}>
                                <div className={style.table__row}>
                                    {openInfo}
                                    <div className={style.table__column1}>
                                        <p className={style.table__label}>{t('txnHash')}</p>
                                        <Link to={`${transactionPath}/${hash}`} className={style.table__link}>
                                            {`${mobileWidth() ? truncate(hash, 15) : hash}`}
                                        </Link>
                                    </div>
                                    <div className={style.table__column2}>
                                        <p className={style.table__label}>{t('block')}</p>
                                        <Link to={`${blockPath}/${block_id}`} className={style.table__link}>
                                            {block_id}
                                        </Link>
                                    </div>
                                    <div className={style.table__column3}>
                                        <p className={style.table__label}>{t('age')}</p>
                                        <p className={style.table__text}>{timeFromNow}</p>
                                    </div>
                                    <div className={style.table__column4}>
                                        <p className={style.table__label}>{t('value')}</p>
                                        <p className={style.table__text}>{formatNumber(output_total)}</p>
                                    </div>
                                    <div className={style.table__column5}>
                                        <p className={style.table__label}>Txn {t('fee')}</p>
                                        <p className={style.table__text}>
                                            {fee === null ? '-' : formatNumber(fee)}
                                        </p>
                                    </div>
                                </div>
                                <div className={detailsStyle}>
                                    <div className={style.details__wrapper}>
                                        <DetailsLeftSide t={t} from={from} input_total={input_total} />
                                        <DetailsRightSide t={t} outputs={outputs} is_stacking={is_stacking} />
                                    </div>
                                    <div className={style.details__total}>
                                        <p>
                                            <img src={btcuTicker} alt="btcuTicker" />
                                            {t('fee')}: {formatNumber(fee)} BTCU
                                        </p>
                                        <p>
                                            <img src={btcuTicker} alt="btcuTicker" />
                                            {t('total')}: {formatNumber(output_total)} BTCU
                                        </p>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
                <Pagination
                    numItemsPerPage={numItemsPerPage}
                    totalCount={totalCount}
                    currentPageNumber={currentPageNumber}
                    recordsOnClick={this.records}
                    paginationOnChange={this.changePagination}
                />
            </div>
        );
    }
}

const DetailsLeftSide = ({ t, from, input_total }) => (
    <div className={style.details__leftSide}>
        <div className={style.details__labelWrapper}>
            <p className={style.details__label}>{t('inputs')}</p>
            <p className={style.details__label}>{t('totalInput')}</p>
        </div>
        {from.map((item, index) => (
            <div key={index} className={style.details__row}>
                <Link to={`${addressPath}/${item}`}>{mobileWidth() ? truncate(item, 15) : item}</Link>
                <div className={style.details__row_value}>
                    <img src={btcuTicker} alt="btcuTicker" />
                    <p>{formatNumber(input_total)} BTCU</p>
                </div>
            </div>
        ))}
    </div>
);

const DetailsRightSide = ({ t, outputs, is_stacking }) => {
    if (!outputs.length) {
        return null;
    }

    return (
        <Fragment>
            <ArrowRight className={style.details__arrow} />
            <div className={style.details__rightSide}>
                <div className={style.details__labelWrapper}>
                    <p className={style.details__label}>{t('outputs')}</p>
                    <p className={style.details__label}>{t('outputTotal')}</p>
                </div>
                <div className={style.details__rowWrapper}>
                    {is_stacking ? (
                        <div className={style.details__row}>
                            <p className={style.details__row_staking}>{t('stakingReward')}</p>
                        </div>
                    ) : null}
                    {outputs.map((items, index) => {
                        const { recipient, value } = items;

                        return (
                            <div key={index} className={style.details__row}>
                                <Link to={`${addressPath}/${recipient}`}>
                                    {mobileWidth() ? truncate(recipient, 15) : recipient}
                                </Link>
                                <div className={style.details__row_value}>
                                    <img src={btcuTicker} alt="btcuTicker" />
                                    <p>{formatNumber(value)} BTCU</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
};

DetailsLeftSide.defaultProps = {
    t: () => {},
    from: [],
    input_total: '',
};

DetailsRightSide.defaultProps = {
    t: () => {},
    outputs: [],
    is_stacking: false,
};

DetailsLeftSide.propTypes = {
    t: PropTypes.func,
    from: PropTypes.instanceOf(Array),
    input_total: PropTypes.string,
};

DetailsRightSide.propTypes = {
    t: PropTypes.func,
    outputs: PropTypes.instanceOf(Array),
    is_stacking: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        allTransactions: { data: allTransactions, success, loading },
    } = state;

    return {
        allTransactions,
        success,
        loading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllTransactions: getAllTransactionsAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| All Transactions' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(AllTransactions);

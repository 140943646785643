import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs } from 'antd';
import classNames from 'classnames';
import getTopAccountsAction from '@actions/get-top-accounts.actions';
import getTopStatisticsAction from '@actions/get-top-statistics.actions';
import getTopTransactionsAction from '@actions/get-top-transactions.actions';
import getTopMinersAction from '@actions/get-top-miners.actions';
import getTopNetworkAction from '@actions/get-top-network.actions';
import withTitle from '@hoc/with-title';
import spinnerImg from '@assets/images/spinner.svg';
import withGetService from '@hoc/with-get-service';
import OverView from './overview';
import Miners from './miners';
import Network from './network';
import Transactions from './transactions';
import { compose } from '@utils';
import style from './top-statistics.module.scss';

const FILTER = {
    HOURS_24: '1',
    DAYS_3: '3',
    DAYS_7: '7',
};

const TAB = {
    ONE: 'OVERVIEW',
    TWO: 'TRANSACTIONS',
    THREE: 'MINERS',
    FOUR: 'FOUR',
};

class TopStatistics extends Component {
    static defaultProps = {
        t: () => {},
        getTopStatistics: () => {},
        getTopTransactions: () => {},
        getTopMiners: () => {},
        getTopNetwork: () => {},
        topStatistics: {},
        topTransactions: {},
        topMiners: [],
        topNetwork: [],
        topMinersLoading: false,
        topStatisticsLoading: false,
        topTransactionsLoading: false,
        topNetworkLoading: false,
    };

    static propTypes = {
        t: PropTypes.func,
        getTopStatistics: PropTypes.func,
        getTopTransactions: PropTypes.func,
        getTopMiners: PropTypes.func,
        getTopNetwork: PropTypes.func,
        topStatistics: PropTypes.object,
        topTransactions: PropTypes.object,
        topMiners: PropTypes.instanceOf(Array),
        topNetwork: PropTypes.instanceOf(Array),
        topMinersLoading: PropTypes.bool,
        topStatisticsLoading: PropTypes.bool,
        topTransactionsLoading: PropTypes.bool,
        topNetworkLoading: PropTypes.bool,
    };

    state = {
        filter: FILTER.HOURS_24,
        tab: TAB.ONE,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { filter } = this.state;

        if (filter !== prevState.filter) {
            this.loadData();
        }
    }

    loadData = () => {
        const { tab } = this.state;
        if (tab === TAB.ONE) {
            this.loadTopStatistics();
        }

        if (tab === TAB.TWO) {
            this.loadTopTransactions();
        }

        if (tab === TAB.THREE) {
            this.loadTopMiners();
        }

        if (tab === TAB.FOUR) {
            this.loadTopNetwork();
        }
    };

    loadTopStatistics = () => {
        const { getTopStatistics } = this.props;
        const { filter } = this.state;
        getTopStatistics(filter);
    };

    loadTopTransactions = () => {
        const { getTopTransactions } = this.props;
        const { filter } = this.state;
        getTopTransactions(filter);
    };

    loadTopMiners = () => {
        const { getTopMiners } = this.props;
        const { filter } = this.state;
        getTopMiners(filter);
    };

    loadTopNetwork = () => {
        const { getTopNetwork } = this.props;
        const { filter } = this.state;
        getTopNetwork(filter);
    };

    setFilter = filter => {
        this.setState({
            filter,
        });
    };

    changeTab = tab => {
        this.setState(
            {
                tab,
            },
            () => {
                this.loadData();
            },
        );
    };

    render() {
        const {
            t,
            topStatistics,
            topStatisticsLoading,
            topTransactions,
            topTransactionsLoading,
            topMinersLoading,
            topNetworkLoading,
            topNetwork,
            topMiners,
        } = this.props;
        const { filter, tab } = this.state;
        const { TabPane } = Tabs;
        const isLoading = topStatisticsLoading || topTransactionsLoading;

        const fliter_1_style = filter === FILTER.HOURS_24
            ? classNames(style.filter__item, style.filter__itemActive)
            : style.filter__item;

        const fliter_2_style = filter === FILTER.DAYS_3
            ? classNames(style.filter__item, style.filter__itemActive)
            : style.filter__item;

        const fliter_3_style = filter === FILTER.DAYS_7
            ? classNames(style.filter__item, style.filter__itemActive)
            : style.filter__item;

        const filterStyle = isLoading ? classNames(style.filter, style.filterLoading) : style.filter;

        const Filter = () => (
            <div className={filterStyle}>
                <div className={fliter_1_style} onClick={() => this.setFilter(FILTER.HOURS_24)}>
                    24 {t('hours')}
                </div>
                <div className={fliter_2_style} onClick={() => this.setFilter(FILTER.DAYS_3)}>
                    3 {t('days')}
                </div>
                <div className={fliter_3_style} onClick={() => this.setFilter(FILTER.DAYS_7)}>
                    7 {t('days')}
                </div>
            </div>
        );

        return (
            <div className={style.topStatistics}>
                <h1 className={style.topStatistics__title}>{t('topStatistics')}</h1>
                <Tabs defaultActiveKey={tab} onChange={this.changeTab}>
                    <TabPane tab={t('overview')} key={TAB.ONE}>
                        <Filter />
                        {topStatisticsLoading ? (
                            <div className={style.spinnerWrapper}>
                                <img className={style.spinner} src={spinnerImg} alt="spinner" />
                            </div>
                        ) : (
                            <OverView
                                topStatistics={topStatistics}
                                topStatisticsLoading={topStatisticsLoading}
                            />
                        )}
                    </TabPane>
                    <TabPane tab={t('transactions')} key={TAB.TWO}>
                        <Filter />
                        {topTransactionsLoading ? (
                            <div className={style.spinnerWrapper}>
                                <img className={style.spinner} src={spinnerImg} alt="spinner" />
                            </div>
                        ) : (
                            <Transactions topTransactions={topTransactions} />
                        )}
                    </TabPane>
                    <TabPane tab={t('miners')} key={TAB.THREE}>
                        <Filter />
                        {topMinersLoading ? (
                            <div className={style.spinnerWrapper}>
                                <img className={style.spinner} src={spinnerImg} alt="spinner" />
                            </div>
                        ) : (
                            <Miners topMiners={topMiners} />
                        )}
                    </TabPane>
                    <TabPane tab={t('network')} key={TAB.FOUR}>
                        <Filter />
                        {topNetworkLoading ? (
                            <div className={style.spinnerWrapper}>
                                <img className={style.spinner} src={spinnerImg} alt="spinner" />
                            </div>
                        ) : (
                            <Network topNetwork={topNetwork} />
                        )}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        topStatistics: { data: topStatistics, loading: topStatisticsLoading },
        topTransactions: { data: topTransactions, loading: topTransactionsLoading },
        topMiners: { data: topMiners, loading: topMinersLoading },
        topNetwork: { data: topNetwork, loading: topNetworkLoading },
    } = state;

    return {
        topStatistics,
        topStatisticsLoading,
        topTransactions,
        topTransactionsLoading,
        topMiners,
        topMinersLoading,
        topNetwork,
        topNetworkLoading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getTopAccounts: getTopAccountsAction(getService),
        getTopStatistics: getTopStatisticsAction(getService),
        getTopTransactions: getTopTransactionsAction(getService),
        getTopMiners: getTopMinersAction(getService),
        getTopNetwork: getTopNetworkAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Top Statistics' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(TopStatistics);

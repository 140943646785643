import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'rc-pagination';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import style from './leasing-pools.module.scss';

const LeasingPoolsView = ({ data }) => {
    const { t } = useTranslation();

    const textItemRender = (current, type, element) => {
        if (type === 'prev') {
            return t('previous');
        }
        if (type === 'next') {
            return t('next');
        }
        return element;
    };

    return (
        <Fragment>
            <h1 className={style.container__title}>{t('leasingPools')}</h1>
            <div className={style.table}>
                {data.map(item => {
                    const { rank, name, address, type, inLeasing, users, rating } = item;

                    return (
                        <div key={rank} className={style.table__row}>
                            <div className={style.table__column1}>
                                <p className={style.table__label}>{t('rank')}</p>
                                <p className={style.table__text}>{rank}</p>
                            </div>
                            <div className={style.table__column2}>
                                <p className={style.table__label}>{t('name')}</p>
                                <p className={style.table__text}>{name}</p>
                            </div>
                            <div className={style.table__column3}>
                                <p className={style.table__label}>{t('address')}</p>
                                <Link to="/" className={style.table__link}>
                                    {address}
                                </Link>
                            </div>
                            <div className={style.table__column4}>
                                <p className={style.table__label}>{t('type')}</p>
                                <p className={style.table__text}>{type}</p>
                            </div>
                            <div className={style.table__column6}>
                                <p className={style.table__label}>{t('inLeasing')}</p>
                                <p className={style.table__text}>{inLeasing}</p>
                            </div>
                            <div className={style.table__column7}>
                                <p className={style.table__label}>{t('users')}</p>
                                <p className={style.table__text}>{users}</p>
                            </div>
                            <div className={style.table__column9}>
                                <p className={style.table__label}>{t('rating')}</p>
                                <p className={style.table__text}>{rating}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <Pagination
                showTitle={false}
                total={100}
                itemRender={textItemRender}
                current={2}
                jumpNextIcon=". . ."
            />
        </Fragment>
    );
};

LeasingPoolsView.defaultProps = {
    data: [],
};

LeasingPoolsView.propTypes = {
    data: PropTypes.instanceOf(Array),
};

export default LeasingPoolsView;

import { GET_PRIVATE_NODE_LIST } from '../constants';

const getPrivateNodeListRequested = () => ({
    type: GET_PRIVATE_NODE_LIST.GET_PRIVATE_NODE_LIST_REQUEST,
});

const getPrivateNodeListLoaded = data => ({
    type: GET_PRIVATE_NODE_LIST.GET_PRIVATE_NODE_LIST_SUCCESS,
    payload: data,
});

const getPrivateNodeListError = error => ({
    type: GET_PRIVATE_NODE_LIST.GET_PRIVATE_NODE_LIST_FAILURE,
    payload: error,
});

const getPrivateNodeListAction = getService => (itemsPerPage, pageNumber) => dispatch => {
    dispatch(getPrivateNodeListRequested());
    getService
        .getPrivateNodeList(itemsPerPage, pageNumber)
        .then(data => {
            dispatch(getPrivateNodeListLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getPrivateNodeListError(err));
        });
};

export default getPrivateNodeListAction;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from '../../../../utils';
import { loginPath, profilePath } from '../../../../constants';
import Avatar from '../../../UI/avatar';
import ProfileIcon from '../../../assets/images/icons/profile-icon';
import style from './user-header.module.scss';

const UserHeader = ({ nameStyle, loggingIn, user: { avatar, login } }) => {
    const { t } = useTranslation();

    if (loggingIn) {
        const logoStyle = avatar
            ? classNames(style.header__rightSide_logoBorder, style.header__rightSide_logo)
            : style.header__rightSide_logo;

        return (
            <Link to={profilePath} className={style.header__logoWrapper}>
                <Avatar className={logoStyle} src={avatar} userName={login} />
                <p className={classNames(nameStyle, style.header__rightSide_name)}>
                    {login}
                </p>
            </Link>
        );
    }

    return (
        <Link to={loginPath} className={style.header__login}>
            <ProfileIcon className={style.header__login_icon} />
            <p>{t('signIn')}</p>
        </Link>
    );
};

const mapStateToProps = state => {
    const {
        authentication: { loggingIn, user },
    } = state;

    return {
        loggingIn,
        user,
    };
};

UserHeader.defaultProps = {
    nameStyle: '',
    loggingIn: false,
    user: {},
};

UserHeader.propTypes = {
    nameStyle: PropTypes.string,
    loggingIn: PropTypes.bool,
    user: PropTypes.object,
};

export default compose(connect(mapStateToProps))(UserHeader);

import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mobileSublinksActions } from '../../../../actions/mobile-sublinks.actions';
import SearchIcon from '../../../assets/images/icons/search-icon';
import CloseIcon from '../../../assets/images/icons/close-icon';
import withGetService from '../../../hoc/with-get-service';
import SearchFilter from '../../search-filter';
import { compose } from '../../../../utils';
import { openSearch, closeMenu } from '../../../../actions/drawer.actions';
import mobileWidth from '../../../../helpers/mobile-width';
import style from './header-search.module.scss';

class HeaderSearch extends Component {
    static defaultProps = {
        clearMobileLinks: () => {},
        closeDrawerMenu: () => {},
        openDrawerMenu: () => {},
        location: {},
        search: false,
    };

    static propTypes = {
        clearMobileLinks: PropTypes.func,
        closeDrawerMenu: PropTypes.func,
        openDrawerMenu: PropTypes.func,
        location: PropTypes.object,
        search: PropTypes.bool,
    };

    componentDidUpdate(prevProps) {
        const { closeDrawerMenu, location } = this.props;
        if (location !== prevProps.location) {
            closeDrawerMenu();
        }
    }

    componentWillUnmount() {
        const { closeDrawerMenu } = this.props;
        closeDrawerMenu();
    }

    toggleBurger = () => {
        const { search, closeDrawerMenu, openDrawerMenu } = this.props;
        if (search) {
            return closeDrawerMenu();
        }

        openDrawerMenu();
    };

    clearMobileLinks = () => {
        const { clearMobileLinks } = this.props;
        clearMobileLinks();
    };

    render() {
        const { search } = this.props;

        const drawerStyle = search ? style.drawer__opened : style.drawer__closed;

        if (search) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'inherit';
        }

        const drawerHeight = {
            height: `calc(${window.innerHeight}px - 19vw)`,
        };

        return (
            <Fragment>
                <div className={style.search} onClick={this.toggleBurger}>
                    {search ? (
                        <CloseIcon className={style.search__icon} />
                    ) : (
                        <SearchIcon className={style.search__icon} />
                    )}
                </div>
                <div className={drawerStyle} style={drawerHeight}>
                    <SearchFilter
                        button={(
                            <button className={style.searchContainer__button} type="submit">
                                <SearchIcon className={style.searchContainer__searchIcon} />
                            </button>
                        )}
                        truncateMainString={mobileWidth() ? 12 : 28}
                        truncateHashString={15}
                        inputClassName={style.searchContainer__input}
                        filterClassName={style.searchContainer}
                    />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        mobileSublinks: { data: mobileSublinks },
        drawer: { search },
    } = state;

    return {
        mobileSublinks,
        search,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        clearMobileLinks: () => mobileSublinksActions.clear(),
        openDrawerMenu: () => openSearch(),
        closeDrawerMenu: () => closeMenu(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    withGetService(),
    withRouter,
)(HeaderSearch);

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { compose } from '@utils';
import mobileWidth from '@helpers/mobile-width';
import truncate from '@helpers/truncate-string';
import formatNumber from '@helpers/format-number';
import { addressPath } from '@constants';
import style from './top-statistics.module.scss';

class Transactions extends Component {
    static defaultProps = {
        t: () => {},
        topTransactions: {},
    };

    static propTypes = {
        t: PropTypes.func,
        topTransactions: PropTypes.object,
    };

    state = {};

    render() {
        const {
            t,
            topTransactions: { topSenders, topReceivers, topTxSent, topTxReceived },
        } = this.props;

        return (
            <div className={style.container}>
                <div className={style.container__wrapper}>
                    <div className={style.container__wrapper_item}>
                        <h3 className={style.container__title}>
                            {t('top')} BTCU {t('senders')}
                        </h3>
                        <div className={style.transactions}>
                            {!topSenders?.length ? <p className={style.noData}>{t('noDataYet')}</p> : null}
                            {topSenders?.map((item, index) => {
                                const { address, value, percentage } = item;

                                return (
                                    <div key={index} className={style.transactions__row}>
                                        <div className={style.transactions__column1}>
                                            <p className={style.transactions__label}>{t('rank')}</p>
                                            <p className={style.transactions__text}>{index + 1}</p>
                                        </div>
                                        <div className={style.transactions__column2}>
                                            <p className={style.transactions__label}>{t('address')}</p>
                                            <Tooltip placement="topLeft" title={address}>
                                                <Link
                                                    to={`${addressPath}/${address}`}
                                                    className={style.transactions__link}
                                                >
                                                    {mobileWidth()
                                                        ? truncate(address, 31)
                                                        : truncate(address, 25)}
                                                </Link>
                                            </Tooltip>
                                        </div>
                                        <div className={style.transactions__column3}>
                                            <p className={style.transactions__label}>{t('totalTxn')}</p>
                                            <p className={style.transactions__text}>{formatNumber(value)}</p>
                                        </div>
                                        <div className={style.transactions__column4}>
                                            <p className={style.transactions__label}>{t('percentage')}</p>
                                            <p className={style.transactions__text}>
                                                {formatNumber(percentage, { decimal: 2 })}%
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={style.container__wrapper_item}>
                        <h3 className={style.container__title}>
                            {t('top')} BTCU {t('receivers')}
                        </h3>
                        <div className={style.transactions}>
                            {!topReceivers?.length ? <p className={style.noData}>{t('noDataYet')}</p> : null}
                            {topReceivers?.map((item, index) => {
                                const { address, value, percentage } = item;

                                return (
                                    <div key={index} className={style.transactions__row}>
                                        <div className={style.transactions__column1}>
                                            <p className={style.transactions__label}>{t('rank')}</p>
                                            <p className={style.transactions__text}>{index + 1}</p>
                                        </div>
                                        <div className={style.transactions__column2}>
                                            <p className={style.transactions__label}>{t('address')}</p>
                                            <Tooltip placement="topLeft" title={address}>
                                                <Link
                                                    to={`${addressPath}/${address}`}
                                                    className={style.transactions__link}
                                                >
                                                    {mobileWidth()
                                                        ? truncate(address, 31)
                                                        : truncate(address, 25)}
                                                </Link>
                                            </Tooltip>
                                        </div>
                                        <div className={style.transactions__column3}>
                                            <p className={style.transactions__label}>{t('totalTxn')}</p>
                                            <p className={style.transactions__text}>{formatNumber(value)}</p>
                                        </div>
                                        <div className={style.transactions__column4}>
                                            <p className={style.transactions__label}>{t('percentage')}</p>
                                            <p className={style.transactions__text}>
                                                {formatNumber(percentage)}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={style.container__wrapper_item}>
                        <h3 className={style.container__title}>
                            {t('top')} {t('txnCountSent')}
                        </h3>
                        <div className={style.transactions}>
                            {!topTxSent?.length ? <p className={style.noData}>{t('noDataYet')}</p> : null}
                            {topTxSent?.map((item, index) => {
                                const { address, value, percentage } = item;

                                return (
                                    <div key={index} className={style.transactions__row}>
                                        <div className={style.transactions__column1}>
                                            <p className={style.transactions__label}>{t('rank')}</p>
                                            <p className={style.transactions__text}>{index + 1}</p>
                                        </div>
                                        <div className={style.transactions__column2}>
                                            <p className={style.transactions__label}>{t('address')}</p>
                                            <Tooltip placement="topLeft" title={address}>
                                                <Link
                                                    to={`${addressPath}/${address}`}
                                                    className={style.transactions__link}
                                                >
                                                    {mobileWidth()
                                                        ? truncate(address, 31)
                                                        : truncate(address, 25)}
                                                </Link>
                                            </Tooltip>
                                        </div>
                                        <div className={style.transactions__column3}>
                                            <p className={style.transactions__label}>{t('totalTxn')}</p>
                                            <p className={style.transactions__text}>{formatNumber(value)}</p>
                                        </div>
                                        <div className={style.transactions__column4}>
                                            <p className={style.transactions__label}>{t('percentage')}</p>
                                            <p className={style.transactions__text}>
                                                {formatNumber(percentage)}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={style.container__wrapper_item}>
                        <h3 className={style.container__title}>{t('txnCountReceived')}</h3>
                        <div className={style.transactions}>
                            {!topTxReceived?.length ? <p className={style.noData}>{t('noDataYet')}</p> : null}
                            {topTxReceived?.map((item, index) => {
                                const { address, value, percentage } = item;

                                return (
                                    <div key={index} className={style.transactions__row}>
                                        <div className={style.transactions__column1}>
                                            <p className={style.transactions__label}>{t('rank')}</p>
                                            <p className={style.transactions__text}>{index + 1}</p>
                                        </div>
                                        <div className={style.transactions__column2}>
                                            <p className={style.transactions__label}>{t('address')}</p>
                                            <Tooltip placement="topLeft" title={address}>
                                                <Link
                                                    to={`${addressPath}/${address}`}
                                                    className={style.transactions__link}
                                                >
                                                    {mobileWidth()
                                                        ? truncate(address, 31)
                                                        : truncate(address, 25)}
                                                </Link>
                                            </Tooltip>
                                        </div>
                                        <div className={style.transactions__column3}>
                                            <p className={style.transactions__label}>{t('totalTxn')}</p>
                                            <p className={style.transactions__text}>{formatNumber(value)}</p>
                                        </div>
                                        <div className={style.transactions__column4}>
                                            <p className={style.transactions__label}>{t('percentage')}</p>
                                            <p className={style.transactions__text}>
                                                {formatNumber(percentage)}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withTranslation(), withRouter)(Transactions);

import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tabs, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import classNames from 'classnames';
import withTitle from '@hoc/with-title';
import Spinner from '@spinner/spinner';
import truncate from '@helpers/truncate-string';
import formatNumber from '@helpers/format-number';
import { compose } from '@utils';
import withGetService from '@hoc/with-get-service';
import Pagination from '@layouts/paginations';
import SettingsIcon from '@assets/images/icons/settings-icon';
import { transactionPath, addressPath, blockPath } from '@constants';
import searchBlockByIndexAction from '@actions/get-search-block-by-index.actions';
import getTransactionsByBlockAction from '@actions/get-transactions-by-block.actions';
import getLatestSingleBlockAction from '@actions/get-latest-single-block.actions';
import ArrowRight from '@assets/images/icons/arrow-right';
import notFoundIcon from '@assets/images/icons/search-not-found.svg';
import difficultyIcon from '@assets/images/icons/difficulty-black-icon.svg';
import clockIcon from '@assets/images/icons/clock-blue.svg';
import rocketIcon from '@assets/images/icons/rocket-icon.svg';
import sizeIcon from '@assets/images/icons/size-icon.svg';
import btcuTicker from '@assets/images/icons/btcu-ticker-icon.svg';
import plusIcon from '@assets/images/icons/plus-icon.svg';
import minusIcon from '@assets/images/icons/minus-icon.svg';
import mobileWidth from '@helpers/mobile-width';
import ArrowLeftIcon from './images/arrow-left';
import ArrowRightIcon from './images/arrow-right';
import Code from './raw-block';
import style from './block-page.module.scss';

class BlockPage extends Component {
    SWITCH_BLOCK_TYPE = {
        PREV: 'PREV',
        NEXT: 'NEXT',
    };

    static defaultProps = {
        t: () => {},
        getBlockByIndex: () => {},
        getTransactionsByBlock: () => {},
        getLatestSingleBlock: () => {},
        match: {},
        searchBlockByIndex: {},
        transactionsByBlock: {},
        latestSingleBlock: {},
        history: {},
        loading: false,
        success: false,
    };

    static propTypes = {
        t: PropTypes.func,
        getBlockByIndex: PropTypes.func,
        getTransactionsByBlock: PropTypes.func,
        getLatestSingleBlock: PropTypes.func,
        match: PropTypes.object,
        searchBlockByIndex: PropTypes.object,
        transactionsByBlock: PropTypes.object,
        latestSingleBlock: PropTypes.object,
        history: PropTypes.object,
        loading: PropTypes.bool,
        success: PropTypes.bool,
    };

    state = {
        currentPageNumber: '',
        numItemsPerPage: '',
        data: [],
        totalCount: '',
        details: '',
    };

    componentDidMount() {
        const { getLatestSingleBlock } = this.props;
        this.loadData();
        this.loadTransactions(10, 1);
        getLatestSingleBlock();
    }

    componentDidUpdate(prevProps) {
        const {
            match: {
                params: { id },
            },
            success,
            transactionsByBlock: { currentPageNumber, numItemsPerPage, items, totalCount },
            getLatestSingleBlock,
        } = this.props;

        if (id !== prevProps.match.params.id) {
            this.loadData();
            this.loadTransactions(10, 1);
            getLatestSingleBlock();
        }

        if (success && success !== prevProps.success) {
            this.setState({
                currentPageNumber,
                numItemsPerPage,
                data: items,
                totalCount,
            });
        }
    }

    loadData = () => {
        const {
            getBlockByIndex,
            match: {
                params: { id },
            },
        } = this.props;
        getBlockByIndex(id);
    };

    loadTransactions = (itemsPerPage, numberPage) => {
        const {
            getTransactionsByBlock,
            match: {
                params: { id },
            },
        } = this.props;
        getTransactionsByBlock(itemsPerPage, numberPage, id);
    };

    changePagination = number => {
        const { numItemsPerPage } = this.state;
        this.loadTransactions(numItemsPerPage, number);
    };

    records = records => {
        this.loadTransactions(records, 1);
    };

    switchBlock = (block, type) => {
        const {
            latestSingleBlock: { height },
            history,
        } = this.props;
        if (type === this.SWITCH_BLOCK_TYPE.PREV) {
            if (+block > 1) {
                history.push(`${blockPath}/${+block - 1}`);
            }
        }

        if (type === this.SWITCH_BLOCK_TYPE.NEXT) {
            if (+block < height) {
                history.push(`${blockPath}/${+block + 1}`);
            }
        }
    };

    switchActiveTab = hash => {
        this.setState({
            details: hash,
        });
    };

    render() {
        const {
            t,
            searchBlockByIndex,
            searchBlockByIndex: { hash: blockHash, output_total, difficulty, time, size, block_reward },
            loading,
            match: {
                params: { id: block },
            },
            latestSingleBlock: { height },
        } = this.props;

        const { currentPageNumber, numItemsPerPage, data, totalCount, details } = this.state;

        const { TabPane } = Tabs;

        if (loading) {
            return <Spinner />;
        }

        if (!Object.keys(searchBlockByIndex).length) {
            return (
                <div className={style.notFoundIcon}>
                    <img src={notFoundIcon} alt="notFoundIcon" />
                    <p className={style.notFoundIcon__title}>{t('nothingFound')}</p>
                </div>
            );
        }

        const arrowLeftStyle = block <= 1 ? style.arrowDisabled : '';
        const arrowRightStyle = block === height ? style.arrowDisabled : '';

        const infoData = [
            {
                icon: rocketIcon,
                label: t('valueOut'),
                value: `${formatNumber(output_total)} BTCU`,
            },
            {
                icon: difficultyIcon,
                label: t('difficulty'),
                value: `${formatNumber(difficulty)}`,
            },
            {
                icon: clockIcon,
                label: t('timestamp'),
                value: (
                    <Moment format="DD.MM.YYYY, hh:mm A" unix>
                        {time / 1000}
                    </Moment>
                ),
            },
            {
                icon: sizeIcon,
                label: t('size'),
                value: `${size / 1000} kB`,
            },
        ];

        return (
            <div className={style.block} id="block-page">
                <h1 className={style.block__title}>
                    BTCU {t('blockHeight')} #{block}
                </h1>
                <div className={style.block__top}>
                    <div className={style.block__topWrapper}>
                        <div
                            className={style.block__top_arrowLeft}
                            onClick={() => this.switchBlock(block, this.SWITCH_BLOCK_TYPE.PREV)}
                        >
                            <ArrowLeftIcon className={arrowLeftStyle} />
                        </div>
                        <p className={style.block__top_address}>
                            {mobileWidth() ? truncate(blockHash, 26) : blockHash}
                        </p>
                        <div
                            className={style.block__top_arrowRight}
                            onClick={() => this.switchBlock(block, this.SWITCH_BLOCK_TYPE.NEXT)}
                        >
                            <ArrowRightIcon className={arrowRightStyle} />
                        </div>
                    </div>
                </div>
                <div className={style.block__bottom}>
                    {infoData.map(item => {
                        const { icon, label, value } = item;

                        return (
                            <div key={label} className={style.block__bottom_item}>
                                <img className={style.block__bottom_icon} src={icon} alt="infoIcon" />
                                <p className={style.block__bottom_label}>{label}</p>
                                <p className={style.block__bottom_value}>{value}</p>
                            </div>
                        );
                    })}
                </div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('transaction')} key="1">
                        <div className={style.transaction}>
                            {data.map(item => {
                                const {
                                    hash,
                                    output_total: valueOut,
                                    fee,
                                    from,
                                    outputs = [],
                                    is_stacking,
                                    input_total,
                                } = item;

                                const isOpen = details === hash;

                                const detailsStyle = isOpen
                                    ? classNames(style.details, style.detailsOpened)
                                    : style.details;

                                return (
                                    <Fragment key={hash}>
                                        <div className={style.transaction__row}>
                                            <OpenInfo
                                                from={from}
                                                isOpen={isOpen}
                                                hash={hash}
                                                switchTab={this.switchActiveTab}
                                            />
                                            <TableRow
                                                t={t}
                                                from={from}
                                                valueOut={valueOut}
                                                hash={hash}
                                                fee={fee}
                                                block_reward={block_reward}
                                            />
                                        </div>
                                        <div className={detailsStyle}>
                                            <div className={style.details__wrapper}>
                                                <DetailsLeftSide
                                                    t={t}
                                                    from={from}
                                                    input_total={input_total}
                                                />
                                                <DetailsRightSide
                                                    t={t}
                                                    outputs={outputs}
                                                    is_stacking={is_stacking}
                                                />
                                            </div>
                                            <div className={style.details__total}>
                                                <p>
                                                    <img src={btcuTicker} alt="btcuTicker" />
                                                    {t('fee')}: {fee} BTCU
                                                </p>
                                                <p>
                                                    <img src={btcuTicker} alt="btcuTicker" />
                                                    {t('total')}: {formatNumber(output_total)} BTCU
                                                </p>
                                            </div>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                        <Pagination
                            numItemsPerPage={numItemsPerPage}
                            totalCount={totalCount}
                            currentPageNumber={currentPageNumber}
                            recordsOnClick={this.records}
                            paginationOnChange={this.changePagination}
                        />
                    </TabPane>
                    <TabPane tab={t('rawBlock')} key="2">
                        <div className={style.rawBlock}>
                            <div className={style.rawBlock__wrapper}>
                                <Code code={searchBlockByIndex} />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

const OpenInfo = ({ from, isOpen, hash, switchTab }) => (
    <Fragment>
        {from.length ? (
            <div className={style.transaction__row_dotWrapper} onClick={() => switchTab(isOpen ? '' : hash)}>
                <div className={style.transaction__row_dot}>
                    <img src={isOpen ? minusIcon : plusIcon} alt="icon" />
                </div>
            </div>
        ) : (
            <div className={style.transaction__row_dotWrapperDisabled}>
                <div className={style.transaction__row_dot}>
                    <SettingsIcon />
                </div>
            </div>
        )}
    </Fragment>
);

const TableRow = ({ t, valueOut, hash, fee, from, block_reward }) => {
    const isFromData = !!from.length;

    return (
        <Fragment>
            <div className={style.transaction__item_hash}>
                <p className={style.transaction__item_label}>{t('hash')}</p>
                <Tooltip placement="topLeft" title={hash}>
                    <Link to={`${transactionPath}/${hash}`}>{mobileWidth() ? truncate(hash, 27) : hash}</Link>
                </Tooltip>
            </div>
            <div className={style.transaction__item_valueOut}>
                <p className={style.transaction__item_label}>
                    {isFromData ? t('valueOut') : t('blockReward')}
                </p>
                <span>{isFromData ? formatNumber(valueOut) : formatNumber(block_reward)}</span>
            </div>
            <div className={isFromData ? style.transaction__item_fee : style.transaction__item_empty}>
                <p className={style.transaction__item_label}>{isFromData ? t('fee') : t('description')}</p>
                <span>{isFromData ? formatNumber(fee) : t('newlyGeneratedCoins')}</span>
            </div>
        </Fragment>
    );
};

const DetailsLeftSide = ({ t, from, input_total }) => (
    <div className={style.details__leftSide}>
        <div className={style.details__labelWrapper}>
            <p className={style.details__label}>{t('inputs')}</p>
            <p className={style.details__label}>{t('totalInput')}</p>
        </div>
        {from.map((item, index) => (
            <div key={index} className={style.details__row}>
                <Link to={`${addressPath}/${item}`}>{mobileWidth() ? truncate(item, 15) : item}</Link>
                <div className={style.details__row_value}>
                    <img src={btcuTicker} alt="btcuTicker" />
                    <p>{formatNumber(input_total)} BTCU</p>
                </div>
            </div>
        ))}
    </div>
);

const DetailsRightSide = ({ t, outputs, is_stacking }) => {
    if (!outputs.length) {
        return null;
    }

    return (
        <Fragment>
            <ArrowRight className={style.details__arrow} />
            <div className={style.details__rightSide}>
                <div className={style.details__labelWrapper}>
                    <p className={style.details__label}>{t('outputs')}</p>
                    <p className={style.details__label}>{t('outputTotal')}</p>
                </div>
                <div className={style.details__rowWrapper}>
                    {is_stacking ? (
                        <div className={style.details__row}>
                            <p className={style.details__row_staking}>{t('stakingReward')}</p>
                        </div>
                    ) : null}
                    {outputs.map(items => {
                        const { recipient, value } = items;

                        return (
                            <div key={recipient} className={style.details__row}>
                                <Link to={`${addressPath}/${recipient}`}>
                                    {mobileWidth() ? truncate(recipient, 15) : recipient}
                                </Link>
                                <div className={style.details__row_value}>
                                    <img src={btcuTicker} alt="btcuTicker" />
                                    <p>{formatNumber(value)} BTCU</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
};

OpenInfo.defaultProps = {
    switchTab: () => {},
    from: [],
    isOpen: false,
    hash: '',
};

TableRow.defaultProps = {
    t: () => {},
    from: [],
    valueOut: '',
    fee: '',
    hash: '',
    block_reward: '',
};

DetailsLeftSide.defaultProps = {
    t: () => {},
    from: [],
    input_total: '',
};

DetailsRightSide.defaultProps = {
    t: () => {},
    outputs: [],
    is_stacking: false,
};

OpenInfo.propTypes = {
    switchTab: PropTypes.func,
    from: PropTypes.instanceOf(Array),
    isOpen: PropTypes.bool,
    hash: PropTypes.string,
};

TableRow.propTypes = {
    t: PropTypes.func,
    from: PropTypes.instanceOf(Array),
    valueOut: PropTypes.any,
    fee: PropTypes.any,
    block_reward: PropTypes.any,
    hash: PropTypes.string,
};

DetailsLeftSide.propTypes = {
    t: PropTypes.func,
    from: PropTypes.instanceOf(Array),
    input_total: PropTypes.string,
};

DetailsRightSide.propTypes = {
    t: PropTypes.func,
    outputs: PropTypes.instanceOf(Array),
    is_stacking: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        searchBlockByIndex: { data: searchBlockByIndex, loading },
        transactionsByBlock: { data: transactionsByBlock, success },
        latestSingleBlock: { data: latestSingleBlock },
    } = state;

    return {
        searchBlockByIndex,
        loading,
        transactionsByBlock,
        success,
        latestSingleBlock,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getBlockByIndex: searchBlockByIndexAction(getService),
        getTransactionsByBlock: getTransactionsByBlockAction(getService),
        getLatestSingleBlock: getLatestSingleBlockAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Block Page' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(BlockPage);

import React from 'react';
import PropTypes from 'prop-types';

const OrionTicker = ({ className }) => (
    <svg
        className={className}
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 5C0 2.23858 2.23858 0 5 0H18C20.7614 0 23 2.23858 23 5V18C23 20.7614 20.7614 23 18 23H5C2.23858 23 0 20.7614 0 18V5Z"
            fill="#BDBDC7"
            fillOpacity="0.1"
        />
        <path
            d="M17.4883 5.67779C17.3825 5.43428 17.1842 5.24285 16.9371 5.1457L11.8528 3.14668C11.6134 3.05253 11.3468 3.0543 11.1087 3.15162L5.88334 5.28663C5.6358 5.38778 5.43907 5.58386 5.33711 5.83107L3.1579 11.115C3.0568 11.3601 3.05719 11.6353 3.15901 11.8802L5.28641 16.9963C5.38646 17.2369 5.57647 17.4289 5.81599 17.5315L11.1811 19.8299C11.4345 19.9384 11.7216 19.9375 11.9744 19.8274L18.0717 17.17C18.3503 17.0486 18.5586 16.807 18.6377 16.5135L19.909 11.7955C19.9679 11.5768 19.9508 11.3445 19.8606 11.1368L17.4883 5.67779ZM9.29825 8.32639L13.6519 8.19037L14.6333 10.6977L11.551 14.3235L8.28604 11.0672L9.29825 8.32639ZM15.096 11.693L17.1755 15.878L12.4892 14.7597L15.096 11.693ZM10.6554 14.8399L6.60181 16.1992L7.92977 12.1216L10.6554 14.8399ZM5.76535 15.5582L4.26402 11.9474L7.00574 11.7496L5.76535 15.5582ZM11.0705 15.758L11.0121 18.668L7.22756 17.0465L11.0705 15.758ZM12.0648 15.689L16.5225 16.7528L12.004 18.722L12.0648 15.689ZM16.0852 11.4476L18.851 11.9027L17.9581 15.2167L16.0852 11.4476ZM18.6532 10.8538L15.5633 10.3456L14.595 7.87136L16.734 6.43724L18.6532 10.8538ZM11.4867 4.0792L15.8793 5.80625L13.8287 7.18138L9.08139 7.32965L6.86255 5.96856L11.4867 4.0792ZM6.07217 6.65713L8.34105 8.04892L7.35505 10.7187L4.30632 10.9388L6.07217 6.65713Z"
            fill="white"
        />
    </svg>
);

OrionTicker.defaultProps = {
    className: '',
};

OrionTicker.propTypes = {
    className: PropTypes.string,
};

export default OrionTicker;

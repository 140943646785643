import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import getPrivateNodeListAction from '@actions/get-private-node-list.actions';
import withTitle from '@hoc/with-title';
import withGetService from '@hoc/with-get-service';
import ProfileView from './profile-view';
import { compose } from '@utils';
import Spinner from '@spinner';
import style from './profile.module.scss';

export class Profile extends Component {
    static defaultProps = {
        getPrivateNodeList: () => {},
        privateNodeList: {},
        loading: false,
    };

    static propTypes = {
        getPrivateNodeList: PropTypes.func,
        privateNodeList: PropTypes.object,
        loading: PropTypes.bool,
    };

    onTabClick = value => {
        if (value === 'overview') {
            const { getPrivateNodeList } = this.props;
            getPrivateNodeList(10, 1);
        }
    };

    render() {
        const { loading, privateNodeList } = this.props;

        if (loading) {
            return (
                <div className={style.container}>
                    <Spinner />
                </div>
            );
        }

        return (
            <div className={style.container}>
                <ProfileView privateNodeList={privateNodeList} onTabClick={this.onTabClick} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        privateNodeList: {
            data: { privateNodeListArr: privateNodeList },
        },
    } = state;

    return {
        privateNodeList,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getPrivateNodeList: getPrivateNodeListAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Profile' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Profile);

/* eslint-disable camelcase */
import axios from 'axios';
import { Language } from '../helpers/request-header';

const login = ({
    email,
    geetest_challenge,
    geetest_seccode,
    geetest_validate,
    passwordValue,
    gtserver,
    gtuser_id,
}) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('geetest_challenge', geetest_challenge);
    formData.append('geetest_seccode', geetest_seccode);
    formData.append('geetest_validate', geetest_validate);
    formData.append('password', passwordValue);
    formData.append('gtserver', gtserver);
    formData.append('gtuser_id', gtuser_id);
    const options = {
        method: 'POST',
        headers: {
            language: Language(),
        },
        data: formData,
        url: `${process.env.REACT_APP_API_AUTH_URL}/auth/login`,
    };

    return axios(options).then(user => user);
};

const registration = user => {
    const formData = new FormData();
    const {
        login: loginValue,
        email,
        password,
        confirmPassword,
        masterNodeAddress,
        geetest_challenge,
        geetest_seccode,
        geetest_validate,
        gtserver,
        gtuser_id,
    } = user;

    formData.append('login', loginValue);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('confirmPassword', confirmPassword);
    formData.append('geetest_challenge', geetest_challenge);
    formData.append('geetest_seccode', geetest_seccode);
    formData.append('geetest_validate', geetest_validate);
    formData.append('gtserver', gtserver);
    formData.append('gtuser_id', gtuser_id);
    if (masterNodeAddress) {
        formData.append('masterNodeAddress', masterNodeAddress);
    }

    const options = {
        method: 'POST',
        headers: {
            language: Language(),
        },
        data: formData,
        url: `${process.env.REACT_APP_API_AUTH_URL}/auth/register`,
    };

    return axios(options).then(data => data);
};

export { login, registration };

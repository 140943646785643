/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import withTitle from '../../hoc/with-title';
import Spinner from '../../spinner';
import SupplyGrowth from './supply-growth';
import DailyTransactions from './daily-transactions';
import withGetService from '../../hoc/with-get-service';
import UniqueAddresses from './unique-addresses';
import NetworkDifficulty from './network-difficulty';
import PendingTransactions from './pending-transactions';
import TransactionFee from './transaction-fee';
import getSupplyChartAction from '../../../actions/get-supply-chart.actions';
import getDailyTransactionsChartAction from '../../../actions/get-daily-transactions-chart.actions';
import getDifficultyChartAction from '../../../actions/get-difficulty-chart.actions';
import getPendingTransactionsChartAction from '../../../actions/get-pending-transactions-chart.actions';
import getTransactionFeeChartAction from '../../../actions/get-transaction-fee-chart.actions';
import getUniqueAddressesChartAction from '../../../actions/get-unique-addresses-chart.actions';
import { compose } from '../../../utils';
import style from './chart-stats.module.scss';

class ChartsAndStats extends Component {
    static defaultProps = {
        t: () => {},
        getSupplyChart: () => {},
        getDifficultyChart: () => {},
        getDailyTransactionsChart: () => {},
        getPendingTransactionsChart: () => {},
        getTransactionFeeChart: () => {},
        getUniqueAddressesChart: () => {},
        loadingSupplyChart: true,
        loadingDailyTransactionsChart: true,
        loadingPendingTransactionsChart: true,
        loadingTransactionFeeChart: true,
        loadingUniqueAddressesChart: true,
        loadingDifficultyChart: true,
        errorSupplyChart: false,
        errorDailyTransactions: false,
        errorUniqueAddresses: false,
        errorDifficultyChart: false,
        errorPendingTransactions: false,
        errorTransactionFeeChart: false,
        supplyChart: [],
        dailyTransactionsChart: [],
        uniqueAddressesChart: [],
        difficultyChart: [],
        pendingTransactionsChart: [],
        transactionFeeChart: [],
    };

    static propTypes = {
        t: PropTypes.func,
        getSupplyChart: PropTypes.func,
        getDifficultyChart: PropTypes.func,
        getDailyTransactionsChart: PropTypes.func,
        getPendingTransactionsChart: PropTypes.func,
        getTransactionFeeChart: PropTypes.func,
        getUniqueAddressesChart: PropTypes.func,
        loadingSupplyChart: PropTypes.bool,
        loadingDailyTransactionsChart: PropTypes.bool,
        loadingPendingTransactionsChart: PropTypes.bool,
        loadingTransactionFeeChart: PropTypes.bool,
        loadingUniqueAddressesChart: PropTypes.bool,
        loadingDifficultyChart: PropTypes.bool,
        errorSupplyChart: PropTypes.bool,
        errorDailyTransactions: PropTypes.bool,
        errorUniqueAddresses: PropTypes.bool,
        errorDifficultyChart: PropTypes.bool,
        errorPendingTransactions: PropTypes.bool,
        errorTransactionFeeChart: PropTypes.bool,
        supplyChart: PropTypes.instanceOf(Array),
        dailyTransactionsChart: PropTypes.instanceOf(Array),
        uniqueAddressesChart: PropTypes.instanceOf(Array),
        pendingTransactionsChart: PropTypes.instanceOf(Array),
        difficultyChart: PropTypes.instanceOf(Array),
        transactionFeeChart: PropTypes.instanceOf(Array),
    };

    state = {};

    componentDidMount() {
        const {
            getSupplyChart,
            getDifficultyChart,
            getDailyTransactionsChart,
            getPendingTransactionsChart,
            getTransactionFeeChart,
            getUniqueAddressesChart,
        } = this.props;
        getSupplyChart('all');
        getDifficultyChart('all');
        getDailyTransactionsChart('all');
        getPendingTransactionsChart('all');
        getTransactionFeeChart('all');
        getUniqueAddressesChart('all');
    }

    render() {
        const {
            t,
            loadingSupplyChart,
            loadingDailyTransactionsChart,
            loadingPendingTransactionsChart,
            loadingTransactionFeeChart,
            loadingUniqueAddressesChart,
            loadingDifficultyChart,
            supplyChart,
            dailyTransactionsChart,
            uniqueAddressesChart,
            pendingTransactionsChart,
            transactionFeeChart,
            difficultyChart,
            errorSupplyChart,
            errorDailyTransactions,
            errorUniqueAddresses,
            errorDifficultyChart,
            errorPendingTransactions,
            errorTransactionFeeChart,
        } = this.props;

        const SupplyGrowthWr = () => {
            if (loadingSupplyChart) {
                return (
                    <div className={style.spinnerChart}>
                        <Spinner />
                    </div>
                );
            }

            if (errorSupplyChart) {
                return null;
            }

            return <SupplyGrowth data={supplyChart} />;
        };

        const DailyTransactionsWr = () => {
            if (loadingDailyTransactionsChart) {
                return (
                    <div className={style.spinnerChart}>
                        <Spinner />
                    </div>
                );
            }

            if (errorDailyTransactions) {
                return null;
            }

            return <DailyTransactions data={dailyTransactionsChart} />;
        };

        const UniqueAddressesWr = () => {
            if (loadingUniqueAddressesChart) {
                return (
                    <div className={style.spinnerChart}>
                        <Spinner />
                    </div>
                );
            }

            if (errorUniqueAddresses) {
                return null;
            }

            return <UniqueAddresses data={uniqueAddressesChart} />;
        };

        const NetworkDifficultyWr = () => {
            if (loadingDifficultyChart) {
                return (
                    <div className={style.spinnerChart}>
                        <Spinner />
                    </div>
                );
            }

            if (errorDifficultyChart) {
                return null;
            }

            return <NetworkDifficulty data={difficultyChart} />;
        };

        const PendingTransactionsWr = () => {
            if (loadingPendingTransactionsChart) {
                return (
                    <div className={style.spinnerChart}>
                        <Spinner />
                    </div>
                );
            }

            if (errorPendingTransactions) {
                return null;
            }

            return (
                <PendingTransactions
                    data={pendingTransactionsChart}
                />
            );
        };

        const TransactionFeeWr = () => {
            if (loadingTransactionFeeChart) {
                return (
                    <div className={style.spinnerChart}>
                        <Spinner />
                    </div>
                );
            }

            if (errorTransactionFeeChart) {
                return null;
            }

            return (
                <TransactionFee data={transactionFeeChart} />
            );
        };

        return (
            <div className={style.charts} id="chartsAndStats">
                <h1 className={style.charts__title}>{t('chartsAndStatistics')}</h1>
                <h3 className={style.charts__subTitle}>{t('marketData')}</h3>
                <div className={style.charts__container}>
                    <div
                        className={style.charts__container_item}
                        style={{ width: '100%' }}
                    >
                        <SupplyGrowthWr />
                    </div>
                </div>
                <h3 className={style.charts__subTitle}>{t('blockchainData')}</h3>
                <div className={style.charts__container}>
                    <div className={style.charts__container_item}>
                        <DailyTransactionsWr />
                    </div>
                    <div className={style.charts__container_item}>
                        <UniqueAddressesWr />
                    </div>
                </div>
                <h3 className={style.charts__subTitle}>{t('networkData')}</h3>
                <div className={style.charts__container}>
                    <div className={style.charts__container_item}>
                        <NetworkDifficultyWr />
                    </div>
                    <div className={style.charts__container_item}>
                        <PendingTransactionsWr />
                    </div>
                    <div className={style.charts__container_item}>
                        <TransactionFeeWr />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        supplyChart: {
            data: supplyChart,
            loading: loadingSupplyChart,
            error: errorSupplyChart,
        },
        dailyTransactionsChart: {
            data: dailyTransactionsChart,
            loading: loadingDailyTransactionsChart,
            error: errorDailyTransactions,
        },
        pendingTransactionsChart: {
            data: pendingTransactionsChart,
            loading: loadingPendingTransactionsChart,
            error: errorPendingTransactions,
        },
        transactionFeeChart: {
            data: transactionFeeChart,
            loading: loadingTransactionFeeChart,
            error: errorTransactionFeeChart,
        },
        uniqueAddressesChart: {
            data: uniqueAddressesChart,
            loading: loadingUniqueAddressesChart,
            error: errorUniqueAddresses,
        },
        difficultyChart: {
            data: difficultyChart,
            loading: loadingDifficultyChart,
            error: errorDifficultyChart,
        },
    } = state;

    return {
        supplyChart,
        dailyTransactionsChart,
        pendingTransactionsChart,
        transactionFeeChart,
        uniqueAddressesChart,
        difficultyChart,
        loadingSupplyChart,
        loadingDailyTransactionsChart,
        loadingPendingTransactionsChart,
        loadingTransactionFeeChart,
        loadingUniqueAddressesChart,
        loadingDifficultyChart,
        errorSupplyChart,
        errorDailyTransactions,
        errorUniqueAddresses,
        errorDifficultyChart,
        errorPendingTransactions,
        errorTransactionFeeChart,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getSupplyChart: getSupplyChartAction(getService),
        getDifficultyChart: getDifficultyChartAction(getService),
        getDailyTransactionsChart: getDailyTransactionsChartAction(getService),
        getPendingTransactionsChart: getPendingTransactionsChartAction(getService),
        getTransactionFeeChart: getTransactionFeeChartAction(getService),
        getUniqueAddressesChart: getUniqueAddressesChartAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Charts and Statistics' }),
    connect(mapStateToProps, mapDispatchToProps),
)(ChartsAndStats);

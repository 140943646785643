import { PUT_PRIVATE_NODE_LIST } from '../constants';

const updatePrivateNodeList = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: true,
            error: false,
            success: false,
        };
    }

    switch (action.type) {
    case PUT_PRIVATE_NODE_LIST.PUT_PRIVATE_NODE_LIST_REQUEST:
        return {
            ...state,
            loading: true,
            error: false,
            success: false,
        };

    case PUT_PRIVATE_NODE_LIST.PUT_PRIVATE_NODE_LIST_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
            success: true,
        };

    case PUT_PRIVATE_NODE_LIST.PUT_PRIVATE_NODE_LIST_FAILURE:
        return {
            data: {},
            loading: false,
            error: true,
            success: false,
        };

    default:
        return state;
    }
};

export default updatePrivateNodeList;

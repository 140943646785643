import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Tooltip } from 'antd';
import formatNumber from '@helpers/format-number';
import Pagination from '@layouts/paginations';
import Button from '@UI/button';
import { compose } from '@utils';
import { addWatchListPath, addressPath } from '@constants';
import truncate from '@helpers/truncate-string';
import NOTIFICATION from '@constants/notification.constants';
import mobileWidth from '@helpers/mobile-width';
import addIcon from '@assets/images/icons/add-icon.svg';
import noDataIcon from '@assets/images/icons/no-data-icon.svg';
import bellOnIcon from '@assets/images/icons/bell-on-icon.svg';
import bellOffIcon from '@assets/images/icons/bell-off-icon.svg';
import style from './watch-list.module.scss';

const WatchListView = ({
    openEdit,
    hangePagination,
    currentPageNumber,
    numItemsPerPage,
    items,
    totalCount,
    grandTotal,
    grandTotalUSD,
}) => {
    const { t } = useTranslation();

    const Header = () => (
        <div className={style.header}>
            <p className={style.header__title}>{t('myWatchList')}</p>
            <Link to={addWatchListPath} className={style.header__add}>
                <img src={addIcon} alt="addIcon" /> {t('add')}
            </Link>
        </div>
    );

    if (!items.length) {
        return (
            <Fragment>
                <Header />
                <div className={style.noData}>
                    <img className={style.noData__icon} src={noDataIcon} alt="noData" />
                    <p className={style.noData__title}>{t('emptyWatchList')}</p>
                    <Link to={addWatchListPath} className={style.header__add}>
                        <img src={addIcon} alt="addIcon" /> {t('add')}
                    </Link>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Header />
            <p className={style.totalContractsFound}>{t('totalContractsFound', { digit: totalCount })}</p>
            <div className={style.table}>
                <div className={style.table__head}>
                    <div>{t('action')}</div>
                    <div>{t('address')}</div>
                    <div>{t('balance')}</div>
                    <div>{t('notification')}</div>
                </div>
                {items.map((item, index) => {
                    const { address, description, notificationStatus, tag, balance, createdAt } = item;

                    const isNotificationOff = notificationStatus === NOTIFICATION.OFF;

                    return (
                        <div key={index} className={style.table__row}>
                            <div>
                                <Button
                                    className={style.table__row_button}
                                    onClick={() => openEdit(address)}
                                    type="button"
                                >
                                    {t('edit')}
                                </Button>
                            </div>
                            <div className={style.table__row_address}>
                                <span className={style.table__row_mobileTitle}>{t('address')}</span>
                                <Tooltip placement="topLeft" title={address}>
                                    <Link to={`${addressPath}/${address}`}>
                                        {mobileWidth() ? truncate(address, 23) : truncate(address, 25)}
                                    </Link>
                                </Tooltip>
                                <p>
                                    {t('addedOn')}:{' '}
                                    <Moment format="DD.MM.YYYY" unix>
                                        {createdAt / 1000}
                                    </Moment>
                                </p>
                                {tag ? (
                                    <p>
                                        {t('nameTag')}: {tag}{' '}
                                    </p>
                                ) : null}
                                {description ? (
                                    <p>
                                        {t('description')}: {description}{' '}
                                    </p>
                                ) : null}
                            </div>
                            <div className={style.table__row_balance}>
                                <span className={style.table__row_mobileTitle}>{t('balance')}</span>
                                {balance ? <p>{formatNumber(balance)} BTCU</p> : null}
                            </div>
                            <div className={style.table__row_notification}>
                                <span className={style.table__row_mobileTitle}>{t('notification')}</span>
                                <section>
                                    <img src={isNotificationOff ? bellOffIcon : bellOnIcon} alt="bell" />
                                    <p> {isNotificationOff ? t('off') : t('on')}</p>
                                </section>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={style.grandTotal}>
                <div className={style.grandTotal__leftSide}>
                    {t('grandTotal')}
                    <span className={style.grandTotal__leftSide_amount}>
                        ({formatNumber(grandTotal)} / BTCU)
                    </span>
                </div>
                <p>${grandTotalUSD}</p>
            </div>
            <div className={style.pagination}>
                <Pagination
                    numItemsPerPage={numItemsPerPage}
                    totalCount={totalCount}
                    currentPageNumber={currentPageNumber}
                    paginationOnChange={hangePagination}
                    disabledRecords
                    minPagination={5}
                />
            </div>
        </Fragment>
    );
};

WatchListView.defaultProps = {
    openEdit: () => {},
    hangePagination: () => {},
    items: [],
    numItemsPerPage: '',
    totalCount: 1,
    currentPageNumber: '',
    grandTotal: '',
    grandTotalUSD: '',
};

WatchListView.propTypes = {
    openEdit: PropTypes.func,
    hangePagination: PropTypes.func,
    items: PropTypes.instanceOf(Array),
    numItemsPerPage: PropTypes.any,
    totalCount: PropTypes.any,
    currentPageNumber: PropTypes.any,
    grandTotal: PropTypes.any,
    grandTotalUSD: PropTypes.any,
};

export default compose(connect(), withRouter)(WatchListView);

import { GET_TRANSACTION_FEE_CHART } from '../constants';

const getTransactionFeeChartRequested = () => ({
    type: GET_TRANSACTION_FEE_CHART.GET_TRANSACTION_FEE_CHART_REQUEST,
});

const getTransactionFeeChartLoaded = data => ({
    type: GET_TRANSACTION_FEE_CHART.GET_TRANSACTION_FEE_CHART_SUCCESS,
    payload: data,
});

const getTransactionFeeChartError = error => ({
    type: GET_TRANSACTION_FEE_CHART.GET_TRANSACTION_FEE_CHART_FAILURE,
    payload: error,
});

const getTransactionFeeChartAction = getService => timeframe => dispatch => {
    dispatch(getTransactionFeeChartRequested());
    getService
        .getTransactionFeeChart(timeframe)
        .then(data => {
            dispatch(getTransactionFeeChartLoaded(data));
        })
        .catch(err => {
            dispatch(getTransactionFeeChartError(err));
        });
};

export default getTransactionFeeChartAction;

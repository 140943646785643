/* eslint-disable no-restricted-globals */
import Decimal from 'decimal.js-light';

const formatNumber = (val, { decimal } = { decimal: 8 }) => {
    if (isNaN(val)) return val;
    const dec = new Decimal(val);

    if (dec.greaterThanOrEqualTo(100) || dec.lessThanOrEqualTo(-1000)) {
        return parseFloat(dec.toFixed(decimal)).toLocaleString();
    }

    return new Decimal(dec.toFixed(decimal)).toString();
};

export default formatNumber;

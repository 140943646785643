import notification from '../helpers/notifications';
import {
    PUT_PRIVATE_NODE_LIST,
    TX_HASH_NOT_FOUND,
    txnPrivateNotesPath,
} from '../constants';

const putPrivateNodeListRequested = () => ({
    type: PUT_PRIVATE_NODE_LIST.PUT_PRIVATE_NODE_LIST_REQUEST,
});

const putPrivateNodeListLoaded = data => ({
    type: PUT_PRIVATE_NODE_LIST.PUT_PRIVATE_NODE_LIST_SUCCESS,
    payload: data,
});

const putPrivateNodeListError = error => ({
    type: PUT_PRIVATE_NODE_LIST.PUT_PRIVATE_NODE_LIST_FAILURE,
    payload: error,
});

const putPrivateNodeListAction = postService => (
    values,
    t,
    history,
    page,
) => dispatch => {
    dispatch(putPrivateNodeListRequested());
    postService
        .updatePrivateNodeList(values)
        .then(data => {
            dispatch(putPrivateNodeListLoaded(data));
            notification('', t('updatedSuccessfully'), 'success');
            let path = txnPrivateNotesPath;
            if (page) {
                path = {
                    pathname: txnPrivateNotesPath,
                    search: `?page=${page}`,
                };
            }
            history.push(path);
        })
        .catch(error => {
            dispatch(putPrivateNodeListError(error.response.data.message));
            const {
                response: { data: { message = 'Something went wrong' } = {} } = {},
            } = error;
            let errorMessage = message;

            if (message === TX_HASH_NOT_FOUND) {
                errorMessage = t('error.txNotFound');
            }

            notification('', errorMessage, 'danger');
        });
};

export default putPrivateNodeListAction;

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withTitle from '@hoc/with-title';
import withGetService from '@hoc/with-get-service';
import withPostService from '@hoc/with-post-service';
import { compose } from '@utils';
import Spinner from '@spinner';
import getTxNotifierAction from '@actions/get-tx-notifier.actions';
import deleteNotifierAction from '@actions/delete-notifier.actions';
import NotificationsView from './notifications-view';
import style from './notifications.module.scss';

export class Notifications extends Component {
    static defaultProps = {
        t: () => {},
        getTxNotifier: () => {},
        deleteNotifier: () => {},
        success: false,
        loading: false,
        txNotifier: {},
    };

    static propTypes = {
        t: PropTypes.func,
        getTxNotifier: PropTypes.func,
        deleteNotifier: PropTypes.func,
        success: PropTypes.bool,
        loading: PropTypes.bool,
        txNotifier: PropTypes.object,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { success } = this.props;

        if (success && success !== prevProps.success) {
            this.loadData();
        }
    }

    loadData = () => {
        const { getTxNotifier } = this.props;
        getTxNotifier();
    };

    removeNotifiction = id => {
        const { t, deleteNotifier } = this.props;
        const data = {
            id,
        };

        deleteNotifier(data, t);
    };

    render() {
        const { loading, txNotifier } = this.props;

        if (loading) {
            return (
                <div className={style.container}>
                    <Spinner />
                </div>
            );
        }

        return (
            <div className={style.container}>
                <NotificationsView txNotifier={txNotifier} removeNotifiction={this.removeNotifiction} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        txNotifier: { data: txNotifier, loading },
        deleteNotifier: { success },
    } = state;

    return {
        txNotifier,
        success,
        loading,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        getTxNotifier: getTxNotifierAction(getService),
        deleteNotifier: deleteNotifierAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    withTitle({ title: '| Notifications' }),
    connect(mapStateToProps, mapDispatchToProps),
)(Notifications);

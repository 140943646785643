import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import withTitle from '@hoc/with-title';
import Field from '@UI/field/field';
import withGetService from '@hoc/with-get-service';
import withPostService from '@hoc/with-post-service';
import { compose } from '@utils';
import networkTitle from '@helpers/network';
import InfoIcon from '@assets/images/icons/info_icon';
import Button from '@UI/button/button';
import { isTxHash } from '@helpers';
import getTransactionInfoAction from '@actions/get-transaction-info.actions';
import postPrivateNodeListAction from '@actions/post-private-node-list.actions';
import style from './private-notes.module.scss';

export class PrivateNotesAdd extends Component {
    static defaultProps = {
        t: () => {},
        postPrivateNodeList: () => {},
        getTransactionInfo: () => {},
        history: {},
        match: {},
        success: false,
        addPrivateNodeListLoading: false,
        transactionInfoLoading: false,
    };

    static propTypes = {
        t: PropTypes.func,
        postPrivateNodeList: PropTypes.func,
        getTransactionInfo: PropTypes.func,
        history: PropTypes.object,
        match: PropTypes.object,
        success: PropTypes.bool,
        addPrivateNodeListLoading: PropTypes.bool,
        transactionInfoLoading: PropTypes.bool,
    };

    state = {
        txHash: '',
        txHashError: '',
        description: '',
        descriptionError: '',
        descripMinLengthError: '',
    };

    componentDidMount() {
        const {
            match: {
                params: { id },
            },
        } = this.props;

        if (id) {
            const event = {
                target: {
                    name: 'txHash',
                    value: id,
                },
            };
            this.inputOnChange(event);
        }
    }

    componentDidUpdate(prevProps) {
        const { success } = this.props;

        if (success && success !== prevProps.success) {
            const { t, postPrivateNodeList, history } = this.props;
            const { txHash, description } = this.state;
            const data = {
                txHash,
                description,
                currency: networkTitle(),
            };
            postPrivateNodeList(data, t, history);
        }
    }

    inputOnChange = event => {
        const { name, value } = event.target;
        const { t } = this.props;

        if (name === 'txHash') {
            const isError = isTxHash(value);
            const txHashError = isError && value.length ? t('error.wrongAddress') : '';

            this.setState({
                [name]: value.trim(),
                txHashError,
            });
        }

        if (name === 'description') {
            this.validateDescription(name, value);
        }
    };

    validateDescription = (name, value) => {
        const { t } = this.props;
        const { description } = this.state;
        const minLength = 1;
        const maxLength = 75;

        const minLengthError = value.length < minLength ? t('error.min_length', { digit: minLength }) : '';

        const maxLengthError = value.length > maxLength ? t('error.max_length', { digit: maxLength }) : '';

        this.setState({
            [name]: maxLengthError ? description : value,
            descriptionError: minLengthError || maxLengthError,
            descripMinLengthError: minLengthError,
        });
    };

    back = () => {
        const { history } = this.props;
        history.goBack();
    };

    save = async () => {
        const { t, getTransactionInfo } = this.props;
        const { txHash, description, txHashError, descripMinLengthError } = this.state;
        await this.validateDescription('description', description);
        const isError = !txHash || !description || txHashError || descripMinLengthError;

        if (!isError) {
            getTransactionInfo(txHash, t);
        }
    };

    render() {
        const { t, addPrivateNodeListLoading, transactionInfoLoading } = this.props;
        const { txHash, txHashError, description, descripMinLengthError, descriptionError } = this.state;

        const disabledSaveBtn = !txHash || !description || txHashError || descripMinLengthError;
        const loading = addPrivateNodeListLoading || transactionInfoLoading;
        console.log(loading, 'loadingloadingloading');

        return (
            <div className={style.container}>
                <div className={style.header}>
                    <p className={style.header__title}>{t('createNewPrivateNote')}</p>
                </div>
                <div className={style.edit}>
                    <div className={style.edit__row}>
                        <p className={style.edit__row_labelSecond}>{t('txnHash')}</p>
                        <div className={style.edit__inputContainer}>
                            <Field
                                id="txHash"
                                type="text"
                                name="txHash"
                                value={txHash}
                                onChange={this.inputOnChange}
                                inputStyle={style.edit__input}
                                labelStyle={style.edit__label}
                                labelStyleActive={style.edit__labelActive}
                            />
                            {txHashError ? (
                                <div className={style.edit__error}>
                                    <InfoIcon className={style.edit__error_icon} />
                                    <p className={style.edit__error_text}>{txHashError}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className={style.edit__row}>
                        <p className={style.edit__row_labelSecond}>{t('privateNote')}</p>
                        <div className={style.edit__inputContainer}>
                            <Field
                                id="description"
                                type="text"
                                name="description"
                                value={description}
                                onChange={this.inputOnChange}
                                inputStyle={style.edit__input}
                                labelStyle={style.edit__label}
                                labelStyleActive={style.edit__labelActive}
                            />
                            {descriptionError ? (
                                <div className={style.edit__error}>
                                    <InfoIcon className={style.edit__error_icon} />
                                    <p className={style.edit__error_text}>{descriptionError}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className={style.edit__buttonWrapper}>
                        <div className={style.edit__buttonWrapper_leftSide}>
                            <Button
                                type="button"
                                className={style.edit__buttonWrapper_back}
                                onClick={this.back}
                            >
                                {t('back')}
                            </Button>
                            <Button
                                type="submit"
                                className={style.edit__buttonWrapper_save}
                                onClick={this.save}
                                disabled={!!disabledSaveBtn || loading}
                            >
                                {t('create')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        addPrivateNodeList: { data, loading: addPrivateNodeListLoading },
        transactionInfo: { loading: transactionInfoLoading, success },
    } = state;

    return {
        data,
        addPrivateNodeListLoading,
        transactionInfoLoading,
        success,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        postPrivateNodeList: postPrivateNodeListAction(postService),
        getTransactionInfo: getTransactionInfoAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    withTitle({ title: '| Add Private Notes' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(PrivateNotesAdd);

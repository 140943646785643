import { GET_UNIQUE_ADDRESSES_CHART } from '../constants';

const getUniqueAddressesChartRequested = () => ({
    type: GET_UNIQUE_ADDRESSES_CHART.GET_UNIQUE_ADDRESSES_CHART_REQUEST,
});

const getUniqueAddressesChartLoaded = data => ({
    type: GET_UNIQUE_ADDRESSES_CHART.GET_UNIQUE_ADDRESSES_CHART_SUCCESS,
    payload: data,
});

const getUniqueAddressesChartError = error => ({
    type: GET_UNIQUE_ADDRESSES_CHART.GET_UNIQUE_ADDRESSES_CHART_FAILURE,
    payload: error,
});

const getUniqueAddressesChartAction = getService => timeframe => dispatch => {
    dispatch(getUniqueAddressesChartRequested());
    getService
        .getUniqueAddressesChart(timeframe)
        .then(data => {
            dispatch(getUniqueAddressesChartLoaded(data));
        })
        .catch(err => {
            dispatch(getUniqueAddressesChartError(err));
        });
};

export default getUniqueAddressesChartAction;

import { GET_TOP_STATISTICS } from '../constants';

const getTopStatisticsRequested = () => ({
    type: GET_TOP_STATISTICS.GET_TOP_STATISTICS_REQUEST,
});

const getTopStatisticsLoaded = data => ({
    type: GET_TOP_STATISTICS.GET_TOP_STATISTICS_SUCCESS,
    payload: data,
});

const getTopStatisticsError = error => ({
    type: GET_TOP_STATISTICS.GET_TOP_STATISTICS_FAILURE,
    payload: error,
});

const getTopStatisticsAction = getService => timeframe => dispatch => {
    dispatch(getTopStatisticsRequested());
    getService
        .getTopStatistics(timeframe)
        .then(data => {
            dispatch(getTopStatisticsLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getTopStatisticsError(err));
        });
};

export default getTopStatisticsAction;

import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import formatNumber from '@helpers/format-number';
import truncate from '@helpers/truncate-string';
import notification from '@helpers/notifications';
import copyToClipboard from '@helpers/copy-to-clipboard';
import getWatchListAction from '@actions/get-watch-list.actions';
import getTransactionsByAddressAction from '@actions/get-transactions-by-address.actions';
import getAddressChartAction from '@actions/get-address-chart.actions';
import getAddressInfoAction from '@actions/get-address-info.actions';
import mobileWidth from '@helpers/mobile-width';
import withTitle from '@hoc/with-title';
import Spinner from '@spinner/spinner';
import withGetService from '@hoc/with-get-service';
// import Comments from '@layouts/comments';
import ModalWindow from '@UI/modal-window';
import spinnerImg from '@assets/images/spinner.svg';
import { loginPath, addWatchListPath, editWatchListPath } from '@constants';
import Button from '@UI/button/button';
import CopyIcon from '@assets/images/icons/copy-icon';
import addIcon from '@assets/images/icons/add-icon.svg';
import eyeIcon from '@assets/images/icons/eye-icon.svg';
import qrCodeIcon from '@assets/images/icons/qr-code-icon.svg';
import notFoundIcon from '@assets/images/icons/search-not-found.svg';
import Analytics from './analytics';
import Transactions from './transactions';
import { compose } from '@utils';
import style from './address-page.module.scss';

class AddressPage extends Component {
    static defaultProps = {
        t: () => {},
        getAddressInfo: () => {},
        getTransactionsByAddress: () => {},
        getAddressChart: () => {},
        match: {},
        addressInfo: {},
        transactionsByAddress: {},
        addressChart: [],
        watchList: {},
        loggingIn: false,
        loading: false,
        success: false,
        watchListLoading: false,
    };

    static propTypes = {
        t: PropTypes.func,
        getAddressInfo: PropTypes.func,
        getTransactionsByAddress: PropTypes.func,
        getAddressChart: PropTypes.func,
        match: PropTypes.object,
        addressInfo: PropTypes.object,
        transactionsByAddress: PropTypes.object,
        addressChart: PropTypes.instanceOf(Array),
        watchList: PropTypes.object,
        loggingIn: PropTypes.bool,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        watchListLoading: PropTypes.bool,
    };

    state = {
        address: '',
        currentPageNumber: '',
        numItemsPerPage: '',
        data: [],
        totalCount: '',
    };

    componentDidMount() {
        const {
            match: {
                params: { id },
            },
            getAddressChart,
        } = this.props;

        this.setState(
            {
                address: id,
            },
            () => {
                this.loadData();
                this.loadTransactions(10, 1);
                getAddressChart(id);
            },
        );
    }

    componentDidUpdate(prevProps) {
        const {
            success,
            transactionsByAddress: { currentPageNumber, numItemsPerPage, items, totalCount },
        } = this.props;

        if (success && success !== prevProps.success) {
            this.setState({
                currentPageNumber,
                numItemsPerPage,
                data: items,
                totalCount,
            });
        }

        const {
            match: {
                params: { id },
            },
            getAddressChart,
        } = this.props;

        if (id !== prevProps.match.params.id) {
            this.setState(
                {
                    address: id,
                },
                () => {
                    this.loadData();
                    this.loadTransactions(10, 1);
                    getAddressChart(id);
                },
            );
        }
    }

    loadData = () => {
        const { t, getAddressInfo } = this.props;
        const { address } = this.state;
        getAddressInfo(address, t);
    };

    loadTransactions = (itemsPerPage, numberPage) => {
        const { getTransactionsByAddress } = this.props;
        const { address } = this.state;
        getTransactionsByAddress(itemsPerPage, numberPage, address);
    };

    changePagination = number => {
        const { numItemsPerPage } = this.state;

        this.loadTransactions(numItemsPerPage, number);
    };

    records = records => {
        this.loadTransactions(records, 1);
    };

    copied = address => {
        const { t } = this.props;
        copyToClipboard(address);
        notification(t('successNotification'), t('сopiedToClipboard'), 'success');
    };

    render() {
        const {
            t,
            addressInfo,
            addressInfo: { balance },
            loggingIn,
            loading,
            addressChart,
            watchList,
            match: {
                params: { id },
            },
            watchListLoading,
        } = this.props;
        const { address, currentPageNumber, numItemsPerPage, data, totalCount } = this.state;
        const { TabPane } = Tabs;

        if (loading) {
            return <Spinner />;
        }

        if (!Object.keys(addressInfo).length) {
            return (
                <div className={style.notFoundIcon}>
                    <img src={notFoundIcon} alt="notFoundIcon" />
                    <p className={style.notFoundIcon__title}>{t('nothingFound')}</p>
                </div>
            );
        }

        const findWatchList = watchList?.items?.find(item => item.address === id);

        let tagInfo = (
            <Fragment>
                {t('notAvailable')},{' '}
                <Link className={style.topBlock__link} to={loginPath}>
                    {t('loginToUpdate')}
                </Link>
            </Fragment>
        );

        if (loggingIn && !findWatchList?.tag) {
            tagInfo = (
                <Link to={`${addWatchListPath}/${id}`} className={style.addNewTag}>
                    <img src={addIcon} alt="addIcon" />
                    {t('addNewTag')}
                </Link>
            );
        }

        if (loggingIn && findWatchList && !findWatchList?.tag) {
            tagInfo = (
                <Link to={`${editWatchListPath}/${id}`} className={style.addNewTag}>
                    <img src={addIcon} alt="addIcon" />
                    {t('addNewTag')}
                </Link>
            );
        }

        if (loggingIn && findWatchList?.tag) {
            tagInfo = (
                <div className={style.tagInfoWrapper}>
                    <p className={style.tagInfoWrapper__tag}>{findWatchList.tag}</p>
                    <Link to={`${editWatchListPath}/${id}`} className={style.addNewTag}>
                        <img src={eyeIcon} alt="eyeIcon" />
                        {t('edit')}
                    </Link>
                </div>
            );
        }

        return (
            <div className={style.address} id="addressPage">
                <div className={style.address__header}>
                    <h1 className={style.address__title}>{t('address')}</h1>
                    <span className={style.address__header_address}>
                        {mobileWidth() ? truncate(address, 30) : address}
                    </span>
                    <div onClick={() => this.copied(address)} className={style.address__copyIcon}>
                        <CopyIcon />
                    </div>
                    <ModalWindow
                        triggerButton={(
                            <div className={style.address__qrCodeIcon}>
                                <img src={qrCodeIcon} alt="qrCodeIcon" />
                            </div>
                        )}
                    >
                        <div className={style.modal}>
                            <p className={style.modal__title}>{t('address')}</p>
                            <QRCode value={address} />
                            <div onClick={() => this.copied(address)} className={style.modal__address}>
                                {address}
                            </div>
                            <Button
                                type="button"
                                onClick={() => this.copied(address)}
                                className={style.modal__button}
                            >
                                {t('copy')}
                            </Button>
                        </div>
                    </ModalWindow>
                </div>
                <div className={style.topBlock}>
                    <div className={style.topBlock__item}>
                        <div className={style.topBlock__row}>
                            <p className={style.topBlock__title}>{t('addressOverview')}</p>
                        </div>
                        <div className={style.topBlock__row}>
                            <p className={style.topBlock__label}>{t('balance')}:</p>
                            <p className={style.topBlock__blackText}>$0.00</p>
                        </div>
                        <div className={style.topBlock__row}>
                            <p className={style.topBlock__label}>{t('value')}:</p>
                            <p className={style.topBlock__blackText}>{formatNumber(balance)} BTCU</p>
                        </div>
                    </div>
                    <div className={style.topBlock__item}>
                        {loggingIn && watchListLoading ? (
                            <div className={style.spinnerWrapper}>
                                <img className={style.spinner} src={spinnerImg} alt="spinner" />
                            </div>
                        ) : (
                            <Fragment>
                                <div className={style.topBlock__row}>
                                    <p className={style.topBlock__title}>{t('moreInfo')}</p>
                                </div>
                                <div className={style.topBlock__row}>
                                    <p className={style.topBlock__label}>{t('myNameTag')}:</p>
                                    <div className={style.topBlock__blackText}>{tagInfo}</div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('transactions')} key="1">
                        <Transactions
                            currentPageNumber={currentPageNumber}
                            numItemsPerPage={numItemsPerPage}
                            data={data}
                            totalCount={totalCount}
                            records={this.records}
                            changePagination={this.changePagination}
                            id={id}
                        />
                    </TabPane>
                    <TabPane tab={t('analytics')} key="2">
                        <Analytics data={addressChart} />
                    </TabPane>
                    {/*
                    <TabPane tab={t('comments')} key="3">
                        <Comments />
                    </TabPane>
                    */}
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        addressInfo: { data: addressInfo, loading },
        authentication: { loggingIn },
        transactionsByAddress: { data: transactionsByAddress, success },
        getWatchList: {
            data: { watchListArr: watchList },
            loading: watchListLoading,
        },
        addressChart: { data: addressChart },
    } = state;

    return {
        addressInfo,
        loading,
        loggingIn,
        transactionsByAddress,
        success,
        addressChart,
        watchList,
        watchListLoading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAddressInfo: getAddressInfoAction(getService),
        getAddressChart: getAddressChartAction(getService),
        getTransactionsByAddress: getTransactionsByAddressAction(getService),
        getWatchList: getWatchListAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Address Page' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(AddressPage);

import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from '../../../utils';
import NetworkTicker from '../../assets/images/icons/network-ticker';
import OrionTicker from '../../assets/images/icons/orion-ticker';
import style from './network.module.scss';

class Network extends Component {
    state = {
        isShowNetwork: false,
    };

    closeNetwork = () => {
        this.setState({
            isShowNetwork: false,
        });
    };

    openNetwork = () => {
        this.setState(state => ({
            isShowNetwork: !state.isShowNetwork,
        }));
    };

    setNetwork = value => {
        const {
            history,
            location: { pathname },
        } = this.props;
        localStorage.setItem('networkTicker', value);
        const network = value === 'btcu_testnet' ? 'orion' : 'mainnet';
        history.push(`${pathname}?${network}`);
        document.location.reload(true);
    };

    render() {
        const { isShowNetwork } = this.state;
        const networkTicker = localStorage.getItem('networkTicker');

        let btcuStyle = style.wrapper__dropDown_item;

        let btcuTestnetStyle = style.wrapper__dropDown_item;

        if (networkTicker === 'btcu') {
            btcuStyle = style.wrapper__dropDown_itemActive;
        }

        if (networkTicker === 'btcu_testnet') {
            btcuTestnetStyle = style.wrapper__dropDown_itemActive;
        }

        return (
            <OutsideClickHandler onOutsideClick={this.closeNetwork}>
                <div className={style.wrapper__network} onClick={this.openNetwork}>
                    {networkTicker === 'btcu_testnet' ? (
                        <OrionTicker className={style.wrapper__icon} />
                    ) : (
                        <NetworkTicker className={style.wrapper__icon} />
                    )}
                    {isShowNetwork ? (
                        <div className={style.wrapper__dropDown}>
                            <div
                                className={btcuStyle}
                                onClick={() => this.setNetwork('btcu')}
                            >
                                BTCU Mainnet
                            </div>
                            <div
                                className={btcuTestnetStyle}
                                onClick={() => this.setNetwork('btcu_testnet')}
                            >
                                BTCU ORION
                            </div>
                        </div>
                    ) : null}
                </div>
            </OutsideClickHandler>
        );
    }
}

Network.defaultProps = {
    history: {},
    location: {},
};

Network.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default compose(withRouter)(Network);

import notification from '../helpers/notifications';
import {
    DELETE_WATCH_LIST,
    WATCHLIST_ADDRESS_NOT_FOUND,
    watchListPath,
} from '../constants';

const deleteWatchListRequested = () => ({
    type: DELETE_WATCH_LIST.DELETE_WATCH_LIST_REQUEST,
});

const deleteWatchListLoaded = data => ({
    type: DELETE_WATCH_LIST.DELETE_WATCH_LIST_SUCCESS,
    payload: data,
});

const deleteWatchListError = error => ({
    type: DELETE_WATCH_LIST.DELETE_WATCH_LIST_FAILURE,
    payload: error,
});

const deleteWatchListAction = postService => (values, t, history) => dispatch => {
    dispatch(deleteWatchListRequested());
    postService
        .deleteWatchList(values)
        .then(data => {
            dispatch(deleteWatchListLoaded(data));
            notification('', t('addressRemovedFromWatchList'), 'success');
            history.push(watchListPath);
        })
        .catch(error => {
            dispatch(deleteWatchListError(error.response.data.message));
            const {
                response: { data: { message = 'Something went wrong' } = {} } = {},
            } = error;
            let errorMessage = message;

            if (message === WATCHLIST_ADDRESS_NOT_FOUND) {
                errorMessage = t('error.addressNotFound');
            }

            notification('', errorMessage, 'danger');
        });
};

export default deleteWatchListAction;

/* eslint-disable react/prefer-stateless-function */

import React, { Fragment } from 'react';
import TitleComponent from './title-component';

const withTitle = ({ title = '' } = {}) => Wrapped => props => (
    <Fragment>
        <TitleComponent title={title} />
        <Wrapped
            {...props}
        />
    </Fragment>

);

export default withTitle;

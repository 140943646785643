import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@assets/images/logos/btcu.svg';
import MobileMenu from '../../mobile-menu';
import ListOfLinks from '../header-links';
import ListOfMoreLinks from '../header-more-links';
import UserHeader from '../user-header-layout';
import SelectLangeage from '../../../language';
import style from './header-home.module.scss';

const HomeHeader = () => (
    <header className={style.header}>
        <div className={style.header__leftSide}>
            <div className={style.header__logo}>
                <Link to="/">
                    <img className={style.header__logo_img} src={logo} alt="logo" />
                </Link>
            </div>
            <div className={style.header__linksWrapper}>
                <ListOfLinks
                    classNameList={style.header__links}
                    classNameItem={style.header__links_item}
                    classNameSubLinks={style.header__subLinks}
                />
                <ListOfMoreLinks
                    classNameContainer={style.header__more_links}
                    classNameSubLinks={style.header__more_subLinks}
                    classNameItem={style.header__more_item}
                />
            </div>
        </div>
        <div className={style.header__rightSide}>
            <UserHeader nameStyle={style.header__rightSide_name} />
            <SelectLangeage />
        </div>
        <MobileMenu />
    </header>
);

export default HomeHeader;

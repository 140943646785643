import { GET_TX_NOTIFIER } from '../constants';

const txNotifier = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: false,
            error: false,
            success: false,
        };
    }

    switch (action.type) {
    case GET_TX_NOTIFIER.GET_TX_NOTIFIER_REQUEST:
        return {
            ...state,
            loading: true,
            error: false,
            success: false,
        };

    case GET_TX_NOTIFIER.GET_TX_NOTIFIER_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
            success: true,
        };

    case GET_TX_NOTIFIER.GET_TX_NOTIFIER_FAILURE:
        return {
            data: {},
            loading: false,
            error: true,
            success: false,
        };

    default:
        return state;
    }
};

export default txNotifier;

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import getPrivateNodeListAction from '@actions/get-private-node-list.actions';
import withTitle from '@hoc/with-title';
import withGetService from '@hoc/with-get-service';
import spinnerImg from '@assets/images/spinner.svg';
import PrivateNotesView from './private-notes-view';
import { compose } from '@utils';
import { editTxnPrivateNotesPath, txnPrivateNotesPath } from '@constants';
import style from './private-notes.module.scss';

export class PrivateNotes extends Component {
    static defaultProps = {
        getPrivateNodeList: () => {},
        history: {},
        loading: false,
        success: false,
        privateNodeList: {},
        location: {},
    };

    static propTypes = {
        getPrivateNodeList: PropTypes.func,
        history: PropTypes.object,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        privateNodeList: PropTypes.object,
        location: PropTypes.object,
    };

    state = {
        currentPageNumber: '',
        numItemsPerPage: 4,
        items: [],
        totalCount: '',
    };

    componentDidMount() {
        const {
            location: { search },
        } = this.props;
        const { numItemsPerPage } = this.state;
        const parse = search.split('=');

        this.loadData(numItemsPerPage, parse[1] || 1);
    }

    componentDidUpdate(prevProps) {
        const {
            success,
            privateNodeList: { currentPageNumber, items, totalCount },
        } = this.props;

        if (success && success !== prevProps.success) {
            this.setState({
                currentPageNumber,
                items,
                totalCount,
            });
        }
    }

    loadData = (itemsPerPage, numberPage) => {
        const { getPrivateNodeList } = this.props;
        getPrivateNodeList(itemsPerPage, numberPage);
    };

    openEdit = id => {
        const { history } = this.props;
        const { currentPageNumber } = this.state;

        history.push({
            pathname: `${editTxnPrivateNotesPath}/${id}`,
            search: `?page=${currentPageNumber}`,
        });
    };

    changePagination = number => {
        const { history } = this.props;
        const { numItemsPerPage } = this.state;

        history.push({
            pathname: txnPrivateNotesPath,
            search: `?page=${number}`,
        });
        this.loadData(numItemsPerPage, number);
    };

    render() {
        const { loading, privateNodeList } = this.props;
        const { currentPageNumber, numItemsPerPage, items, totalCount } = this.state;

        if (loading) {
            return (
                <div className={style.container}>
                    <div className={style.spinnerWrapper}>
                        <img className={style.spinner} src={spinnerImg} alt="spinner" />
                    </div>
                </div>
            );
        }

        return (
            <div className={style.container}>
                <PrivateNotesView
                    openEdit={this.openEdit}
                    hangePagination={this.changePagination}
                    privateNodeList={privateNodeList}
                    currentPageNumber={currentPageNumber}
                    numItemsPerPage={numItemsPerPage}
                    items={items}
                    totalCount={totalCount}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        privateNodeList: {
            data: { privateNodeListArr: privateNodeList },
            success,
            loading,
        },
    } = state;

    return {
        privateNodeList,
        success,
        loading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getPrivateNodeList: getPrivateNodeListAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withTitle({ title: '| Private Notes' }),
    connect(mapStateToProps, mapDispatchToProps),
)(PrivateNotes);

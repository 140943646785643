import React from 'react';
import PropTypes from 'prop-types';

const CopyIcon = ({ className }) => (
    <svg
        className={className}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.20002 3.46375H1.2C0.538202 3.46375 0 3.98158 0 4.61834V10.3913C0 11.0281 0.538202 11.5459 1.2 11.5459H7.20002C7.86182 11.5459 8.40002 11.0281 8.40002 10.3913V4.61834C8.40002 3.98158 7.86182 3.46375 7.20002 3.46375Z"
            fill="#FF1809"
        />
        <path
            d="M10.8001 0H4.8001C4.48184 0 4.17662 0.121643 3.95157 0.33817C3.72653 0.554697 3.6001 0.84837 3.6001 1.15458V2.30917H8.40011C8.71837 2.30917 9.0236 2.43081 9.24864 2.64734C9.47369 2.86387 9.60012 3.15754 9.60012 3.46375V8.08209H10.8001C11.1184 8.08209 11.4236 7.96045 11.6486 7.74392C11.8737 7.5274 12.0001 7.23372 12.0001 6.92751V1.15458C12.0001 0.84837 11.8737 0.554697 11.6486 0.33817C11.4236 0.121643 11.1184 0 10.8001 0Z"
            fill="#FF1809"
        />
    </svg>
);

CopyIcon.defaultProps = {
    className: '',
};

CopyIcon.propTypes = {
    className: PropTypes.string,
};

export default CopyIcon;
